const data = {
  faq: [
    {
      id: Math.random(),
      question:
        "The Sky Villas are conceptualized as individual bungalows on elevated levels?",
      description:
        "The industry standard states that somewhere around a 55% bounce rate is average. That's really general information, though, and it isn't that useful unless you're measuring it in a specific way. The Sky Villas are conceptualized as individual bungalows on elevated levels, promoting a sense.",
    },
    {
      id: Math.random(),
      question:
        "Promoting a sense of celebration in the sky. Each bungalow unit?",
      description:
        "The industry standard states that somewhere around a 55% bounce rate is average. That's really general information, though, and it isn't that useful unless you're measuring it in a specific way. The Sky Villas are conceptualized as individual bungalows on elevated levels, promoting a sense.",
    },
    {
      id: Math.random(),
      question:
        "Is designed as an extravagant duplex and is accessible from an elevated street through?",
      description:
        "The industry standard states that somewhere around a 55% bounce rate is average. That's really general information, though, and it isn't that useful unless you're measuring it in a specific way. The Sky Villas are conceptualized as individual bungalows on elevated levels, promoting a sense.",
    },
    {
      id: Math.random(),
      question:
        "A double height personal entry lobby with pergola above and greenery alongside?",
      description:
        "The industry standard states that somewhere around a 55% bounce rate is average. That's really general information, though, and it isn't that useful unless you're measuring it in a specific way. The Sky Villas are conceptualized as individual bungalows on elevated levels, promoting a sense.",
    },
    {
      id: Math.random(),
      question:
        "The focal point of each unit is the living dining space opening into a deck over lavish?",
      description:
        "The industry standard states that somewhere around a 55% bounce rate is average. That's really general information, though, and it isn't that useful unless you're measuring it in a specific way. The Sky Villas are conceptualized as individual bungalows on elevated levels, promoting a sense.",
    },
    {
      id: Math.random(),
      question:
        "Private green stretching the entire length and the kitchen opening into a kitchen?",
      description:
        "The industry standard states that somewhere around a 55% bounce rate is average. That's really general information, though, and it isn't that useful unless you're measuring it in a specific way. The Sky Villas are conceptualized as individual bungalows on elevated levels, promoting a sense.",
    },
    {
      id: Math.random(),
      question:
        "The private zone on the upper floor is centered around a family lounge?",
      description:
        "The industry standard states that somewhere around a 55% bounce rate is average. That's really general information, though, and it isn't that useful unless you're measuring it in a specific way. The Sky Villas are conceptualized as individual bungalows on elevated levels, promoting a sense.",
    },
    {
      id: Math.random(),
      question:
        "Surrounding by bedrooms and the master bedroom suite with attached toilets?",
      description:
        "The industry standard states that somewhere around a 55% bounce rate is average. That's really general information, though, and it isn't that useful unless you're measuring it in a specific way. The Sky Villas are conceptualized as individual bungalows on elevated levels, promoting a sense.",
    },
    {
      id: Math.random(),
      question:
        "Tea deck, in premium units. Distinct vehicular and non-vehicular areas define?",
      description:
        "The industry standard states that somewhere around a 55% bounce rate is average. That's really general information, though, and it isn't that useful unless you're measuring it in a specific way. The Sky Villas are conceptualized as individual bungalows on elevated levels, promoting a sense.",
    },
  ],
};
export default data;
