import React from "react";
import logo from "../../../assets/images/logo-2.png";
import Navs from "../../Share/Navs";

function HeaderHomeTwo({ action }) {
  return (
    <>
      <header className="coreal-header-area coreal-header-2-area">
        <div className="container custom-container">
          <div className="row">
            <div className="col-lg-12">
              <div className="coreal-header-items d-flex justify-content-between align-items-center">
                <div className="coreal-header-brand d-flex">
                  <div className="logo">
                    <a href="#">
                      <img src={logo} alt="" />
                    </a>
                  </div>
                </div>
                <div className="coreal-header-main-menu">
                  <Navs />
                </div>
                <div className="coreal-header-btn">
                  <a className="main-btn" href="#">
                    <i className="fal fa-home"></i> List Your Property
                  </a>
                  <div className="toggle-btn d-block d-lg-none">
                    <div
                      className="coreal-canvas_open"
                      onClick={(e) => action(e)}
                    >
                      <img
                        src={require(`../../../assets/images/dots.svg`).default}
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
}

export default HeaderHomeTwo;
