import React from "react";
import navitems from "../Data/NavItems";

function Navs() {
  return (
    <>
      {navitems && navitems.length > 0 && (
        <ul>
          {navitems.map((value) => (
            <li key={Math.random()}>
              <a href={value.link} style={{ textTransform: "capitalize" }}>
                {value.title}
                {value.submenu && <i className="fal fa-angle-down"></i>}
              </a>
              {value.submenu && (
                <ul className="sub-menu">
                  {value.items.map((subItems) => (
                    <li key={Math.random()}>
                      <a
                        href={subItems.link}
                        style={{ textTransform: "capitalize" }}
                      >
                        {subItems.title}
                      </a>
                    </li>
                  ))}
                </ul>
              )}
            </li>
          ))}
        </ul>
      )}
    </>
  );
}

export default Navs;
