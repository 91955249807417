import React from "react";

function Contact({ className }) {
  return (
    <>
      <div className={`coreal-loan-contact-area ${className || ""}`}>
        <div className="container custom-container">
          <div className="row">
            <div className="col-lg-12">
              <div className="coreal-loan-contact-item">
                <div className="content d-md-flex d-block justify-content-between">
                  <span>
                    Need a home loan? <span>Get pre-approved</span>
                  </span>
                  <a href="#">
                    Contact Us Now <i className="fal fa-long-arrow-right"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Contact;
