import React, { useRef } from "react";
import SliderCom from "../Helper/SliderCom";

function Gallery() {
  const sliderContent = useRef();
  const next = () => {
    sliderContent.current.slickNext();
  };
  const prev = () => {
    sliderContent.current.slickPrev();
  };
  const settings = {
    dots: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: false,
    speed: 1500,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          arrows: false,
        },
      },
      {
        breakpoint: 576,
        settings: {
          arrows: false,
        },
      },
    ],
  };
  return (
    <>
      <section className="coreal-features-gallery-area-3">
        <div className="container custom-container">
          <div className="row">
            <div className="col-lg-12">
              <div className="coreal-featured-gallery-slider-active position-relative">
                <span
                  className="prev slick-arrow"
                  style={{ display: "block" }}
                  onClick={() => prev()}
                >
                  <i className="fal fa-arrow-left"></i>
                </span>
                <SliderCom selector={sliderContent} settings={settings}>
                  <div className="coreal-features-gallery-box-3">
                    <img
                      src={
                        require(`../../assets/images/features-slide-1.png`)
                          .default
                      }
                      alt=""
                    />
                    <div className="coreal-features-gallery-item-3">
                      <div className="coreal-features-gallery-content">
                        <h3 className="title">Villa on Hollywood Boulevard</h3>
                        <p>Jeronimo Drive, Coral Gables, FL 33146, USA</p>
                        <div className="sub-bar">
                          <span>featured</span>
                        </div>
                      </div>
                      <div className="coreal-features-gallery-icon">
                        <ul>
                          <li>
                            <img
                              src={
                                require(`../../assets/images/icon/features-1.png`)
                                  .default
                              }
                              alt=""
                            />
                            <span>25</span>
                          </li>
                          <li>
                            <img
                              src={
                                require(`../../assets/images/icon/features-2.png`)
                                  .default
                              }
                              alt=""
                            />
                            <span>25</span>
                          </li>
                          <li>
                            <img
                              src={
                                require(`../../assets/images/icon/features-3.png`)
                                  .default
                              }
                              alt=""
                            />
                            <span>25</span>
                          </li>
                          <li>
                            <img
                              src={
                                require(`../../assets/images/icon/features-4.png`)
                                  .default
                              }
                              alt=""
                            />
                            <span>25</span>
                          </li>
                        </ul>
                      </div>
                      <div className="coreal-features-gallery-price">
                        <span>Form</span>
                        <h4 className="title">
                          $ 850,00 <sub>/ Month</sub>
                        </h4>
                      </div>
                    </div>
                  </div>
                  <div className="coreal-features-gallery-box-3">
                    <img
                      src={
                        require(`../../assets/images/features-slide-2.png`)
                          .default
                      }
                      alt=""
                    />
                    <div className="coreal-features-gallery-item-3">
                      <div className="coreal-features-gallery-content">
                        <h3 className="title">Villa on Hollywood Boulevard</h3>
                        <p>Jeronimo Drive, Coral Gables, FL 33146, USA</p>
                        <div className="sub-bar">
                          <span>featured</span>
                        </div>
                      </div>
                      <div className="coreal-features-gallery-icon">
                        <ul>
                          <li>
                            <img
                              src={
                                require(`../../assets/images/icon/features-1.png`)
                                  .default
                              }
                              alt=""
                            />
                            <span>25</span>
                          </li>
                          <li>
                            <img
                              src={
                                require(`../../assets/images/icon/features-2.png`)
                                  .default
                              }
                              alt=""
                            />
                            <span>25</span>
                          </li>
                          <li>
                            <img
                              src={
                                require(`../../assets/images/icon/features-3.png`)
                                  .default
                              }
                              alt=""
                            />
                            <span>25</span>
                          </li>
                          <li>
                            <img
                              src={
                                require(`../../assets/images/icon/features-4.png`)
                                  .default
                              }
                              alt=""
                            />
                            <span>25</span>
                          </li>
                        </ul>
                      </div>
                      <div className="coreal-features-gallery-price">
                        <span>Form</span>
                        <h4 className="title">
                          $ 850,00 <sub>/ Month</sub>
                        </h4>
                      </div>
                    </div>
                  </div>
                </SliderCom>
                <span
                  className="next slick-arrow"
                  style={{ display: "block" }}
                  onClick={() => next()}
                >
                  <i className="fal fa-arrow-right"></i>
                </span>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Gallery;
