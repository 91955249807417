import React, { useState } from "react";

function MainSection() {
  const [tab, setTab] = useState("all");
  const handleChange = (e, value) => {
    e.preventDefault();
    setTab(value);
  };
  return (
    <>
      <section className="coral-agents-details-area pt-100">
        <div className="container custom-container">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <div className="coral-agents-details-thumb">
                <img
                  src={
                    require("../../../assets/images/agent-details-thumb.jpg")
                      .default
                  }
                  alt=""
                />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="coral-agents-details-content">
                <span>Senior Agent</span>
                <h3 className="title">Hexol D. Drowni</h3>
                <p>
                  The spectacular Harbour Central is a brand new development in
                  London’s Docklands, comprising a total of 642 private
                  apartments divided across five residential buildings, along
                  with a leisure complex.
                </p>
                <div className="coreal-contact-info">
                  <div className="item">
                    <div className="icon">
                      <i className="fal fa-phone"></i>
                    </div>
                    <span>Mobile Numeber</span>
                    <h5>+98 0390 909 039</h5>
                  </div>
                  <div className="item">
                    <div className="icon">
                      <i className="fal fa-envelope"></i>
                    </div>
                    <span>Email Address</span>
                    <h5>info@webexample.com</h5>
                  </div>
                  <div className="item">
                    <div className="icon">
                      <i className="fal fa-map-marker-alt"></i>
                    </div>
                    <span>Office Location</span>
                    <h5>14/A, Kilix Home Tower, NYC</h5>
                  </div>
                </div>
                <div className="agent-contact-info">
                  <a href="#">Connect With Me</a>
                  <a className="chat-box" href="#">
                    <i className="fal fa-comments"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="coreal-my-listing-area pt-90">
        <div className="container custom-container">
          <div className="row">
            <div className="col-lg-8">
              <div className="coreal-my-listing-topbar">
                <h3 className="title">My Listing</h3>
                <ul className="nav nav-pills" id="pills-tab" role="tablist">
                  <li className="nav-item" role="presentation">
                    <button
                      onClick={(e) => handleChange(e, "all")}
                      className={`nav-link ${tab === "all" ? "active" : ""}`}
                      id="pills-home-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-home"
                      type="button"
                      role="tab"
                      aria-controls="pills-home"
                      aria-selected="true"
                    >
                      All
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      onClick={(e) => handleChange(e, "rent")}
                      className={`nav-link ${tab === "rent" ? "active" : ""}`}
                      id="pills-profile-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-profile"
                      type="button"
                      role="tab"
                      aria-controls="pills-profile"
                      aria-selected="false"
                    >
                      For Rent
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      onClick={(e) => handleChange(e, "sale")}
                      className={`nav-link ${tab === "sale" ? "active" : ""}`}
                      id="pills-contact-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-contact"
                      type="button"
                      role="tab"
                      aria-controls="pills-contact"
                      aria-selected="false"
                    >
                      For Sale
                    </button>
                  </li>
                </ul>
              </div>
              <div className="tab-content mb-45" id="pills-tabContent">
                <div
                  className={`tab-pane fade ${
                    tab === "all" ? "show active" : ""
                  }`}
                  id="pills-home"
                  role="tabpanel"
                  aria-labelledby="pills-home-tab"
                >
                  <div className="row">
                    <div className="col-lg-6 col-md-6">
                      <div className="coreal-listing-details-properties-item mt-45">
                        <div className="thumb">
                          <img
                            src={
                              require(`../../../assets/images/properties-1.jpg`)
                                .default
                            }
                            alt=""
                          />
                          <i className="fal fa-heart"></i>
                          <a href="#">
                            <i className="fal fa-users"></i> <span>Family</span>
                          </a>
                        </div>
                        <div className="content">
                          <h3 className="title">$78,987,00</h3>
                          <p>
                            <a href="#">
                              North west 20th Ave San Francisco, CA
                            </a>
                          </p>
                          <ul>
                            <li>
                              <i className="fal fa-bed"></i> 5 Bed
                            </li>
                            <li>
                              <i className="fal fa-bath"></i> 2 Bath
                            </li>
                            <li>
                              <i className="fal fa-expand"></i> 4100 sqft
                            </li>
                          </ul>
                          <div className="author">
                            <img
                              src={
                                require(`../../../assets/images/user-1.jpg`)
                                  .default
                              }
                              alt=""
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                      <div className="coreal-listing-details-properties-item mt-45">
                        <div className="thumb">
                          <img
                            src={
                              require(`../../../assets/images/properties-2.jpg`)
                                .default
                            }
                            alt=""
                          />
                          <i className="fal fa-heart"></i>
                          <a href="#">
                            <i className="fal fa-users"></i> <span>Family</span>
                          </a>
                        </div>
                        <div className="content">
                          <h3 className="title">$78,987,00</h3>
                          <p>
                            <a href="#">
                              North west 20th Ave San Francisco, CA
                            </a>
                          </p>
                          <ul>
                            <li>
                              <i className="fal fa-bed"></i> 5 Bed
                            </li>
                            <li>
                              <i className="fal fa-bath"></i> 2 Bath
                            </li>
                            <li>
                              <i className="fal fa-expand"></i> 4100 sqft
                            </li>
                          </ul>
                          <div className="author">
                            <img
                              src={
                                require(`../../../assets/images/user-1.jpg`)
                                  .default
                              }
                              alt=""
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                      <div className="coreal-listing-details-properties-item mt-45">
                        <div className="thumb">
                          <img
                            src={
                              require(`../../../assets/images/properties-2.jpg`)
                                .default
                            }
                            alt=""
                          />
                          <i className="fal fa-heart"></i>
                          <a href="#">
                            <i className="fal fa-users"></i> <span>Family</span>
                          </a>
                        </div>
                        <div className="content">
                          <h3 className="title">$78,987,00</h3>
                          <p>
                            <a href="#">
                              North west 20th Ave San Francisco, CA
                            </a>
                          </p>
                          <ul>
                            <li>
                              <i className="fal fa-bed"></i> 5 Bed
                            </li>
                            <li>
                              <i className="fal fa-bath"></i> 2 Bath
                            </li>
                            <li>
                              <i className="fal fa-expand"></i> 4100 sqft
                            </li>
                          </ul>
                          <div className="author">
                            <img
                              src={
                                require(`../../../assets/images/user-1.jpg`)
                                  .default
                              }
                              alt=""
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                      <div className="coreal-listing-details-properties-item mt-45">
                        <div className="thumb">
                          <img
                            src={
                              require(`../../../assets/images/properties-1.jpg`)
                                .default
                            }
                            alt=""
                          />
                          <i className="fal fa-heart"></i>
                          <a href="#">
                            <i className="fal fa-users"></i> <span>Family</span>
                          </a>
                        </div>
                        <div className="content">
                          <h3 className="title">$78,987,00</h3>
                          <p>
                            <a href="#">
                              North west 20th Ave San Francisco, CA
                            </a>
                          </p>
                          <ul>
                            <li>
                              <i className="fal fa-bed"></i> 5 Bed
                            </li>
                            <li>
                              <i className="fal fa-bath"></i> 2 Bath
                            </li>
                            <li>
                              <i className="fal fa-expand"></i> 4100 sqft
                            </li>
                          </ul>
                          <div className="author">
                            <img
                              src={
                                require(`../../../assets/images/user-1.jpg`)
                                  .default
                              }
                              alt=""
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className={`tab-pane fade ${
                    tab === "rent" ? "show active" : ""
                  }`}
                  id="pills-profile"
                  role="tabpanel"
                  aria-labelledby="pills-profile-tab"
                >
                  <div className="row">
                    <div className="col-lg-6 col-md-6">
                      <div className="coreal-listing-details-properties-item mt-45">
                        <div className="thumb">
                          <img
                            src={
                              require(`../../../assets/images/properties-1.jpg`)
                                .default
                            }
                            alt=""
                          />
                          <i className="fal fa-heart"></i>
                          <a href="#">
                            <i className="fal fa-users"></i> <span>Family</span>
                          </a>
                        </div>
                        <div className="content">
                          <h3 className="title">$78,987,00</h3>
                          <p>
                            <a href="#">
                              North west 20th Ave San Francisco, CA
                            </a>
                          </p>
                          <ul>
                            <li>
                              <i className="fal fa-bed"></i> 5 Bed
                            </li>
                            <li>
                              <i className="fal fa-bath"></i> 2 Bath
                            </li>
                            <li>
                              <i className="fal fa-expand"></i> 4100 sqft
                            </li>
                          </ul>
                          <div className="author">
                            <img
                              src={
                                require(`../../../assets/images/user-1.jpg`)
                                  .default
                              }
                              alt=""
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                      <div className="coreal-listing-details-properties-item mt-45">
                        <div className="thumb">
                          <img
                            src={
                              require(`../../../assets/images/properties-2.jpg`)
                                .default
                            }
                            alt=""
                          />
                          <i className="fal fa-heart"></i>
                          <a href="#">
                            <i className="fal fa-users"></i> <span>Family</span>
                          </a>
                        </div>
                        <div className="content">
                          <h3 className="title">$78,987,00</h3>
                          <p>
                            <a href="#">
                              North west 20th Ave San Francisco, CA
                            </a>
                          </p>
                          <ul>
                            <li>
                              <i className="fal fa-bed"></i> 5 Bed
                            </li>
                            <li>
                              <i className="fal fa-bath"></i> 2 Bath
                            </li>
                            <li>
                              <i className="fal fa-expand"></i> 4100 sqft
                            </li>
                          </ul>
                          <div className="author">
                            <img
                              src={
                                require(`../../../assets/images/user-1.jpg`)
                                  .default
                              }
                              alt=""
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                      <div className="coreal-listing-details-properties-item mt-45">
                        <div className="thumb">
                          <img
                            src={
                              require(`../../../assets/images/properties-2.jpg`)
                                .default
                            }
                            alt=""
                          />
                          <i className="fal fa-heart"></i>
                          <a href="#">
                            <i className="fal fa-users"></i> <span>Family</span>
                          </a>
                        </div>
                        <div className="content">
                          <h3 className="title">$78,987,00</h3>
                          <p>
                            <a href="#">
                              North west 20th Ave San Francisco, CA
                            </a>
                          </p>
                          <ul>
                            <li>
                              <i className="fal fa-bed"></i> 5 Bed
                            </li>
                            <li>
                              <i className="fal fa-bath"></i> 2 Bath
                            </li>
                            <li>
                              <i className="fal fa-expand"></i> 4100 sqft
                            </li>
                          </ul>
                          <div className="author">
                            <img
                              src={
                                require(`../../../assets/images/user-1.jpg`)
                                  .default
                              }
                              alt=""
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                      <div className="coreal-listing-details-properties-item mt-45">
                        <div className="thumb">
                          <img
                            src={
                              require(`../../../assets/images/properties-1.jpg`)
                                .default
                            }
                            alt=""
                          />
                          <i className="fal fa-heart"></i>
                          <a href="#">
                            <i className="fal fa-users"></i> <span>Family</span>
                          </a>
                        </div>
                        <div className="content">
                          <h3 className="title">$78,987,00</h3>
                          <p>
                            <a href="#">
                              North west 20th Ave San Francisco, CA
                            </a>
                          </p>
                          <ul>
                            <li>
                              <i className="fal fa-bed"></i> 5 Bed
                            </li>
                            <li>
                              <i className="fal fa-bath"></i> 2 Bath
                            </li>
                            <li>
                              <i className="fal fa-expand"></i> 4100 sqft
                            </li>
                          </ul>
                          <div className="author">
                            <img
                              src={
                                require(`../../../assets/images/user-1.jpg`)
                                  .default
                              }
                              alt=""
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className={`tab-pane fade ${
                    tab === "sale" ? "show active" : ""
                  }`}
                  id="pills-contact"
                  role="tabpanel"
                  aria-labelledby="pills-contact-tab"
                >
                  <div className="row">
                    <div className="col-lg-6 col-md-6">
                      <div className="coreal-listing-details-properties-item mt-45">
                        <div className="thumb">
                          <img
                            src={
                              require(`../../../assets/images/properties-1.jpg`)
                                .default
                            }
                            alt=""
                          />
                          <i className="fal fa-heart"></i>
                          <a href="#">
                            <i className="fal fa-users"></i> <span>Family</span>
                          </a>
                        </div>
                        <div className="content">
                          <h3 className="title">$78,987,00</h3>
                          <p>
                            <a href="#">
                              North west 20th Ave San Francisco, CA
                            </a>
                          </p>
                          <ul>
                            <li>
                              <i className="fal fa-bed"></i> 5 Bed
                            </li>
                            <li>
                              <i className="fal fa-bath"></i> 2 Bath
                            </li>
                            <li>
                              <i className="fal fa-expand"></i> 4100 sqft
                            </li>
                          </ul>
                          <div className="author">
                            <img
                              src={
                                require(`../../../assets/images/user-1.jpg`)
                                  .default
                              }
                              alt=""
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                      <div className="coreal-listing-details-properties-item mt-45">
                        <div className="thumb">
                          <img
                            src={
                              require(`../../../assets/images/properties-2.jpg`)
                                .default
                            }
                            alt=""
                          />
                          <i className="fal fa-heart"></i>
                          <a href="#">
                            <i className="fal fa-users"></i> <span>Family</span>
                          </a>
                        </div>
                        <div className="content">
                          <h3 className="title">$78,987,00</h3>
                          <p>
                            <a href="#">
                              North west 20th Ave San Francisco, CA
                            </a>
                          </p>
                          <ul>
                            <li>
                              <i className="fal fa-bed"></i> 5 Bed
                            </li>
                            <li>
                              <i className="fal fa-bath"></i> 2 Bath
                            </li>
                            <li>
                              <i className="fal fa-expand"></i> 4100 sqft
                            </li>
                          </ul>
                          <div className="author">
                            <img
                              src={
                                require(`../../../assets/images/user-1.jpg`)
                                  .default
                              }
                              alt=""
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                      <div className="coreal-listing-details-properties-item mt-45">
                        <div className="thumb">
                          <img
                            src={
                              require(`../../../assets/images/properties-2.jpg`)
                                .default
                            }
                            alt=""
                          />
                          <i className="fal fa-heart"></i>
                          <a href="#">
                            <i className="fal fa-users"></i> <span>Family</span>
                          </a>
                        </div>
                        <div className="content">
                          <h3 className="title">$78,987,00</h3>
                          <p>
                            <a href="#">
                              North west 20th Ave San Francisco, CA
                            </a>
                          </p>
                          <ul>
                            <li>
                              <i className="fal fa-bed"></i> 5 Bed
                            </li>
                            <li>
                              <i className="fal fa-bath"></i> 2 Bath
                            </li>
                            <li>
                              <i className="fal fa-expand"></i> 4100 sqft
                            </li>
                          </ul>
                          <div className="author">
                            <img
                              src={
                                require(`../../../assets/images/user-1.jpg`)
                                  .default
                              }
                              alt=""
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                      <div className="coreal-listing-details-properties-item mt-45">
                        <div className="thumb">
                          <img
                            src={
                              require(`../../../assets/images/properties-1.jpg`)
                                .default
                            }
                            alt=""
                          />
                          <i className="fal fa-heart"></i>
                          <a href="#">
                            <i className="fal fa-users"></i> <span>Family</span>
                          </a>
                        </div>
                        <div className="content">
                          <h3 className="title">$78,987,00</h3>
                          <p>
                            <a href="#">
                              North west 20th Ave San Francisco, CA
                            </a>
                          </p>
                          <ul>
                            <li>
                              <i className="fal fa-bed"></i> 5 Bed
                            </li>
                            <li>
                              <i className="fal fa-bath"></i> 2 Bath
                            </li>
                            <li>
                              <i className="fal fa-expand"></i> 4100 sqft
                            </li>
                          </ul>
                          <div className="author">
                            <img
                              src={
                                require(`../../../assets/images/user-1.jpg`)
                                  .default
                              }
                              alt=""
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="listing-details-find sidebar-widget mb-45">
                <div className="sidebar-widget-title">
                  <h3 className="title">Custom Request</h3>
                </div>
                <div className="listing-details-find-box mt-30">
                  <form action="#">
                    <div className="input-box">
                      <input type="text" placeholder="Enter keyword" />
                      <i className="fal fa-user"></i>
                    </div>
                    <div className="input-box">
                      <input type="text" placeholder="Email address" />
                      <i className="fal fa-envelope"></i>
                    </div>
                    <div className="input-box">
                      <input type="text" placeholder="Phone number" />
                      <i className="fal fa-phone"></i>
                    </div>
                    <div className="input-box">
                      <input type="text" placeholder="Budget" />
                      <i className="fal fa-star"></i>
                    </div>
                    <div className="input-box">
                      <textarea
                        name="#"
                        id="#"
                        cols="30"
                        rows="10"
                        placeholder="Message"
                      ></textarea>
                      <i className="fal fa-pen"></i>
                    </div>

                    <div className="input-box">
                      <button type="button">Make A Request</button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default MainSection;
