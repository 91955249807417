import React from "react";

function CardStyleOne({ className, datas }) {
  return (
    <div
      className={`coreal-listing-details-properties-item mt-45 ${
        className || ""
      }`}
    >
      <div className="thumb">
        <img
          src={require(`../../assets/images/${datas.cover}`).default}
          alt=""
        />
        <i className="fal fa-heart"></i>
        <a href="#">
          <i className="fal fa-users"></i> <span>{datas.category}</span>
        </a>
      </div>
      <div className="content">
        <h3 className="title">${datas.price}</h3>
        <p>
          <a href="#">{datas.title}</a>
        </p>
        <ul>
          <li>
            <i className="fal fa-bed"></i>
            {datas.bed} Bed
          </li>
          <li>
            <i className="fal fa-bath"></i> {datas.bath} Bath
          </li>
          <li>
            <i className="fal fa-expand"></i> {datas.sqft} sqft
          </li>
        </ul>
        <div className="author">
          <img
            src={require(`../../assets/images/${datas.user}`).default}
            alt=""
          />
        </div>
      </div>
    </div>
  );
}

export default CardStyleOne;
