import React from "react";

function Sponsers() {
  return (
    <>
      <section className="coreal-sponsor-area coreal-sponsor-area-3">
        <div className="container custom-container">
          <div className="row justify-content-center">
            <div className="col-lg-6">
              <div className="section-title text-center">
                <span>Friendly Users</span>
                <h3 className="title">We have more then 1M Active users</h3>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <div className="coreal-sponser-box pt-20">
                <div className="item-list item-1">
                  <div className="item  text-center">
                    <a href="#">
                      <img
                        src={
                          require(`../../assets/images/sopnser-1.png`).default
                        }
                        alt=""
                      />
                    </a>
                  </div>
                  <div className="item text-center">
                    <a href="#">
                      <img
                        src={
                          require(`../../assets/images/sopnser-2.png`).default
                        }
                        alt=""
                      />
                    </a>
                  </div>
                  <div className="item text-center">
                    <a href="#">
                      <img
                        src={
                          require(`../../assets/images/sopnser-3.png`).default
                        }
                        alt=""
                      />
                    </a>
                  </div>
                  <div className="item text-center">
                    <a href="#">
                      <img
                        src={
                          require(`../../assets/images/sopnser-4.png`).default
                        }
                        alt=""
                      />
                    </a>
                  </div>
                  <div className="item first text-center">
                    <a href="#">
                      <img
                        src={
                          require(`../../assets/images/sopnser-5.png`).default
                        }
                        alt=""
                      />
                    </a>
                  </div>
                </div>
                <div className="item-list item-2">
                  <div className="item  text-center">
                    <a href="#">
                      <img
                        src={
                          require(`../../assets/images/sopnser-6.png`).default
                        }
                        alt=""
                      />
                    </a>
                  </div>
                  <div className="item text-center">
                    <a href="#">
                      <img
                        src={
                          require(`../../assets/images/sopnser-7.png`).default
                        }
                        alt=""
                      />
                    </a>
                  </div>
                  <div className="item text-center">
                    <a href="#">
                      <img
                        src={
                          require(`../../assets/images/sopnser-8.png`).default
                        }
                        alt=""
                      />
                    </a>
                  </div>
                  <div className="item text-center">
                    <a href="#">
                      <img
                        src={
                          require(`../../assets/images/sopnser-9.png`).default
                        }
                        alt=""
                      />
                    </a>
                  </div>
                  <div className="item second text-center">
                    <a href="#">
                      <img
                        src={
                          require(`../../assets/images/sopnser-10.png`).default
                        }
                        alt=""
                      />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Sponsers;
