import React from "react";

function Features() {
  return (
    <>
      <section className="coreal-featurd-3-area pt-90 pb-100">
        <div className="container custom-container">
          <div className="row">
            <div className="col-lg-12">
              <div className="section-title">
                <span>Featured Area</span>
                <h3 className="title">Search properties around your city</h3>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-9">
              <div className="row">
                <div className="col-lg-4 col-md-6">
                  <div className="coreal-featurd-3-item mt-30">
                    <img
                      src={
                        require(`../../assets/images/features-item-1.jpg`)
                          .default
                      }
                      alt=""
                    />
                    <div className="coreal-featurd-3-overlay">
                      <ul>
                        <li>
                          <p>Area</p>
                          <span>4 SqFt</span>
                        </li>
                        <li>
                          <p>Bedrooms</p>
                          <span>03</span>
                        </li>
                        <li>
                          <p>Garages</p>
                          <span>02</span>
                        </li>
                        <li>
                          <p>Bathrooms</p>
                          <span>07</span>
                        </li>
                      </ul>
                      <div className="coreal-featurd-3-content">
                        <h3 className="title">
                          <a href="#">Store in Woodside</a>
                        </h3>
                        <span>
                          <i className="fal fa-map-marker-alt"></i> 23
                          Philadelphia Avenue...
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6">
                  <div className="coreal-featurd-3-item mt-30">
                    <img
                      src={
                        require(`../../assets/images/features-item-2.jpg`)
                          .default
                      }
                      alt=""
                    />
                    <div className="coreal-featurd-3-overlay">
                      <ul>
                        <li>
                          <p>Area</p>
                          <span>4 SqFt</span>
                        </li>
                        <li>
                          <p>Bedrooms</p>
                          <span>03</span>
                        </li>
                        <li>
                          <p>Garages</p>
                          <span>02</span>
                        </li>
                        <li>
                          <p>Bathrooms</p>
                          <span>07</span>
                        </li>
                      </ul>
                      <div className="coreal-featurd-3-content">
                        <h3 className="title">
                          <a href="#">Hermosa casa al norte</a>
                        </h3>
                        <span>
                          <i className="fal fa-map-marker-alt"></i> 23
                          Philadelphia Avenue...
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6">
                  <div className="coreal-featurd-3-item item mt-30">
                    <img
                      src={
                        require(`../../assets/images/features-item-3.jpg`)
                          .default
                      }
                      alt=""
                    />
                    <div className="coreal-featurd-3-overlay">
                      <ul>
                        <li>
                          <p>Area</p>
                          <span>4 SqFt</span>
                        </li>
                        <li>
                          <p>Bedrooms</p>
                          <span>03</span>
                        </li>
                        <li>
                          <p>Garages</p>
                          <span>02</span>
                        </li>
                        <li>
                          <p>Bathrooms</p>
                          <span>07</span>
                        </li>
                      </ul>
                      <div className="coreal-featurd-3-content">
                        <h3 className="title">
                          <a href="#">Casa Lomas de Machalí</a>
                        </h3>
                        <span>
                          <i className="fal fa-map-marker-alt"></i> 23
                          Philadelphia Avenue...
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-8">
                  <div className="coreal-featurd-3-item mt-30">
                    <img
                      src={
                        require(`../../assets/images/features-item-4.jpg`)
                          .default
                      }
                      alt=""
                    />
                    <div className="coreal-featurd-3-overlay">
                      <ul>
                        <li>
                          <p>Area</p>
                          <span>4 SqFt</span>
                        </li>
                        <li>
                          <p>Bedrooms</p>
                          <span>03</span>
                        </li>
                        <li>
                          <p>Garages</p>
                          <span>02</span>
                        </li>
                        <li>
                          <p>Bathrooms</p>
                          <span>07</span>
                        </li>
                      </ul>
                      <div className="coreal-featurd-3-content">
                        <h3 className="title">
                          <a href="#">Luxury Villa In Los Angeles</a>
                        </h3>
                        <span>
                          <i className="fal fa-map-marker-alt"></i> 23
                          Philadelphia Avenue...
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="coreal-featurd-3-item item mt-30">
                    <img
                      src={
                        require(`../../assets/images/features-item-5.jpg`)
                          .default
                      }
                      alt=""
                    />
                    <div className="coreal-featurd-3-overlay">
                      <ul>
                        <li>
                          <p>Area</p>
                          <span>4 SqFt</span>
                        </li>
                        <li>
                          <p>Bedrooms</p>
                          <span>03</span>
                        </li>
                        <li>
                          <p>Garages</p>
                          <span>02</span>
                        </li>
                        <li>
                          <p>Bathrooms</p>
                          <span>07</span>
                        </li>
                      </ul>
                      <div className="coreal-featurd-3-content">
                        <h3 className="title">
                          <a href="#">Farm in Castro St Los</a>
                        </h3>
                        <span>
                          <i className="fal fa-map-marker-alt"></i> 23
                          Philadelphia Avenue...
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="coreal-featurd-3-item mt-30">
                <img
                  src={
                    require(`../../assets/images/features-item-6.jpg`).default
                  }
                  alt=""
                />
                <div className="coreal-featurd-3-overlay">
                  <ul>
                    <li>
                      <p>Area</p>
                      <span>4 SqFt</span>
                    </li>
                    <li>
                      <p>Bedrooms</p>
                      <span>03</span>
                    </li>
                    <li>
                      <p>Garages</p>
                      <span>02</span>
                    </li>
                    <li>
                      <p>Bathrooms</p>
                      <span>07</span>
                    </li>
                  </ul>
                  <div className="coreal-featurd-3-content">
                    <h3 className="title">
                      <a href="#">Park Avenue Apartment</a>
                    </h3>
                    <span>
                      <i className="fal fa-map-marker-alt"></i> 23 Philadelphia
                      Avenue...
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Features;
