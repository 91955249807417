import React from "react";

function Hero({ className }) {
  return (
    <>
      <section className={`coreal-hero-1-area ${className || ""}`}>
        <div className="container custom-container">
          <div className="row align-items-center">
            <div className="col-lg-7">
              <div className="coreal-hero-content">
                <h1
                  className="title wow fadeInLeft"
                  data-wow-duration="1s"
                  data-wow-delay="0s"
                >
                  Let’s find a <span>Home</span> that’s perfect for you.
                </h1>
                <span
                  className="wow fadeInLeft"
                  data-wow-duration="1.5s"
                  data-wow-delay=".5s"
                >
                  Search confidently with your trusted source of homes for sale
                  or rent
                </span>
                <div
                  className="coreal-hero-search wow fadeInUp"
                  data-wow-duration="1.5s"
                  data-wow-delay="1s"
                >
                  <form action="#">
                    <div className="input-box">
                      <input type="text" placeholder="San Francisco, CA" />
                      <div className="coreal-select-item">
                        <select className="nice-select">
                          <option data-display="USA">USA</option>
                          <option value="1">BAN</option>
                          <option value="2">IND</option>
                          <option value="3">SRI</option>
                          <option value="4">ENG</option>
                        </select>
                      </div>
                      <button type="button">
                        <i className="fal fa-search"></i> Search Now
                      </button>
                    </div>
                    <ul>
                      <li>
                        <a className="active" href="#">
                          Buy
                        </a>
                      </li>
                      <li>
                        <a href="#">Rent</a>
                      </li>
                      <li>
                        <a href="#">Pre-approval</a>
                      </li>
                      <li>
                        <a href="#">Mortage</a>
                      </li>
                    </ul>
                  </form>
                </div>
              </div>
            </div>
            <div className="col-lg-5">
              <div className="hero-thumb">
                <img
                  src={require("../../assets/images/hero-thumb.png").default}
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Hero;
