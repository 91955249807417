import React, { useEffect } from "react";
import BackToTopLib from "../../lib/BackToTopLib";

function BackToTop() {
  useEffect(() => {
    BackToTopLib(".back-to-top");
  });

  return (
    <div className="back-to-top">
      <a href="#">
        <i className="fas fa-arrow-up"></i>
      </a>
    </div>
  );
}

export default BackToTop;
