import React from "react";
import TrackingUserCard from "../Card/TrackingUserCard";

function TrackingArea({ trackingUsers }) {
  return (
    <>
      <section className="coreal-track-area">
        <div className="container custom-container">
          <div className="row">
            <div className="col-lg-12">
              <div className="coreal-track-box text-center ml-100 mr-100">
                <img
                  src={require(`../../assets/images/map-1.png`).default}
                  alt=""
                />
                <div className="coreal-track-location">
                  {trackingUsers.slice(0, 6).map((value, index) => (
                    <TrackingUserCard
                      key={value.id + value.username}
                      datas={value}
                      usercount={index + 1}
                    />
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default TrackingArea;
