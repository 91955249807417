import React from "react";
import NavItems from "../Data/NavItems";
import BackToTop from "../Helper/BackToTop";
import MargeHeaderDrawer from "../Helper/MargeHeaderDrawer";
import FooterCom from "../Partials/Footers/FooterHomeOne";
import Hero from "./Hero";
import MainSection from "./MainSection";

function Contact() {
  return (
    <>
      <MargeHeaderDrawer navItems={NavItems} />
      <Hero />
      <MainSection />
      <FooterCom />
      <BackToTop />
    </>
  );
}

export default Contact;
