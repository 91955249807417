import React from "react";

function FeatureSection() {
  return (
    <>
      <section className="coreal-fetaures-area">
        <div className="container custom-container">
          <div className="row">
            <div className="col-lg-12">
              <div className="section-title text-center">
                <span>Features</span>
                <h3 className="title">How We Can Help</h3>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-3 col-md-6 col-sm-6">
              <div
                className="coreal-fetaures-item mt-30 wow fadeInUp"
                data-wow-duration="2s"
                data-wow-delay="0s"
              >
                <img
                  src={
                    require(`../../assets/images/icon/fetaures-1.svg`).default
                  }
                  alt=""
                />
                <h4 className="title">Buy A Home</h4>
                <p>
                  With more neighborhood insights than any other real estate
                  website, we've captured
                </p>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-6">
              <div
                className="coreal-fetaures-item mt-30 wow fadeInUp"
                data-wow-duration="2s"
                data-wow-delay=".3s"
              >
                <img
                  src={
                    require(`../../assets/images/icon/fetaures-2.svg`).default
                  }
                  alt=""
                />
                <h4 className="title">Rent A Home</h4>
                <p>
                  With more neighborhood insights than any other real estate
                  website, we've captured
                </p>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-6">
              <div
                className="coreal-fetaures-item mt-30 wow fadeInUp"
                data-wow-duration="2s"
                data-wow-delay=".6s"
              >
                <img
                  src={
                    require(`../../assets/images/icon/fetaures-3.svg`).default
                  }
                  alt=""
                />
                <h4 className="title">Book A Room</h4>
                <p>
                  With more neighborhood insights than any other real estate
                  website, we've captured
                </p>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-6">
              <div
                className="coreal-fetaures-item mt-30 wow fadeInUp"
                data-wow-duration="2s"
                data-wow-delay=".9s"
              >
                <img
                  src={
                    require(`../../assets/images/icon/fetaures-4.svg`).default
                  }
                  alt=""
                />
                <h4 className="title">Rent A Room</h4>
                <p>
                  With more neighborhood insights than any other real estate
                  website, we've captured
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default FeatureSection;
