import React, { useState } from "react";
import SelectBox from "../../Share/SelectBox/index";

function MainSection() {
  const category1 = ["All type", "Bedrooms", "Bathrooms"];
  const [selectedCategory, setCategory] = useState();
  const changeCategory1 = (value) => {
    setCategory(value);
  };
  const category2 = ["All type", "Bedrooms", "Bathrooms"];
  const [selectedCategory2, setCategory2] = useState();
  const changeCategory2 = (value) => {
    setCategory2(value);
  };
  return (
    <>
      <section className="coreal-agency-page-area pt-55 pb-100">
        <div className="container custom-container">
          <div className="row">
            <div className="col-lg-4 col-md-6">
              <div className="listing-details-agent sidebar-widget mt-45">
                <div className="sidebar-widget-title text-center pb-50 pt-15">
                  <img
                    src={
                      require(`../../../assets/images/sopnser-1.png`).default
                    }
                    alt=""
                  />
                </div>
                <div className="agent-details">
                  <div className="content">
                    <h4 className="title">Miranda H. Halim</h4>
                    <span>Founder</span>
                  </div>
                </div>
                <div className="text mt-20">
                  <p>
                    The spectacular Harbour Central a brand new development in
                    London’s Docklands, comprising a total of 642 private.
                  </p>
                </div>
                <div className="agent-contact-details">
                  <ul>
                    <li>
                      <i className="fal fa-phone"></i>{" "}
                      <a href="#">+098 908 967 87</a>
                    </li>
                    <li>
                      <i className="fal fa-envelope"></i>{" "}
                      <a href="#">info@webexample.com</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="listing-details-agent sidebar-widget mt-45">
                <div className="sidebar-widget-title text-center pb-50 pt-15">
                  <img
                    src={
                      require(`../../../assets/images/sopnser-2.png`).default
                    }
                    alt=""
                  />
                </div>
                <div className="agent-details">
                  <div className="content">
                    <h4 className="title">Miranda H. Halim</h4>
                    <span>Founder</span>
                  </div>
                </div>
                <div className="text mt-20">
                  <p>
                    The spectacular Harbour Central a brand new development in
                    London’s Docklands, comprising a total of 642 private.
                  </p>
                </div>
                <div className="agent-contact-details">
                  <ul>
                    <li>
                      <i className="fal fa-phone"></i>{" "}
                      <a href="#">+098 908 967 87</a>
                    </li>
                    <li>
                      <i className="fal fa-envelope"></i>{" "}
                      <a href="#">info@webexample.com</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="listing-details-agent sidebar-widget mt-45">
                <div className="sidebar-widget-title text-center pb-50 pt-15">
                  <img
                    src={
                      require(`../../../assets/images/sopnser-3.png`).default
                    }
                    alt=""
                  />
                </div>
                <div className="agent-details">
                  <div className="content">
                    <h4 className="title">Miranda H. Halim</h4>
                    <span>Founder</span>
                  </div>
                </div>
                <div className="text mt-20">
                  <p>
                    The spectacular Harbour Central a brand new development in
                    London’s Docklands, comprising a total of 642 private.
                  </p>
                </div>
                <div className="agent-contact-details">
                  <ul>
                    <li>
                      <i className="fal fa-phone"></i>{" "}
                      <a href="#">+098 908 967 87</a>
                    </li>
                    <li>
                      <i className="fal fa-envelope"></i>{" "}
                      <a href="#">info@webexample.com</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="listing-details-agent sidebar-widget mt-45">
                <div className="sidebar-widget-title text-center pb-50 pt-15">
                  <img
                    src={
                      require(`../../../assets/images/sopnser-4.png`).default
                    }
                    alt=""
                  />
                </div>
                <div className="agent-details">
                  <div className="content">
                    <h4 className="title">Miranda H. Halim</h4>
                    <span>Founder</span>
                  </div>
                </div>
                <div className="text mt-20">
                  <p>
                    The spectacular Harbour Central a brand new development in
                    London’s Docklands, comprising a total of 642 private.
                  </p>
                </div>
                <div className="agent-contact-details">
                  <ul>
                    <li>
                      <i className="fal fa-phone"></i>{" "}
                      <a href="#">+098 908 967 87</a>
                    </li>
                    <li>
                      <i className="fal fa-envelope"></i>{" "}
                      <a href="#">info@webexample.com</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="listing-details-agent sidebar-widget mt-45">
                <div className="sidebar-widget-title text-center pb-50 pt-15">
                  <img
                    src={
                      require(`../../../assets/images/sopnser-5.png`).default
                    }
                    alt=""
                  />
                </div>
                <div className="agent-details">
                  <div className="content">
                    <h4 className="title">Miranda H. Halim</h4>
                    <span>Founder</span>
                  </div>
                </div>
                <div className="text mt-20">
                  <p>
                    The spectacular Harbour Central a brand new development in
                    London’s Docklands, comprising a total of 642 private.
                  </p>
                </div>
                <div className="agent-contact-details">
                  <ul>
                    <li>
                      <i className="fal fa-phone"></i>{" "}
                      <a href="#">+098 908 967 87</a>
                    </li>
                    <li>
                      <i className="fal fa-envelope"></i>{" "}
                      <a href="#">info@webexample.com</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="listing-details-agent sidebar-widget mt-45">
                <div className="sidebar-widget-title text-center pb-50 pt-15">
                  <img
                    src={
                      require(`../../../assets/images/sopnser-6.png`).default
                    }
                    alt=""
                  />
                </div>
                <div className="agent-details">
                  <div className="content">
                    <h4 className="title">Miranda H. Halim</h4>
                    <span>Founder</span>
                  </div>
                </div>
                <div className="text mt-20">
                  <p>
                    The spectacular Harbour Central a brand new development in
                    London’s Docklands, comprising a total of 642 private.
                  </p>
                </div>
                <div className="agent-contact-details">
                  <ul>
                    <li>
                      <i className="fal fa-phone"></i>{" "}
                      <a href="#">+098 908 967 87</a>
                    </li>
                    <li>
                      <i className="fal fa-envelope"></i>{" "}
                      <a href="#">info@webexample.com</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="listing-details-agent sidebar-widget mt-45">
                <div className="sidebar-widget-title text-center pb-50 pt-15">
                  <img
                    src={
                      require(`../../../assets/images/sopnser-7.png`).default
                    }
                    alt=""
                  />
                </div>
                <div className="agent-details">
                  <div className="content">
                    <h4 className="title">Miranda H. Halim</h4>
                    <span>Founder</span>
                  </div>
                </div>
                <div className="text mt-20">
                  <p>
                    The spectacular Harbour Central a brand new development in
                    London’s Docklands, comprising a total of 642 private.
                  </p>
                </div>
                <div className="agent-contact-details">
                  <ul>
                    <li>
                      <i className="fal fa-phone"></i>{" "}
                      <a href="#">+098 908 967 87</a>
                    </li>
                    <li>
                      <i className="fal fa-envelope"></i>{" "}
                      <a href="#">info@webexample.com</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="listing-details-agent sidebar-widget mt-45">
                <div className="sidebar-widget-title text-center pb-50 pt-15">
                  <img
                    src={
                      require(`../../../assets/images/sopnser-8.png`).default
                    }
                    alt=""
                  />
                </div>
                <div className="agent-details">
                  <div className="content">
                    <h4 className="title">Miranda H. Halim</h4>
                    <span>Founder</span>
                  </div>
                </div>
                <div className="text mt-20">
                  <p>
                    The spectacular Harbour Central a brand new development in
                    London’s Docklands, comprising a total of 642 private.
                  </p>
                </div>
                <div className="agent-contact-details">
                  <ul>
                    <li>
                      <i className="fal fa-phone"></i>{" "}
                      <a href="#">+098 908 967 87</a>
                    </li>
                    <li>
                      <i className="fal fa-envelope"></i>{" "}
                      <a href="#">info@webexample.com</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="listing-details-find sidebar-widget mt-45">
                <div className="sidebar-widget-title">
                  <h3 className="title">Request for listing</h3>
                </div>
                <div className="listing-details-find-box mt-30">
                  <form action="#">
                    <div className="input-box">
                      <input type="text" placeholder="Enter full name" />
                      <i className="fal fa-user"></i>
                    </div>
                    <SelectBox
                      className="mb-20"
                      selected={selectedCategory}
                      datas={category1}
                      action={changeCategory1}
                    />
                    <SelectBox
                      className="mb-20"
                      selected={selectedCategory2}
                      datas={category2}
                      action={changeCategory2}
                    />
                    <div className="input-box">
                      <button type="button">Make A Request</button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default MainSection;
