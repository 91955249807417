import React from "react";
import useToggle from "../../Hooks/useToggles";
import listingData from "../Data/HomeTwo";
import NavItems from "../Data/NavItems";
import BackToTop from "../Helper/BackToTop";
import ListingTab from "../HomeTwo/ListingTab";
import Drawer from "../Mobile/Drawer";
import FooterHomeThree from "../Partials/Footers/FooterHomeThree";
import HeaderHomeThree from "../Partials/Headers/HeaderHomeThree";
import AgentMembers from "./AgentMembers";
import Blogs from "./Blogs";
import Features from "./Features";
import Gallery from "./Gallery";
import GetInTouch from "./GetInTouch";
import Hero from "./Hero";
import Map from "./Map";
import Services from "./Services";
import Sponsers from "./Sponsers";

function HomeThree() {
  const [drawer, drawerAction] = useToggle(false);
  const listingDatas = listingData.listingData;
  return (
    <>
      <Drawer
        navItems={NavItems}
        drawer={drawer}
        action={drawerAction.toggle}
      />
      <HeaderHomeThree action={drawerAction.toggle} />
      <Hero />
      <Services />
      <section className="coreal-listing-tab-area coreal-listing-tab-area-3 pt-90 pb-100">
        <div className="container custom-container">
          <div className="row">
            <div className="col-lg-12">
              <div className="section-title text-center pb-20">
                <span>Trending Listings</span>
                <h3 className="title">Services we offered</h3>
              </div>
            </div>
          </div>
          <ListingTab listingDatas={listingDatas} />
        </div>
      </section>
      <GetInTouch />
      <Gallery />
      <AgentMembers />
      <Features />
      <Sponsers />
      <Map />
      <Blogs />
      <FooterHomeThree />
      <BackToTop />
    </>
  );
}

export default HomeThree;
