import React from "react";
import listingData from "../../Data/HomeTwo";
import ListedGridData from "../../Data/ListedPropertiesGrid";
import NavItems from "../../Data/NavItems";
import BackToTop from "../../Helper/BackToTop";
import MargeHeaderDrawer from "../../Helper/MargeHeaderDrawer";
import FooterCom from "../../Partials/Footers/FooterHomeOne";
import Hero from "./Hero";
import MainSection from "./MainSection";

function ListedPropertiesRow() {
  return (
    <>
      <MargeHeaderDrawer navItems={NavItems} />
      <Hero />
      <MainSection
        listingData={listingData.listingData}
        listedGridData={ListedGridData.features}
      />
      <FooterCom />
      <BackToTop />
    </>
  );
}

export default ListedPropertiesRow;
