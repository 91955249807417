import React from "react";

function SearchBar({ search, action }) {
  return (
    <div className={`coreal-search ${search ? "active" : ""}`}>
      <div className="coreal-search__content coreal-search--mobile">
        <a
          className="coreal-search__close"
          onClick={(e) => action(e)}
          id="close-search"
        >
          <i className="fal fa-times"></i>
        </a>
        <h3>Search</h3>
        <form action="do_action" method="post">
          <div className="coreal-search-table">
            <div className="input-group">
              <input
                className="form-control coreal-input"
                type="text"
                placeholder="Search for products"
              />
              <div className="input-group-append">
                <button type="button">
                  <i className="fa fa-search"></i>
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default SearchBar;
