import React, { useState } from "react";
import SelectBox from "../Share/SelectBox";

function MainSection() {
  const sorting1 = ["sorting1", "sorting24", "sorting34", "sorting44"];
  const [sort1, setSort1] = useState("Web Developement");
  const changeSort1 = (value) => {
    setSort1(value);
  };
  return (
    <>
      <div className="coreal-contact-map pt-100">
        <iframe
          title="map"
          src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d14612.97540392891!2d90.497437!3d23.70298445!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sbd!4v1619507867071!5m2!1sen!2sbd"
          width="600"
          height="450"
          style={{ border: "0" }}
          allowFullScreen=""
          loading="lazy"
        ></iframe>
      </div>

      <div className="coreal-er-contact-us-area">
        <div className="container custom-container">
          <div className="row">
            <div className=" col-lg-12">
              <div className="coreal-er-contact-us-box">
                <form action="#">
                  <div className="coreal-er-contact-title">
                    <h4 className="coreal-er-title">
                      Get in touch & let us know
                    </h4>
                  </div>
                  <div className="row">
                    <div className=" col-lg-4">
                      <div className="coreal-er-input-box">
                        <input type="text" placeholder="Enter full name" />
                        <i className="fal fa-user"></i>
                      </div>
                    </div>
                    <div className=" col-lg-4">
                      <div className="coreal-er-input-box">
                        <input type="email" placeholder="Enter address" />
                        <i className="fal fa-envelope"></i>
                      </div>
                    </div>
                    <div className=" col-lg-4">
                      <div className="coreal-er-input-box coreal-er-select-item">
                        <div className="coreal-select-item">
                          <SelectBox
                            datas={sorting1}
                            selected={sort1}
                            action={changeSort1}
                          />
                        </div>
                      </div>
                    </div>
                    <div className=" col-lg-12">
                      <div className="coreal-er-input-box">
                        <textarea
                          name="#"
                          id="#"
                          cols="30"
                          rows="10"
                          placeholder="Enter message"
                        ></textarea>
                        <i className="fal fa-pencil"></i>
                      </div>
                    </div>
                  </div>
                  <button type="button" className="coreal-er-main-btn">
                    Submit Request
                  </button>
                </form>
              </div>
            </div>
          </div>
          <div className="row pt-90">
            <div className=" col-lg-4">
              <div className="coreal-er-contact-info-box">
                <h3 className="coreal-er-title">Corporate Office</h3>
                <ul>
                  <li>
                    Address: <span>Lorem 142 Str., 2352, Pro State, USA</span>
                  </li>
                  <li>
                    Phone: <span>+33-257634 534</span>
                  </li>
                  <li>
                    Email: <span>info@webexample.com</span>
                  </li>
                </ul>
                <a className="coreal-er-main-btn" href="#">
                  Make A Call
                </a>
              </div>
            </div>
            <div className=" col-lg-4">
              <div className="coreal-er-contact-info-box">
                <h3 className="coreal-er-title">Main HQ</h3>
                <ul>
                  <li>
                    Address: <span>Lorem 142 Str., 2352, Pro State, USA</span>
                  </li>
                  <li>
                    Phone: <span>+33-257634 534</span>
                  </li>
                  <li>
                    Email: <span>info@webexample.com</span>
                  </li>
                </ul>
                <a className="coreal-er-main-btn active" href="#">
                  Make A Call
                </a>
              </div>
            </div>
            <div className=" col-lg-4">
              <div className="coreal-er-contact-info-box">
                <h3 className="coreal-er-title">Advertise</h3>
                <ul>
                  <li>
                    Address: <span>Lorem 142 Str., 2352, Pro State, USA</span>
                  </li>
                  <li>
                    Phone: <span>+33-257634 534</span>
                  </li>
                  <li>
                    Email: <span>info@webexample.com</span>
                  </li>
                </ul>
                <a className="coreal-er-main-btn" href="#">
                  Make A Call
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default MainSection;
