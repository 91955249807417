import React from "react";

function category({ className }) {
  return (
    <>
      <section className={`coreal-category-area ${className || ""}`}>
        <div className="container custom-container">
          <div className="row">
            <div className="col-lg-12">
              <div className="section-title text-center">
                <span>Category</span>
                <h3 className="title">Best Categories</h3>
              </div>
              <div className="coreal-category-box d-flex">
                <div className="coreal-category-item">
                  <img
                    src={
                      require("../../assets/images/icon/category-1.svg").default
                    }
                    alt=""
                  />
                  <h4 className="title">
                    <a href="#">Duplex House</a>
                  </h4>
                  <span>20+ Listings</span>
                </div>
                <div className="coreal-category-item">
                  <img
                    src={
                      require("../../assets/images/icon/category-2.svg").default
                    }
                    alt=""
                  />
                  <h4 className="title">
                    <a href="#">International Reach</a>
                  </h4>
                  <span>20+ Listings</span>
                  <div className="rebon">
                    <span>NEW</span>
                  </div>
                </div>
                <div className="coreal-category-item">
                  <img
                    src={
                      require("../../assets/images/icon/category-3.svg").default
                    }
                    alt=""
                  />
                  <h4 className="title">
                    <a href="#">Committed Team</a>
                  </h4>
                  <span>20+ Listings</span>
                </div>
                <div className="coreal-category-item">
                  <img
                    src={
                      require("../../assets/images/icon/category-4.svg").default
                    }
                    alt=""
                  />
                  <h4 className="title">
                    <a href="#">Top Rated</a>
                  </h4>
                  <span>20+ Listings</span>
                </div>
                <div className="coreal-category-item">
                  <img
                    src={
                      require("../../assets/images/icon/category-5.svg").default
                    }
                    alt=""
                  />
                  <h4 className="title">
                    <a href="#">Trust Us </a>
                  </h4>
                  <span>20+ Listings</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default category;
