import React from "react";

function Blogs() {
  return (
    <section className="coreal-blog-area pt-90 pb-100">
      <div className="container custom-container">
        <div className="row">
          <div className="col-lg-12">
            <div className="section-title text-center">
              <span>News Feeds</span>
              <h3 className="title">Get every update</h3>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-3 col-md-6">
            <div className="coreal-single-blog coreal-single-blog-3 mt-30">
              <div className="thumb">
                <img
                  src={require(`../../assets/images/blog-1.png`).default}
                  alt=""
                />
                <div className="meta-date text-center">
                  <h5 className="title">23</h5>
                  <span>Jun</span>
                </div>
              </div>
              <div className="content">
                <div className="meta-items">
                  <div className="meta-categores">
                    <a href="#">Portfolio</a>
                  </div>
                  <div className="meta-author">
                    <span>
                      By <a href="#">Hilix H. Hexer</a>
                    </span>
                  </div>
                </div>
                <h4 className="title">
                  <a href="#">Modern apartment adjacent Modern </a>
                </h4>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6">
            <div className="coreal-single-blog coreal-single-blog-3 mt-30">
              <div className="thumb">
                <img
                  src={require(`../../assets/images/blog-2.png`).default}
                  alt=""
                />
                <div className="meta-date text-center">
                  <h5 className="title">23</h5>
                  <span>Jun</span>
                </div>
              </div>
              <div className="content">
                <div className="meta-items">
                  <div className="meta-categores">
                    <a href="#">Portfolio</a>
                  </div>
                  <div className="meta-author">
                    <span>
                      By <a href="#">Hilix H. Hexer</a>
                    </span>
                  </div>
                </div>
                <h4 className="title">
                  <a href="#">We are Offering the Best RED We are </a>
                </h4>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6">
            <div className="coreal-single-blog coreal-single-blog-3 mt-30">
              <div className="thumb">
                <img
                  src={require(`../../assets/images/blog-3.png`).default}
                  alt=""
                />
                <div className="meta-date text-center">
                  <h5 className="title">23</h5>
                  <span>Jun</span>
                </div>
              </div>
              <div className="content">
                <div className="meta-items">
                  <div className="meta-categores">
                    <a href="#">Portfolio</a>
                  </div>
                  <div className="meta-author">
                    <span>
                      By <a href="#">Hilix H. Hexer</a>
                    </span>
                  </div>
                </div>
                <h4 className="title">
                  <a href="#">
                    Project Luxury Villa in Rego Park Project Luxury
                  </a>
                </h4>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6">
            <div className="coreal-single-blog coreal-single-blog-3 mt-30">
              <div className="thumb">
                <img
                  src={require(`../../assets/images/blog-3.png`).default}
                  alt=""
                />
                <div className="meta-date text-center">
                  <h5 className="title">23</h5>
                  <span>Jun</span>
                </div>
              </div>
              <div className="content">
                <div className="meta-items">
                  <div className="meta-categores">
                    <a href="#">Portfolio</a>
                  </div>
                  <div className="meta-author">
                    <span>
                      By <a href="#">Hilix H. Hexer</a>
                    </span>
                  </div>
                </div>
                <h4 className="title">
                  <a href="#">
                    Of professionals is always here to help you with
                  </a>
                </h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Blogs;
