import React from "react";

function Hero() {
  return (
    <>
      <section className="coreal-hero-3-area">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8">
              <div className="coreal-hero-3-content">
                <h1
                  className="title wow fadeInUp"
                  data-wow-duration="2s"
                  data-wow-delay="0s"
                >
                  Make a perfect base for family.
                </h1>
                <div
                  className="coreal-hero-form wow fadeInUp"
                  data-wow-duration="2s"
                  data-wow-delay=".6s"
                >
                  <form action="#">
                    <div className="input-box">
                      <input type="text" placeholder="San Francisco, CA" />
                      <button type="button">
                        <i className="fal fa-search"></i> Search Now
                      </button>
                      <div className="coreal-select-item">
                        <select>
                          <option data-display="Sell">Sell</option>
                          <option value="1">Buy</option>
                          <option value="2">Rent</option>
                        </select>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="shape">
          <img
            src={require("../../assets/images/hero-shape.png").default}
            alt=""
          />
        </div>
      </section>
    </>
  );
}

export default Hero;
