import React from "react";

function FooterCom() {
  return (
    <>
      <footer className="coreal-footer-area">
        <div className="container custom-container">
          <div className="row">
            <div className="col-lg-3 col-md- col-sm-6">
              <div className="coreal-footer-widget">
                <div className="wdiget-title">
                  <h3 className="title">Get In Touch</h3>
                </div>
                <div className="coreal-footer-contact">
                  <ul>
                    <li>
                      <a href="mailto:info@examplemail.com">
                        info@examplemail.com
                      </a>
                    </li>
                    <li>
                      <a href="phoneto:098.097.987.09">098.097.987.09</a>
                    </li>
                    <li>
                      <a href="mailto:info@examplemail.com">
                        info@examplemail.com
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-6">
              <div className="coreal-footer-widget coreal-footer-navigation-item">
                <div className="wdiget-title">
                  <h3 className="title">More Pages</h3>
                </div>
                <div className="coreal-footer-navigation d-flex">
                  <ul>
                    <li>
                      <a href="#">FAQs</a>
                    </li>
                    <li>
                      <a href="#">Blog</a>
                    </li>
                    <li>
                      <a href="#">News</a>
                    </li>
                    <li>
                      <a href="#">Location</a>
                    </li>
                    <li>
                      <a href="#">Press</a>
                    </li>
                  </ul>
                  <ul>
                    <li>
                      <a href="#">Our Location</a>
                    </li>
                    <li>
                      <a href="#">Careers</a>
                    </li>
                    <li>
                      <a href="#">About</a>
                    </li>
                    <li>
                      <a href="#">Contact</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-6">
              <div className="coreal-footer-widget coreal-footer-post-item">
                <div className="wdiget-title">
                  <h3 className="title">News Insights</h3>
                </div>
                <div className="coreal-footer-post">
                  <div className="coreal-post-item">
                    <div className="thumb">
                      <img
                        src={
                          require(`../../../assets/images/footer-post-1.png`)
                            .default
                        }
                        alt=""
                      />
                    </div>
                    <div className="content">
                      <div className="meta-author">
                        <span>
                          By <a href="#">Hilix H. Hexer</a>
                        </span>
                      </div>
                      <h4 className="title">
                        <a href="#">Consult with customers not</a>
                      </h4>
                    </div>
                  </div>
                  <div className="coreal-post-item mt-10">
                    <div className="thumb">
                      <img
                        src={
                          require(`../../../assets/images/footer-post-2.png`)
                            .default
                        }
                        alt=""
                      />
                    </div>
                    <div className="content">
                      <div className="meta-author">
                        <span>
                          By <a href="#">Hilix H. Hexer</a>
                        </span>
                      </div>
                      <h4 className="title">
                        <a href="#">Luxury Villa In Los Angeles</a>
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-6">
              <div className="coreal-footer-widget coreal-footer-gallery-item">
                <div className="wdiget-title">
                  <h3 className="title">Instagram Feeds</h3>
                </div>
                <div className="coreal-footer-gallery">
                  <ul>
                    <li>
                      <a href="#">
                        <img
                          src={
                            require(`../../../assets/images/gallery-1.png`)
                              .default
                          }
                          alt=""
                        />
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <img
                          src={
                            require(`../../../assets/images/gallery-2.png`)
                              .default
                          }
                          alt=""
                        />
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <img
                          src={
                            require(`../../../assets/images/gallery-3.png`)
                              .default
                          }
                          alt=""
                        />
                      </a>
                    </li>
                  </ul>
                  <ul>
                    <li>
                      <a href="#">
                        <img
                          src={
                            require(`../../../assets/images/gallery-4.png`)
                              .default
                          }
                          alt=""
                        />
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <img
                          src={
                            require(`../../../assets/images/gallery-5.png`)
                              .default
                          }
                          alt=""
                        />
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <img
                          src={
                            require(`../../../assets/images/gallery-6.png`)
                              .default
                          }
                          alt=""
                        />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
      <div className="coreal-copyright-area">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="coreal-copyright-box d-block d-md-flex align-items-center justify-content-between">
                <div className="coreal-copyright-item">
                  <p>
                    Copyright & Design by @
                    <a href="https://themeforest.net/user/quomodotheme">
                      Quomodosoft
                    </a>{" "}
                    - 2021
                  </p>
                </div>
                <div className="coreal-copyright-social">
                  <ul>
                    <li>
                      <a href="#">
                        <i className="fab fa-facebook"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fab fa-twitter"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fab fa-behance"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fab fa-youtube"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fab fa-linkedin"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default FooterCom;
