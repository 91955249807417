import React from "react";

function Services() {
  return (
    <>
      <section className="coreal-fetaures-area">
        <div className="container custom-container">
          <div className="row">
            <div className="col-lg-12">
              <div className="section-title">
                <span>Services</span>
                <h3 className="title">Services we offered</h3>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-3 col-md-6 col-sm-6">
              <div className="coreal-service-item mt-30">
                <span>01</span>
                <h4 className="title">Property Management</h4>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt
                </p>
                <img
                  src={
                    require(`../../assets/images/icon/fetaures-4.svg`).default
                  }
                  alt=""
                />
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-6">
              <div className="coreal-service-item mt-30">
                <span>02</span>
                <h4 className="title">Mortage Service</h4>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt
                </p>
                <img
                  src={
                    require(`../../assets/images/icon/fetaures-1.svg`).default
                  }
                  alt=""
                />
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-6">
              <div className="coreal-service-item mt-30">
                <span>03</span>
                <h4 className="title">Consulting Service</h4>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt
                </p>
                <img
                  src={
                    require(`../../assets/images/icon/fetaures-2.svg`).default
                  }
                  alt=""
                />
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-6">
              <div className="coreal-service-item mt-30">
                <span>04</span>
                <h4 className="title">Recover Assets</h4>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt
                </p>
                <img
                  src={
                    require(`../../assets/images/icon/fetaures-3.svg`).default
                  }
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Services;
