import React from "react";
import ListedGridData from "../../Data/ListedPropertiesGrid";
import NavItems from "../../Data/NavItems";
import BackToTop from "../../Helper/BackToTop";
import MargeHeaderDrawer from "../../Helper/MargeHeaderDrawer";
import FooterCom from "../../Partials/Footers/FooterHomeOne";
import Hero from "./Hero";
import MainSection from "./MainSection";

function AddListing() {
  return (
    <>
      <MargeHeaderDrawer navItems={NavItems} />
      <Hero />
      <MainSection listedGridData={ListedGridData.features} />
      <FooterCom />
      <BackToTop />
    </>
  );
}

export default AddListing;
