import React from "react";

function DownloadArea() {
  return (
    <>
      <section className="coreal-download-area">
        <div className="container custom-container">
          <div className="row">
            <div className="col-lg-6">
              <div className="coreal-download-content">
                <span>Download App</span>
                <h3 className="title">
                  Best-of amenities & local highlights for mobile.
                </h3>
                <p>
                  Get real reviews from real residents about real neighborhoods.
                  The What Locals Say feature lets residents share what they
                  love about living in their neighborhood.
                </p>
                <ul>
                  <li>
                    <a href="#">
                      <img
                        src={
                          require(`../../assets/images/google-store.jpg`)
                            .default
                        }
                        alt=""
                      />
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <img
                        src={
                          require(`../../assets/images/apple-store.jpg`).default
                        }
                        alt=""
                      />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="coreal-download-thumb">
                <img
                  src={
                    require(`../../assets/images/download-mobile.png`).default
                  }
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default DownloadArea;
