import React, { useState } from "react";
import useToggle from "../../Hooks/useToggles";
import listingData from "../Data/HomeTwo";
import NavItems from "../Data/NavItems";
import BackToTop from "../Helper/BackToTop";
import PopupVideo from "../Helper/PopupVideo";
import Drawer from "../Mobile/Drawer";
import FooterHomeTwo from "../Partials/Footers/FooterHomeTwo";
import HeaderHomeTwo from "../Partials/Headers/HeaderHomeTwo";
import Blogs from "./Blogs";
import CtaSection from "./CtaSection";
import DestinationArea from "./DestinationArea";
import FaqSection from "./FaqSection";
import Hero from "./Hero";
import ListingTab from "./ListingTab";
import PropertyArea from "./PropertyArea";
import Sponsers from "./Sponsers";
import TrackingArea from "./TrackingArea";

function HomeTwo() {
  const listingDatas = listingData.listingData;
  const { trackingUsers } = listingData;
  const { blogs } = listingData;
  const [drawer, drawerAction] = useToggle(false);

  // video popup
  const [showVideo, setVideoValue] = useState(false);
  const handleShowVideo = (e) => {
    e.preventDefault();
    setVideoValue(!showVideo);
  };
  return (
    <>
      {showVideo && (
        <PopupVideo
          videoSrc="//www.youtube.com/embed/EE7NqzhMDms?autoplay=1"
          handler={(e) => handleShowVideo(e)}
        />
      )}
      <Drawer
        navItems={NavItems}
        drawer={drawer}
        action={drawerAction.toggle}
      />
      <HeaderHomeTwo action={drawerAction.toggle} />
      <Hero />
      <section className="coreal-listing-tab-area pt-100 pb-100">
        <div className="container custom-container">
          <ListingTab listingDatas={listingDatas} />
        </div>
      </section>
      <TrackingArea trackingUsers={trackingUsers} />
      <CtaSection />
      <FaqSection />
      <PropertyArea videoPopupAction={handleShowVideo} />
      <DestinationArea />
      <Sponsers />
      <Blogs blogs={blogs} />
      <FooterHomeTwo />
      <BackToTop />
    </>
  );
}

export default HomeTwo;
