import React from "react";
import SliderCom from "../Helper/SliderCom";

function DestinationArea() {
  const settings = {
    dots: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: false,
    speed: 1500,
    slidesToShow: 4,
    slidesToScroll: 1,
    centerMode: true,
    centerPadding: "120px",
    responsive: [
      {
        breakpoint: 1201,
        settings: {
          centerPadding: "0px",
        },
      },
      {
        breakpoint: 992,
        settings: {
          centerPadding: "0px",
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 768,
        settings: {
          centerPadding: "0px",
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 576,
        settings: {
          centerPadding: "0px",
          slidesToShow: 1,
        },
      },
    ],
  };
  return (
    <>
      <section className="coreal-destinations-area">
        <div className="container custom-container">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <div className="section-title">
                <span>Destinations</span>
                <h3 className="title">Destinations we love</h3>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="coreal-destinations-search-box">
                <form action="#">
                  <div className="input-box">
                    <input type="text" placeholder="Type city name here" />
                    <button type="button">
                      <i className="fal fa-search"></i>
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid p-0">
          <SliderCom
            className="row coreal-destinations-slider-active pt-35"
            settings={settings}
          >
            <div>
              <div className="coreal-destinations-location-item">
                <img
                  src={
                    require(`../../assets/images/destinations-1.png`).default
                  }
                  alt=""
                />
                <div className="coreal-destinations-overlay">
                  <div className="content">
                    <span>10 Listings</span>
                    <h4 className="title">
                      <a href="#">Tokyo</a>
                    </h4>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div className="coreal-destinations-location-item">
                <img
                  src={
                    require(`../../assets/images/destinations-2.png`).default
                  }
                  alt=""
                />
                <div className="coreal-destinations-overlay">
                  <div className="content">
                    <span>10 Listings</span>
                    <h4 className="title">
                      <a href="#">New York</a>
                    </h4>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div className="coreal-destinations-location-item">
                <img
                  src={
                    require(`../../assets/images/destinations-3.png`).default
                  }
                  alt=""
                />
                <div className="coreal-destinations-overlay">
                  <div className="content">
                    <span>10 Listings</span>
                    <h4 className="title">
                      <a href="#">Sanghai</a>
                    </h4>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div className="coreal-destinations-location-item">
                <img
                  src={
                    require(`../../assets/images/destinations-4.png`).default
                  }
                  alt=""
                />
                <div className="coreal-destinations-overlay">
                  <div className="content">
                    <span>10 Listings</span>
                    <h4 className="title">
                      <a href="#">Japan</a>
                    </h4>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div className="coreal-destinations-location-item">
                <img
                  src={
                    require(`../../assets/images/destinations-5.png`).default
                  }
                  alt=""
                />
                <div className="coreal-destinations-overlay">
                  <div className="content">
                    <span>10 Listings</span>
                    <h4 className="title">
                      <a href="#">dhaka</a>
                    </h4>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div className="coreal-destinations-location-item">
                <img
                  src={
                    require(`../../assets/images/destinations-6.png`).default
                  }
                  alt=""
                />
                <div className="coreal-destinations-overlay">
                  <div className="content">
                    <span>10 Listings</span>
                    <h4 className="title">
                      <a href="#">korea</a>
                    </h4>
                  </div>
                </div>
              </div>
            </div>
          </SliderCom>
        </div>
      </section>
    </>
  );
}

export default DestinationArea;
