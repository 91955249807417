import React from "react";

function AgentMembers() {
  return (
    <section className="coreal-agents-area">
      <div className="container custom-container">
        <div className="row">
          <div className="col-lg-12">
            <div className="section-title text-center">
              <span>Expert Agents</span>
              <h3 className="title">Meet Our Real Estate Agents</h3>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-3 col-md-6">
            <div
              className="coreal-agents-item wow fadeInUp"
              data-wow-duration="2s"
              data-wow-delay="0s"
            >
              <div className="thumb">
                <img
                  src={require(`../../assets/images/agents-1.png`).default}
                  alt=""
                />
              </div>
              <div className="content">
                <span>Founder</span>
                <h4 className="title"> Lydia Wise </h4>
                <ul>
                  <li>
                    <a href="#">
                      <i className="fal fa-phone"></i> 980. 098. 967. 83
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i className="fal fa-envelope"></i> info@webexample.com
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i className="fab fa-facebook-f"></i> fb.me/romada_halim
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6">
            <div
              className="coreal-agents-item wow fadeInUp"
              data-wow-duration="2s"
              data-wow-delay=".3s"
            >
              <div className="thumb">
                <img
                  src={require(`../../assets/images/agents-2.png`).default}
                  alt=""
                />
              </div>
              <div className="content">
                <span>Founder</span>
                <h4 className="title"> Olive Erickson </h4>
                <ul>
                  <li>
                    <a href="#">
                      <i className="fal fa-phone"></i> 980. 098. 967. 83
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i className="fal fa-envelope"></i> info@webexample.com
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i className="fab fa-facebook-f"></i> fb.me/romada_halim
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6">
            <div
              className="coreal-agents-item wow fadeInUp"
              data-wow-duration="2s"
              data-wow-delay=".6s"
            >
              <div className="thumb">
                <img
                  src={require(`../../assets/images/agents-3.png`).default}
                  alt=""
                />
              </div>
              <div className="content">
                <span>Founder</span>
                <h4 className="title"> Carl Knight </h4>
                <ul>
                  <li>
                    <a href="#">
                      <i className="fal fa-phone"></i> 980. 098. 967. 83
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i className="fal fa-envelope"></i> info@webexample.com
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i className="fab fa-facebook-f"></i> fb.me/romada_halim
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6">
            <div
              className="coreal-agents-item wow fadeInUp"
              data-wow-duration="2s"
              data-wow-delay=".9s"
            >
              <div className="thumb">
                <img
                  src={require(`../../assets/images/agents-4.png`).default}
                  alt=""
                />
              </div>
              <div className="content">
                <span>Founder</span>
                <h4 className="title">Romada H. Halim</h4>
                <ul>
                  <li>
                    <a href="#">
                      <i className="fal fa-phone"></i> 980. 098. 967. 83
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i className="fal fa-envelope"></i> info@webexample.com
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i className="fab fa-facebook-f"></i> fb.me/romada_halim
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default AgentMembers;
