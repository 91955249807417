import React, { useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import Agent from "./Components/AgentPages/Agent/index";
import AgentDetails from "./Components/AgentPages/AgentDetails/index";
import Contact from "./Components/Contact/index";
import PreLoader from "./Components/Helper/PreLoader";
import HomeOne from "./Components/HomeOne/index";
import HomeThree from "./Components/HomeThree/index";
import HomeTwo from "./Components/HomeTwo/index";
import News from "./Components/News/index";
import AddListing from "./Components/Pages/AddListing/index";
import Agency from "./Components/Pages/Agency/index";
import FAQ from "./Components/Pages/FAQ/index";
import ListDetails from "./Components/Pages/ListDetails/index";
import ListedPropertiesGrid from "./Components/Pages/ListedPropertiesGrid/index";
import ListedPropertiesRow from "./Components/Pages/ListedPropertiesRow/index";
import MapListing from "./Components/Pages/MapListing/index";

function Routers() {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  });
  return (
    <>
      {loading && (
        <div className={`coreal-loader ${loading ? "active" : ""}`}>
          <PreLoader />
        </div>
      )}
      <div className={`coreal-visible ${loading === false ? "active" : ""}`}>
        <Routes>
          <Route exact path="/" element={<HomeOne />} />
          <Route exact path="/home-two" element={<HomeTwo />} />
          <Route exact path="/home-three" element={<HomeThree />} />
          <Route exact path="/agency" element={<Agency />} />
          <Route exact path="/faq" element={<FAQ />} />
          <Route exact path="/map-listing" element={<MapListing />} />
          <Route exact path="/listed-grid" element={<ListedPropertiesGrid />} />
          <Route exact path="/listed-row" element={<ListedPropertiesRow />} />
          <Route exact path="/add-listing" element={<AddListing />} />
          <Route exact path="/list-details" element={<ListDetails />} />
          <Route exact path="/agent" element={<Agent />} />
          <Route exact path="/agent-details" element={<AgentDetails />} />
          <Route exact path="/news" element={<News />} />
          <Route exact path="/contact" element={<Contact />} />
        </Routes>
      </div>
    </>
  );
}
export default Routers;
