import React from "react";
import SliderCom from "../Helper/SliderCom";

function PropertyArea({ videoPopupAction }) {
  const settings = {
    dots: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: false,
    speed: 1500,
    slidesToShow: 9,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1600,
        settings: {
          slidesToShow: 6,
        },
      },
      {
        breakpoint: 1201,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  return (
    <>
      <section className="coreal-property-area">
        <div className="container custom-container">
          <div className="row">
            <div className="col-lg-4">
              <div className="section-title">
                <span>Property</span>
                <h3 className="title">Explore property type</h3>
              </div>
            </div>
            <div className="col-lg-8">
              <SliderCom
                settings={settings}
                className="row coreal-property-slider-active"
              >
                <div className="col-lg-3">
                  <div className="coreal-property-btn text-center">
                    <img
                      src={
                        require(`../../assets/images/icon/property-icon-1.png`)
                          .default
                      }
                      alt=""
                    />
                    <a href="#">Property</a>
                  </div>
                </div>
                <div className="col-lg-3">
                  <div className="coreal-property-btn text-center mt-30">
                    <img
                      src={
                        require(`../../assets/images/icon/property-icon-2.png`)
                          .default
                      }
                      alt=""
                    />
                    <a href="#">Apartment </a>
                  </div>
                </div>
                <div className="col-lg-3">
                  <div className="coreal-property-btn text-center">
                    <img
                      src={
                        require(`../../assets/images/icon/property-icon-3.png`)
                          .default
                      }
                      alt=""
                    />
                    <a href="#"> House </a>
                  </div>
                </div>
                <div className="col-lg-3">
                  <div className="coreal-property-btn text-center mt-30">
                    <img
                      src={
                        require(`../../assets/images/icon/property-icon-4.png`)
                          .default
                      }
                      alt=""
                    />
                    <a href="#"> Luxury Homes </a>
                  </div>
                </div>
                <div className="col-lg-3">
                  <div className="coreal-property-btn text-center">
                    <img
                      src={
                        require(`../../assets/images/icon/property-icon-5.png`)
                          .default
                      }
                      alt=""
                    />
                    <a href="#"> Villa </a>
                  </div>
                </div>
                <div className="col-lg-3">
                  <div className="coreal-property-btn text-center mt-30">
                    <img
                      src={
                        require(`../../assets/images/icon/property-icon-2.png`)
                          .default
                      }
                      alt=""
                    />
                    <a href="#">Property</a>
                  </div>
                </div>
                <div className="col-lg-3">
                  <div className="coreal-property-btn text-center">
                    <img
                      src={
                        require(`../../assets/images/icon/property-icon-3.png`)
                          .default
                      }
                      alt=""
                    />
                    <a href="#">Apartment</a>
                  </div>
                </div>
                <div className="col-lg-3">
                  <div className="coreal-property-btn text-center mt-30">
                    <img
                      src={
                        require(`../../assets/images/icon/property-icon-4.png`)
                          .default
                      }
                      alt=""
                    />
                    <a href="#">House</a>
                  </div>
                </div>
                <div className="col-lg-3">
                  <div className="coreal-property-btn text-center">
                    <img
                      src={
                        require(`../../assets/images/icon/property-icon-5.png`)
                          .default
                      }
                      alt=""
                    />
                    <a href="#">Luxury Homes</a>
                  </div>
                </div>
                <div className="col-lg-3">
                  <div className="coreal-property-btn text-center mt-30">
                    <img
                      src={
                        require(`../../assets/images/icon/property-icon-2.png`)
                          .default
                      }
                      alt=""
                    />
                    <a href="#">Villa</a>
                  </div>
                </div>
              </SliderCom>
              {/* <div className="">

              </div> */}
            </div>
          </div>
        </div>
      </section>
      <div className="coreal-property-thumb-area">
        <div className="container-fluid pl-50 pr-50">
          <div className="row">
            <div className="col-lg-5 col-md-5">
              <div
                className="coreal-property-thumb item-1 wow fadeInUp"
                data-wow-duration="2s"
                data-wow-delay="0s"
              >
                <img
                  src={
                    require("../../assets/images/property-view-1.png").default
                  }
                  alt=""
                />
                <div className="play">
                  <a
                    onClick={(e) => videoPopupAction(e)}
                    className="video-popup"
                  >
                    <i className="fas fa-play"></i>
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-7 col-md-7">
              <div
                className="coreal-property-thumb item-2 wow fadeInUp"
                data-wow-duration="2s"
                data-wow-delay=".6s"
              >
                <img
                  src={
                    require("../../assets/images/property-view-2.png").default
                  }
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default PropertyArea;
