import React, { useState } from "react";
import SelectBox from "../../Share/SelectBox";

function Hero() {
  const sorting = ["sorting1", "sorting2", "sorting3", "sorting4"];
  const [sort, setSort] = useState("Sorting");
  const changeSort = (value) => {
    setSort(value);
  };
  const filterData = [
    "filterData1",
    "filterData2",
    "filterData3",
    "filterData4",
  ];
  const [filt, setFilt] = useState("Filtering");
  const changeFilter = (value) => {
    setFilt(value);
  };
  return (
    <>
      <div className="coreal-map-listing-topbar-area">
        <div className="container custom-container">
          <div className="row">
            <div className="col-lg-12">
              <div className="coreal-listed-porperties-topbar d-block d-md-flex align-items-center justify-content-between pt-60">
                <div className="coreal-page-listed-result">
                  <p>
                    Showing <span>1–10</span> of <span>45</span> results
                  </p>
                </div>
                <div className="coreal-listed-porperties-btns">
                  <div className="coreal-select-item">
                    <SelectBox
                      datas={sorting}
                      selected={sort}
                      action={changeSort}
                    />
                  </div>
                  <ul className="nav nav-pills" id="pills-tab" role="tablist">
                    <li className="nav-item" role="presentation">
                      <button
                        className="nav-link active"
                        id="pills-home-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#pills-home"
                        type="button"
                        role="tab"
                        aria-selected="true"
                      >
                        <i className="fal fa-th"></i>
                      </button>
                    </li>
                    <li className="nav-item" role="presentation">
                      <button
                        className="nav-link"
                        id="pills-profile-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#pills-profile"
                        type="button"
                        role="tab"
                        aria-selected="false"
                      >
                        <i className="fal fa-list"></i>
                      </button>
                    </li>
                  </ul>
                  <div className="coreal-select-item pl-15">
                    <SelectBox
                      datas={filterData}
                      selected={filt}
                      action={changeFilter}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Hero;
