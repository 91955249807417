const data = {
  listingData: [
    {
      id: Math.random(),
      price: 8917,
      title: "2166 20th Ave San Francisco, CA",
      bed: 2,
      bath: 4,
      sqft: 3456,
      cover: "properties-1.jpg",
      user: "user-1.jpg",
      category: "Family",
    },
    {
      id: Math.random(),
      price: 8917,
      title: "2166 20th Ave San Francisco, CA",
      bed: 2,
      bath: 4,
      sqft: 3456,
      cover: "properties-1.jpg",
      user: "user-1.jpg",
      category: "Family",
    },
    {
      id: Math.random(),
      price: 8917,
      title: "2166 20th Ave San Francisco, CA",
      bed: 2,
      bath: 4,
      sqft: 3456,
      cover: "properties-1.jpg",
      user: "user-1.jpg",
      category: "Family",
    },
    {
      id: Math.random(),
      price: 8917,
      title: "2166 20th Ave San Francisco, CA",
      bed: 2,
      bath: 4,
      sqft: 3456,
      cover: "properties-1.jpg",
      user: "user-1.jpg",
      category: "Family",
    },
    {
      id: Math.random(),
      price: 8917,
      title: "2166 20th Ave San Francisco, CA",
      bed: 2,
      bath: 4,
      sqft: 3456,
      cover: "properties-1.jpg",
      user: "user-1.jpg",
      category: "Family",
    },
    {
      id: Math.random(),
      price: 8917,
      title: "2166 20th Ave San Francisco, CA",
      bed: 2,
      bath: 4,
      sqft: 3456,
      cover: "properties-1.jpg",
      user: "user-1.jpg",
      category: "Family",
    },
    {
      id: Math.random(),
      price: 8917,
      title: "2166 20th Ave San Francisco, CA",
      bed: 2,
      bath: 4,
      sqft: 3456,
      cover: "properties-1.jpg",
      user: "user-1.jpg",
      category: "Family",
    },
    {
      id: Math.random(),
      price: 8917,
      title: "2166 20th Ave San Francisco, CA",
      bed: 2,
      bath: 4,
      sqft: 3456,
      cover: "properties-1.jpg",
      user: "user-1.jpg",
      category: "Family",
    },
    {
      id: Math.random(),
      price: 8917,
      title: "2166 20th Ave San Francisco, CA",
      bed: 2,
      bath: 4,
      sqft: 3456,
      cover: "properties-1.jpg",
      user: "user-1.jpg",
      category: "Family",
    },
    {
      id: Math.random(),
      price: 8917,
      title: "2166 20th Ave San Francisco, CA",
      bed: 2,
      bath: 4,
      sqft: 3456,
      cover: "properties-1.jpg",
      user: "user-1.jpg",
      category: "Family",
    },
    {
      id: Math.random(),
      price: 8917,
      title: "2166 20th Ave San Francisco, CA",
      bed: 2,
      bath: 4,
      sqft: 3456,
      cover: "properties-1.jpg",
      user: "user-1.jpg",
      category: "Family",
    },
    {
      id: Math.random(),
      price: 8917,
      title: "2166 20th Ave San Francisco, CA",
      bed: 2,
      bath: 4,
      sqft: 3456,
      cover: "properties-1.jpg",
      user: "user-1.jpg",
      category: "Family",
    },
    {
      id: Math.random(),
      price: 8917,
      title: "2166 20th Ave San Francisco, CA",
      bed: 2,
      bath: 4,
      sqft: 3456,
      cover: "properties-1.jpg",
      user: "user-1.jpg",
      category: "Family",
    },
    {
      id: Math.random(),
      price: 8917,
      title: "2166 20th Ave San Francisco, CA",
      bed: 2,
      bath: 4,
      sqft: 3456,
      cover: "properties-1.jpg",
      user: "user-1.jpg",
      category: "Family",
    },
    {
      id: Math.random(),
      price: 8917,
      title: "2166 20th Ave San Francisco, CA",
      bed: 2,
      bath: 4,
      sqft: 3456,
      cover: "properties-1.jpg",
      user: "user-1.jpg",
      category: "Family",
    },
    {
      id: Math.random(),
      price: 8917,
      title: "2166 20th Ave San Francisco, CA",
      bed: 2,
      bath: 4,
      sqft: 3456,
      cover: "properties-1.jpg",
      user: "user-1.jpg",
      category: "Family",
    },
    {
      id: Math.random(),
      price: 8917,
      title: "2166 20th Ave San Francisco, CA",
      bed: 2,
      bath: 4,
      sqft: 3456,
      cover: "properties-1.jpg",
      user: "user-1.jpg",
      category: "Family",
    },
    {
      id: Math.random(),
      price: 8917,
      title: "2166 20th Ave San Francisco, CA",
      bed: 2,
      bath: 4,
      sqft: 3456,
      cover: "properties-1.jpg",
      user: "user-1.jpg",
      category: "Family",
    },
  ],
  trackingUsers: [
    {
      id: Math.random(),
      user: "user-1.jpg",
      username: "Miranda H. Halim",
      phone: "897. 987. 867. 76",
      email: "info@webexample.com",
    },
    {
      id: Math.random(),
      user: "user-1.jpg",
      username: "Miranda H. Halim",
      phone: "897. 987. 867. 76",
      email: "info@webexample.com",
    },
    {
      id: Math.random(),
      user: "user-1.jpg",
      username: "Miranda H. Halim",
      phone: "897. 987. 867. 76",
      email: "info@webexample.com",
    },
    {
      id: Math.random(),
      user: "user-1.jpg",
      username: "Miranda H. Halim",
      phone: "897. 987. 867. 76",
      email: "info@webexample.com",
    },
    {
      id: Math.random(),
      user: "user-1.jpg",
      username: "Miranda H. Halim",
      phone: "897. 987. 867. 76",
      email: "info@webexample.com",
    },
    {
      id: Math.random(),
      user: "user-1.jpg",
      username: "Miranda H. Halim",
      phone: "897. 987. 867. 76",
      email: "info@webexample.com",
    },
    {
      id: Math.random(),
      user: "user-1.jpg",
      username: "Miranda H. Halim",
      phone: "897. 987. 867. 76",
      email: "info@webexample.com",
    },
    {
      id: Math.random(),
      user: "user-1.jpg",
      username: "Miranda H. Halim",
      phone: "897. 987. 867. 76",
      email: "info@webexample.com",
    },
  ],
  blogs: [
    {
      id: Math.random(),
      title: "Team of professionals is always here to help",
      cover: "blog-1.png",
      username: "Hilix H. Hexer",
      category: "Portfolio",
    },
    {
      id: Math.random(),
      title: "Team of professionals is always here to help",
      cover: "blog-2.png",
      username: "Hilix H. Hexer",
      category: "Portfolio",
    },
    {
      id: Math.random(),
      title: "Team of professionals is always here to help",
      cover: "blog-3.png",
      username: "Hilix H. Hexer",
      category: "Portfolio",
    },
  ],
};
export default data;
