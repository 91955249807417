import React from "react";
import useToggle from "../../Hooks/useToggles";
import Drawer from "../Mobile/Drawer";
import Header from "../Partials/Headers/HeaderHomeOne";
import SearchBar from "../Partials/SearchBar";

function MargeHeaderDrawer({ navItems = [] }) {
  const [drawer, drawerAction] = useToggle(false);
  const [search, setSearch] = useToggle(false);
  return (
    <>
      <SearchBar search={search} action={setSearch.toggle} />
      <Drawer
        navItems={navItems}
        drawer={drawer}
        action={drawerAction.toggle}
      />
      <Header action={drawerAction.toggle} searchAction={setSearch.toggle} />
    </>
  );
}

export default MargeHeaderDrawer;
