import React, { useState } from "react";
import CardRowStyle from "../../Card/CardRowStyle";
import CardStyleOne from "../../Card/CardStyleOne";
import SelectBox from "../../Share/SelectBox";

function MainSection({ listingData, listedGridData }) {
  const [selected, setSelected] = useState([]);
  const [tab, setTab] = useState("row");
  const tabContoller = (e, value) => {
    e.preventDefault();
    setTab(value);
  };

  // filter
  const sorting = ["sorting1", "sorting2", "sorting3", "sorting4"];
  const [sort, setSort] = useState("Sorting");
  const changeSort = (value) => {
    setSort(value);
  };

  const sorting1 = ["sorting1", "sorting24", "sorting34", "sorting44"];
  const [sort1, setSort1] = useState("Sorting");
  const changeSort1 = (value) => {
    setSort1(value);
  };
  const sorting2 = ["sorting11", "sorting21", "sorting31", "sorting41"];
  const [sort2, setSort2] = useState("Sorting");
  const changeSort2 = (value) => {
    setSort2(value);
  };
  const sorting3 = ["sorting12", "sorting22", "sorting32", "sorting42"];
  const [sort3, setSort3] = useState("Sorting");
  const changeSort3 = (value) => {
    setSort3(value);
  };
  const sorting4 = ["sorting13", "sorting23", "sorting33", "sorting43"];
  const [sort4, setSort4] = useState("Sorting");
  const changeSort4 = (value) => {
    setSort4(value);
  };

  const handleChange = (id) => {
    let selectedItem = selected;
    let findItem = selected.indexOf(id);
    findItem > -1 ? selectedItem.splice(findItem, 1) : selectedItem.push(id);
    setSelected(selectedItem);
  };
  return (
    <>
      <section className="coreal-listed-porperties-area pt-70">
        <div className="container custom-container">
          <div className="row">
            <div className="col-lg-8">
              <div className="coreal-listed-porperties mt-30">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="coreal-listed-porperties-topbar d-block d-md-flex align-items-center justify-content-between">
                      <div className="coreal-page-listed-result">
                        <p>
                          Showing <span>1–10</span> of <span>45</span> results
                        </p>
                      </div>
                      <div className="coreal-listed-porperties-btns">
                        <div className="coreal-select-item">
                          <SelectBox
                            datas={sorting}
                            selected={sort}
                            action={changeSort}
                          />
                        </div>
                        <ul
                          className="nav nav-pills"
                          id="pills-tab"
                          role="tablist"
                        >
                          <li className="nav-item" role="presentation">
                            <button
                              onClick={(e) => tabContoller(e, "col")}
                              className={`nav-link ${
                                tab === "col" ? "active" : ""
                              }`}
                              id="pills-home-tab"
                              data-bs-toggle="pill"
                              data-bs-target="#pills-home"
                              type="button"
                              role="tab"
                              aria-controls="pills-home"
                              aria-selected="true"
                            >
                              <i className="fal fa-th"></i>
                            </button>
                          </li>
                          <li className="nav-item" role="presentation">
                            <button
                              onClick={(e) => tabContoller(e, "row")}
                              className={`nav-link ${
                                tab === "row" ? "active" : ""
                              }`}
                              id="pills-profile-tab"
                              data-bs-toggle="pill"
                              data-bs-target="#pills-profile"
                              type="button"
                              role="tab"
                              aria-controls="pills-profile"
                              aria-selected="false"
                            >
                              <i className="fal fa-list"></i>
                            </button>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="tab-content" id="pills-tabContent">
                  <div
                    className={`tab-pane fade ${
                      tab === "col" ? "active show" : ""
                    }`}
                    id="pills-home"
                    role="tabpanel"
                    aria-labelledby="pills-home-tab"
                  >
                    <div className="row">
                      {listingData.slice(0, 12).map((value) => (
                        <div
                          key={`${value.id}grid`}
                          className="col-lg-6 col-md-6"
                        >
                          <CardStyleOne datas={value} />
                        </div>
                      ))}
                    </div>
                  </div>
                  <div
                    className={`tab-pane fade ${
                      tab === "row" ? "active show" : ""
                    }`}
                    id="pills-profile"
                    role="tabpanel"
                    aria-labelledby="pills-profile-tab"
                  >
                    {listingData.slice(0, 7).map((value) => (
                      <CardRowStyle key={`${value.id}row`} datas={value} />
                    ))}
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-12">
                    <div className="coreal-listing-properties-pagination d-flex justify-content-center mt-50">
                      <nav aria-label="Page navigation example">
                        <ul className="pagination">
                          <li className="page-item">
                            <a className="page-link" href="#">
                              <i className="fal fa-arrow-left"></i>
                            </a>
                          </li>
                          <li className="page-item">
                            <a className="page-link" href="#">
                              01
                            </a>
                          </li>
                          <li className="page-item">
                            <a className="page-link" href="#">
                              02
                            </a>
                          </li>
                          <li className="page-item">
                            <a className="page-link" href="#">
                              ---
                            </a>
                          </li>
                          <li className="page-item">
                            <a className="page-link" href="#">
                              10
                            </a>
                          </li>
                          <li className="page-item">
                            <a className="page-link" href="#">
                              <i className="fal fa-arrow-right"></i>
                            </a>
                          </li>
                        </ul>
                      </nav>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="listing-details-sidebar mt-30">
                <div className="listing-details-find sidebar-widget">
                  <div className="sidebar-widget-title">
                    <h3 className="title">Find your home</h3>
                  </div>
                  <div className="listing-details-find-box mt-30">
                    <form action="#">
                      <div className="input-box">
                        <input type="text" placeholder="Enter keyword" />
                      </div>
                      <div className="">
                        <div className="coreal-select-item">
                          <SelectBox
                            className="mb-20"
                            datas={sorting1}
                            selected={sort1}
                            action={changeSort1}
                          />
                        </div>
                      </div>
                      <div className="">
                        <div className="coreal-select-item">
                          <SelectBox
                            className="mb-20"
                            datas={sorting2}
                            selected={sort2}
                            action={changeSort2}
                          />
                        </div>
                      </div>
                      <div className="">
                        <div className="coreal-select-item">
                          <SelectBox
                            className="mb-20"
                            datas={sorting3}
                            selected={sort3}
                            action={changeSort3}
                          />
                        </div>
                      </div>
                      <div className="">
                        <div className="coreal-select-item">
                          <SelectBox
                            className="mb-20"
                            datas={sorting4}
                            selected={sort4}
                            action={changeSort4}
                          />
                        </div>
                      </div>
                      <div className="input-box">
                        <button type="button">
                          <i className="far fa-search"></i> Search Now
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
                <div className="listing-details-find sidebar-widget mt-45">
                  <div className="sidebar-widget-title">
                    <h3 className="title">Other features</h3>
                  </div>
                  <div className="listed-featured-checkbox">
                    <div className="coreal-add-listing-property-form-box pt-10">
                      <ul className="coreal-checkbox-box checkbox_style6">
                        {listedGridData.map((item) => (
                          <li
                            key={item.id}
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                            }}
                          >
                            <div
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <input
                                type="checkbox"
                                onChange={() => handleChange(item.id)}
                                selected={selected.includes(item.id)}
                              />
                              <span>{item.name}</span>
                            </div>

                            <i className="fal fa-check float-end"></i>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="listing-details-featured-properties sidebar-widget mt-45">
                  <div className="sidebar-widget-title">
                    <h3 className="title">Featured Properties</h3>
                  </div>
                  <div className="listing-details-featured-properties-item mt-30">
                    <div className="thumb">
                      <img
                        src={
                          require(`../../../assets/images/featured-properties-thumb.png`)
                            .default
                        }
                        alt=""
                      />
                      <i className="fal fa-heart"></i>
                      <div className="properties-price">
                        <span>House For Sale</span>
                        <h3 className="title">$98,0000</h3>
                      </div>
                    </div>
                    <div className="content">
                      <p>
                        <a href="#">2166 20th Ave San Francisco, CA</a>
                      </p>
                      <ul>
                        <li>
                          <i className="fal fa-bed"></i> 5 Bed
                        </li>
                        <li>
                          <i className="fal fa-bath"></i> 2 Bath
                        </li>
                        <li>
                          <i className="fal fa-expand"></i> 4100 sqft
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="coreal-listing-details-add mt-45">
                  <a href="#">
                    <img
                      src={require(`../../../assets/images/ad.png`).default}
                      alt=""
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default MainSection;
