import React from "react";

function ExploreSection() {
  return (
    <>
      <section className="coreal-explore-area">
        <div className="container-fluid p-0">
          <div className="row">
            <div className="col-lg-12">
              <div className="coreal-explore-top-explore">
                <span>Explore a neighborhood or city</span>
                <form action="#">
                  <div className="input-box">
                    <input type="text" placeholder="Type city name here" />
                    <button type="button">
                      <i className="fal fa-search"></i>
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-7">
              <div className="row mr-15">
                <div className="col-lg-4 col-md-4 col-sm-6">
                  <div className="coreal-explore-item mt-30">
                    <img
                      src={require(`../../assets/images/explore-1.jpg`).default}
                      alt=""
                    />
                    <div className="coreal-explore-overlay">
                      <span>10 Listings</span>
                      <h3 className="title">
                        <a href="#">Hong Kong</a>
                      </h3>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6">
                  <div className="coreal-explore-item mt-30">
                    <img
                      src={require(`../../assets/images/explore-2.jpg`).default}
                      alt=""
                    />
                    <div className="coreal-explore-overlay">
                      <span>10 Listings</span>
                      <h3 className="title">
                        <a href="#">Boston</a>
                      </h3>
                    </div>
                  </div>
                  <div className="coreal-explore-item mt-30">
                    <img
                      src={require(`../../assets/images/explore-3.jpg`).default}
                      alt=""
                    />
                    <div className="coreal-explore-overlay">
                      <span>10 Listings</span>
                      <h3 className="title">
                        <a href="#">New Castle</a>
                      </h3>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6">
                  <div className="coreal-explore-item mt-30 item">
                    <img
                      src={require(`../../assets/images/explore-4.jpg`).default}
                      alt=""
                    />
                    <div className="coreal-explore-overlay">
                      <span>10 Listings</span>
                      <h3 className="title">
                        <a href="#">Tokyo</a>
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-5">
              <div className="row pl-15">
                <div className="col-lg-6 col-md-6 col-sm-6">
                  <div className="coreal-explore-item mt-30">
                    <img
                      src={require(`../../assets/images/explore-5.jpg`).default}
                      alt=""
                    />
                    <div className="coreal-explore-overlay">
                      <span>10 Listings</span>
                      <h3 className="title">
                        <a href="#">Tunsia</a>
                      </h3>
                    </div>
                  </div>
                  <div className="coreal-explore-item mt-30">
                    <img
                      src={require(`../../assets/images/explore-6.jpg`).default}
                      alt=""
                    />
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-6">
                  <div className="coreal-explore-item mt-30">
                    <img
                      src={require(`../../assets/images/explore-7.jpg`).default}
                      alt=""
                    />
                    <div className="coreal-explore-overlay">
                      <span>10 Listings</span>
                      <h3 className="title">
                        <a href="#">Rome</a>
                      </h3>
                    </div>
                  </div>
                  <div className="coreal-explore-item mt-30">
                    <img
                      src={require(`../../assets/images/explore-8.jpg`).default}
                      alt=""
                    />
                    <div className="coreal-explore-overlay">
                      <span>10 Listings</span>
                      <h3 className="title">
                        <a href="#">Dhaka</a>
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default ExploreSection;
