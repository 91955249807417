import React from "react";

function SponserSection() {
  return (
    <>
      <div className="coreal-sponser-area  pb-40">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-10">
              <div className="coreal-sponser-box">
                <div className="item-list">
                  <div className="item text-center">
                    <a href="#">
                      <img
                        src={
                          require(`../../assets/images/sopnser-1.png`).default
                        }
                        alt=""
                      />
                    </a>
                  </div>
                  <div className="item text-center">
                    <a href="#">
                      <img
                        src={
                          require(`../../assets/images/sopnser-2.png`).default
                        }
                        alt=""
                      />
                    </a>
                  </div>
                  <div className="item text-center">
                    <a href="#">
                      <img
                        src={
                          require(`../../assets/images/sopnser-3.png`).default
                        }
                        alt=""
                      />
                    </a>
                  </div>
                  <div className="item text-center">
                    <a href="#">
                      <img
                        src={
                          require(`../../assets/images/sopnser-4.png`).default
                        }
                        alt=""
                      />
                    </a>
                  </div>
                  <div className="item text-center">
                    <a href="#">
                      <img
                        src={
                          require(`../../assets/images/sopnser-5.png`).default
                        }
                        alt=""
                      />
                    </a>
                  </div>
                  <div className="item text-center">
                    <a href="#">
                      <img
                        src={
                          require(`../../assets/images/sopnser-6.png`).default
                        }
                        alt=""
                      />
                    </a>
                  </div>
                  <div className="item text-center">
                    <a href="#">
                      <img
                        src={
                          require(`../../assets/images/sopnser-7.png`).default
                        }
                        alt=""
                      />
                    </a>
                  </div>
                  <div className="item text-center">
                    <a href="#">
                      <img
                        src={
                          require(`../../assets/images/sopnser-8.png`).default
                        }
                        alt=""
                      />
                    </a>
                  </div>
                  <div className="item text-center">
                    <a href="#">
                      <img
                        src={
                          require(`../../assets/images/sopnser-9.png`).default
                        }
                        alt=""
                      />
                    </a>
                  </div>
                  <div className="item text-center">
                    <a href="#">
                      <img
                        src={
                          require(`../../assets/images/sopnser-10.png`).default
                        }
                        alt=""
                      />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SponserSection;
