import React, { useState } from "react";

function MainSection({ data = [] }) {
  const [tab, setTab] = useState(data[0].id);
  const handler = (e, value) => {
    e.preventDefault();
    if (value !== tab) {
      setTab(value);
    } else {
      setTab();
    }
  };
  return (
    <section className="coreal-my-listing-area pt-100">
      <div className="container custom-container">
        <div className="row">
          <div className="col-lg-8">
            <div className="coreal-faq-item mb-45">
              <div className="coreal-listing-details-tab-box coreal-faq-accordion">
                <div className="accordion" id="accordionExample">
                  {data.map((value) => (
                    <div key={`${value.id}tab`} className="accordion-item">
                      <div
                        onClick={(e) => handler(e, value.id)}
                        className="accordion-header"
                        id="heading1"
                      >
                        <button
                          className={`accordion-button ${
                            value.id === tab ? "collapsed" : ""
                          }`}
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapse1"
                          aria-expanded="true"
                          aria-controls="collapse1"
                        >
                          <span>{value.question}</span>
                        </button>
                      </div>
                      <div
                        id="collapse1"
                        className={`accordion-collapse collapse ${
                          value.id === tab ? "show" : ""
                        }`}
                        aria-labelledby="heading1"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body">
                          <p>{value.description}</p>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="listing-details-find sidebar-widget mb-45">
              <div className="sidebar-widget-title">
                <h3 className="title">Custom Request</h3>
              </div>
              <div className="listing-details-find-box mt-30">
                <form action="#">
                  <div className="input-box">
                    <input type="text" placeholder="Enter keyword" />
                    <i className="fal fa-user"></i>
                  </div>
                  <div className="input-box">
                    <input type="text" placeholder="Email address" />
                    <i className="fal fa-envelope"></i>
                  </div>
                  <div className="input-box">
                    <input type="text" placeholder="Phone number" />
                    <i className="fal fa-phone"></i>
                  </div>
                  <div className="input-box">
                    <input type="text" placeholder="Budget" />
                    <i className="fal fa-star"></i>
                  </div>
                  <div className="input-box">
                    <textarea
                      name="#"
                      id="#"
                      cols="30"
                      rows="10"
                      placeholder="Message"
                    ></textarea>
                    <i className="fal fa-pen"></i>
                  </div>

                  <div className="input-box">
                    <button type="button">Make A Request</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default MainSection;
