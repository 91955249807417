import React, { useState } from "react";

function FaqSection() {
  const [tab, setTab] = useState(1);
  const handler = (e, value) => {
    e.preventDefault();
    setTab(value);
  };
  return (
    <>
      <section className="coreal-faq-area pb-100">
        <div className="container custom-container">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <div className="coreal-faq--thumb">
                <img
                  src={require(`../../assets/images/faq-thumb.jpg`).default}
                  alt=""
                />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="coreal-faq-content pl-50">
                <div className="section-title pb-10">
                  <span>FAQ</span>
                  <h3 className="title">Get every single answers from here.</h3>
                </div>
                <div className="coreal-listing-details-tab-box coreal-faq-accordion">
                  <div className="accordion" id="accordionExample">
                    <div className="accordion-item">
                      <div
                        onClick={(e) => handler(e, 1)}
                        className="accordion-header"
                        id="headingOne"
                      >
                        <button
                          className={`accordion-button ${
                            tab === 1 ? "collapsed" : ""
                          }`}
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseOne"
                          aria-expanded="true"
                          aria-controls="collapseOne"
                        >
                          <span>How can we update thes kind WP Theme?</span>
                        </button>
                      </div>
                      <div
                        id="collapseOne"
                        className={`accordion-collapse collapse ${
                          tab === 1 ? "show" : ""
                        }`}
                        aria-labelledby="headingOne"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body">
                          <p>
                            The industry standard states that somewhere around a
                            55% bounce rate is average. That's really general
                            information, though, and it isn't that useful unless
                            you're measuring it in a specific way.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <div
                        onClick={(e) => handler(e, 2)}
                        className="accordion-header"
                        id="headingTwo"
                      >
                        <button
                          className={`accordion-button ${
                            tab === 2 ? "collapsed" : ""
                          }`}
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseTwo"
                          aria-expanded="false"
                          aria-controls="collapseTwo"
                        >
                          <span>
                            How can we Browse Featured Communities Below?
                          </span>
                        </button>
                      </div>
                      <div
                        id="collapseTwo"
                        className={`accordion-collapse collapse ${
                          tab === 2 ? "show" : ""
                        }`}
                        aria-labelledby="headingTwo"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body">
                          <p>
                            The industry standard states that somewhere around a
                            55% bounce rate is average. That's really general
                            information, though, and it isn't that useful unless
                            you're measuring it in a specific way.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <div
                        onClick={(e) => handler(e, 3)}
                        className="accordion-header"
                        id="headingThree"
                      >
                        <button
                          className={`accordion-button ${
                            tab === 3 ? "collapsed" : ""
                          }`}
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseThree"
                          aria-expanded="false"
                          aria-controls="collapseThree"
                        >
                          <span>Do you store any of my information?</span>
                        </button>
                      </div>
                      <div
                        id="collapseThree"
                        className={`accordion-collapse collapse ${
                          tab === 3 ? "show" : ""
                        }`}
                        aria-labelledby="headingThree"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body">
                          <p>
                            The industry standard states that somewhere around a
                            55% bounce rate is average. That's really general
                            information, though, and it isn't that useful unless
                            you're measuring it in a specific way.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default FaqSection;
