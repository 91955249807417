const data = [
  {
    id: Math.random(),
    title: "home",
    link: "#",
    submenu: true,
    items: [
      {
        id: `${Math.random()}sub`,
        title: "home one",
        link: "/",
      },
      {
        title: "home two",
        link: "/home-two",
      },
      {
        title: "home three",
        link: "/home-three",
      },
    ],
  },
  {
    id: Math.random(),
    title: "pages",
    link: "#",
    submenu: true,
    items: [
      {
        id: `${Math.random()}sub`,
        title: "Agency",
        link: "/agency",
      },
      {
        title: "FAQ",
        link: "/faq",
      },
      {
        title: "Map Listing",
        link: "/map-listing",
      },
      {
        title: "Listed Propreties Grid",
        link: "/listed-grid",
      },
      {
        title: "Listed Propreties List",
        link: "/listed-row",
      },
      {
        title: "Add Listing",
        link: "/add-listing",
      },
      {
        title: "List Details",
        link: "/list-details",
      },
    ],
  },
  {
    id: Math.random(),
    title: "Agents",
    link: "#",
    submenu: true,
    items: [
      {
        id: `${Math.random()}sub`,
        title: "Agent",
        link: "/agent",
      },
      {
        title: "Agent Details",
        link: "/agent-details",
      },
    ],
  },
  {
    id: Math.random(),
    title: "News",
    link: "/news",
    submenu: false,
  },
  {
    id: Math.random(),
    title: "Contact",
    link: "/contact",
    submenu: false,
  },
];
export default data;
