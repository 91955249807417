const data = {
  features: [
    {
      id: 1,
      name: "Air Conditioning",
    },
    {
      id: 2,
      name: "Barbeque",
    },
    {
      id: 3,
      name: "Dryer",
    },
    {
      id: 4,
      name: "Gym",
    },
    {
      id: 5,
      name: "Laundry",
    },
    {
      id: 6,
      name: "Lawn",
    },
    {
      id: 7,
      name: "Microwave",
    },
    {
      id: 8,
      name: "Refrigerator",
    },
    {
      id: 9,
      name: "Sauna",
    },
    {
      id: 10,
      name: "Swimming Pool",
    },
    {
      id: 11,
      name: "TV Cable",
    },
    {
      id: 12,
      name: "Washer",
    },
    {
      id: 13,
      name: "Wifi",
    },
    {
      id: 14,
      name: "Window Coverings",
    },
  ],
};
export default data;
