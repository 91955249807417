import React from "react";

function CtaSection() {
  return (
    <>
      <section className="coreal-cta-area">
        <div className="container custom-container">
          <div className="row">
            <div className="col-lg-12">
              <div className="coreal-cta-box">
                <div className="row align-items-end">
                  <div className="col-lg-6">
                    <div
                      className="coreal-cta-info wow fadeInLeft"
                      data-wow-duration="2s"
                      data-wow-delay="0s"
                    >
                      <div className="icon">
                        <i className="fal fa-headphones"></i>
                      </div>
                      <h4 className="title">Support Option</h4>
                      <ul>
                        <li>+44 (0) 1801 333 712</li>
                        <li>support@example.com</li>
                      </ul>
                      <div className="coreal-cta-support-icon">
                        <a href="#">
                          <i className="fal fa-comments"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div
                      className="coreal-cta-newsletter wow fadeInRight"
                      data-wow-duration="2s"
                      data-wow-delay=".6s"
                    >
                      <div className="icon">
                        <i className="fal fa-envelope-open"></i>
                      </div>
                      <span>
                        Sign up to our <span>newsletter</span>!
                      </span>
                      <form action="#">
                        <div className="input-box">
                          <input
                            type="text"
                            placeholder="Enter email address"
                          />
                          <button type="button">
                            <i className="fal fa-long-arrow-right"></i>
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default CtaSection;
