import React from "react";

function ListingPart({ data }) {
  return (
    <>
      <section className="coreal-listing-area pt-90 pb-95">
        <div className="container custom-container">
          <div className="row">
            <div className="col-lg-12">
              <div className="section-title">
                <span>View All 329 New Listings</span>
                <h3 className="title">New Listings in San Francisco, CA</h3>
              </div>
            </div>
          </div>
          <div className="row">
            {data.map((value) => (
              <div key={Math.random()} className="col-lg-3 col-md-6">
                <div
                  className="listing-details-featured-properties-item listing-item mt-30 wow fadeInUp"
                  data-wow-duration="2s"
                  data-wow-delay="0s"
                >
                  <div className="thumb">
                    <img
                      src={
                        require(`../../assets/images/${value.image}`).default
                      }
                      alt=""
                    />
                    <i className="fal fa-heart"></i>
                    <div className="properties-price">
                      <span>House For Sale</span>
                      <h3 className="title">${value.price}</h3>
                    </div>
                  </div>
                  <div className="content">
                    <p>
                      <a href="#">{value.title}</a>
                    </p>
                    <ul>
                      <li>
                        <i className="fal fa-bed"></i> {value.bed} Bed
                      </li>
                      <li>
                        <i className="fal fa-bath"></i> {value.bath} Bath
                      </li>
                      <li>
                        <i className="fal fa-expand"></i> {value.sqft} sqft
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
}

export default ListingPart;
