import mapboxgl from "mapbox-gl";
import React, { useEffect, useRef, useState } from "react";

mapboxgl.accessToken =
  "pk.eyJ1IjoicmF5aGFuMDQyMSIsImEiOiJja251NnZkcDUwYTJjMnhtbnAxYW8yOXlrIn0.8gtnbL6vZeMb7wm1x6CmaQ";

function Map() {
  const geojson = {
    type: "FeatureCollection",
    features: [
      {
        type: "Feature",
        geometry: {
          type: "Point",
          coordinates: [-77.032, 38.913],
        },
        properties: {
          title: "Mapbox",
          description: "Washington, D.C.",
        },
      },
      {
        type: "Feature",
        geometry: {
          type: "Point",
          coordinates: [-122.414, 37.776],
        },
        properties: {
          title: "Mapbox",
          description:
            'San Francisco, California<img src="https://www.google.com/images/branding/googlelogo/2x/googlelogo_color_92x30dp.png" alt="Girl in a jacket"> <a href="http://godsilla.bandcamp.com" target="_blank" title="Opens in a new window">Gods’illa</a>.',
        },
      },
      {
        type: "Feature",
        properties: {
          title: "Lincoln Park",
          description: "A northside park that is home to the Lincoln Park Zoo",
        },
        geometry: {
          coordinates: [-87.637596, 41.940403],
          type: "Point",
        },
      },
      {
        type: "Feature",
        properties: {
          title: "Burnham Park",
          description: "A lakefront park on Chicago's south side",
        },
        geometry: {
          coordinates: [-87.603735, 41.829985],
          type: "Point",
        },
      },
      {
        type: "Feature",
        properties: {
          title: "Millennium Park",
          description:
            "A downtown park known for its art installations and unique architecture",
        },
        geometry: {
          coordinates: [-88.622554, 41.882534],
          type: "Point",
        },
      },
      {
        type: "Feature",
        properties: {
          title: "Grant Park",
          description:
            "A downtown park that is the site of many of Chicago's favorite festivals and events",
        },
        geometry: {
          coordinates: [-89.619185, 41.876367],
          type: "Point",
        },
      },
      {
        type: "Feature",
        properties: {
          title: "Humboldt Park",
          description: "A large park on Chicago's northwest side",
        },
        geometry: {
          coordinates: [-90.70199, 41.905423],
          type: "Point",
        },
      },
      {
        type: "Feature",
        properties: {
          title: "Douglas Park",
          description:
            "A large park near in Chicago's North Lawndale neighborhood",
        },
        geometry: {
          coordinates: [-91.699329, 41.860092],
          type: "Point",
        },
      },
      {
        type: "Feature",
        properties: {
          title: "Calumet Park",
          description:
            "A park on the Illinois-Indiana border featuring a historic fieldhouse",
        },
        geometry: {
          coordinates: [-87.230221, 41.715515],
          type: "Point",
        },
      },
    ],
  };
  const mapContainer = useRef(null);

  const [lng] = useState(-96);
  const [lat] = useState(37.8);
  const [zoom] = useState(3);

  const [hoveredDistrict, _setHoveredDistrict] = useState(null);
  const hoveredDistrictRef = useRef(hoveredDistrict);
  const setHoveredDistrict = (data) => {
    hoveredDistrictRef.current = data;
    _setHoveredDistrict(data);
  };

  useEffect(() => {
    const map = new mapboxgl.Map({
      container: mapContainer.current,
      style: "mapbox://styles/mapbox/light-v10",
      center: [lng, lat],
      zoom,
    });
    // if (map.current) return; // initialize map only once
    // map.current = new mapboxgl.Map({
    //   container: mapContainer.current,
    //   style: "mapbox://styles/mapbox/light-v10",
    //   center: [lng, lat],
    //   zoom,
    // });
    // Add zoom and rotation controls to the map.
    map.addControl(new mapboxgl.NavigationControl());
    map.once("load", () => {
      map.addSource("district-source", {
        type: "geojson",
        data: geojson,
      });

      map.addLayer({
        id: "district-layer",
        type: "fill",
        source: "district-source",
        layout: {},
        paint: {
          "fill-color": [
            "match",
            ["get", "CD116FP"],
            "01",
            "#5AA5D7",
            "02",
            "#02735E",
            "03",
            "#00E0EF",
            "04",
            "#84D0D9",
            "05",
            "#202359",
            "06",
            "#CE7529",
            "07",
            "#00AE6C",
            "08",
            "#0056A3",
            /* other */ "#ffffff",
          ],
          "fill-opacity": [
            "case",
            ["boolean", ["feature-state", "hover"], false],
            0.8,
            0.5,
          ],
        },
      });

      map.on("mousemove", "district-layer", (e) => {
        if (e.features.length > 0) {
          if (hoveredDistrictRef.current && hoveredDistrictRef.current > -1) {
            map.setFeatureState(
              { source: "district-source", id: hoveredDistrictRef.current },
              { hover: false }
            );
          }

          const _hoveredDistrict = e.features[0].id;

          map.setFeatureState(
            { source: "district-source", id: _hoveredDistrict },
            { hover: true }
          );

          setHoveredDistrict(_hoveredDistrict);
        }
      });

      // When the mouse leaves the state-fill layer, update the feature state of the
      // previously hovered feature.
      map.on("mouseleave", "district-layer", () => {
        if (hoveredDistrictRef.current) {
          map.setFeatureState(
            { source: "district-source", id: hoveredDistrictRef.current },
            { hover: false }
          );
        }
        setHoveredDistrict(null);
      });
    });
    geojson.features.forEach((marker) => {
      // create a HTML element for each feature
      const el = document.createElement("div");
      el.className = "marker";

      // make a marker for each feature and add it to the map
      new mapboxgl.Marker(el)
        .setLngLat(marker.geometry.coordinates)
        .setPopup(
          new mapboxgl.Popup({ offset: 25 }) // add popups
            .setHTML(
              `<h3>${marker.properties.title}</h3><p>${marker.properties.description}</p>`
            )
        )
        .addTo(map);
      // .togglePopup();
    });
  });
  return (
    <div className="coreal-map-3-area">
      <div ref={mapContainer} className="map-container" id="coreal-map" />
    </div>
  );
}

export default Map;
