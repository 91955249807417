import React from "react";

function FunFactSection() {
  return (
    <>
      <section className="coreal-fun-facts-area">
        <div className="container custom-container">
          <div className="row">
            <div className="col-lg-6">
              <div className="coreal-fun-facts-content">
                <span>Fun Facts</span>
                <h3 className="title">
                  Have done some cool stuff with common users
                </h3>
              </div>
              <div className="coreal-fun-facts-subscribe">
                <div className="row">
                  <div className="col-lg-6 col-md-6">
                    <div className="input-box">
                      <input type="text" placeholder="Enter full name" />
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6">
                    <div className="input-box">
                      <input type="text" placeholder="Enter email address" />
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="input-box text-end">
                      <button type="button">
                        <i className="fal fa-envelope"></i> Subscribe Now
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="coreal-fun-facts-text">
                <p>
                  Real estate websites are attracting so many eyeballs every
                  month because they are adding value for both buyers and
                  sellers.
                </p>
                <div className="row">
                  <div className="col-md-4 col-sm-6">
                    <div className="coreal-fun-facts-count">
                      <h3 className="title">7500</h3>
                      <span>Project Done</span>
                    </div>
                  </div>
                  <div className="col-md-4 col-sm-6">
                    <div className="coreal-fun-facts-count">
                      <h3 className="title">23</h3>
                      <span>Co-Agents</span>
                    </div>
                  </div>
                  <div className="col-md-4 col-sm-6">
                    <div className="coreal-fun-facts-count">
                      <h3 className="title">902</h3>
                      <span>Cup Of Coffee</span>
                    </div>
                  </div>
                  <div className="col-md-4 col-sm-6">
                    <div className="coreal-fun-facts-count">
                      <h3 className="title">120</h3>
                      <span>Branch Worldwide</span>
                    </div>
                  </div>
                  <div className="col-md-4 col-sm-6">
                    <div className="coreal-fun-facts-count">
                      <h3 className="title">292</h3>
                      <span>Built House</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default FunFactSection;
