const data = {
  propertiesCollapsed: [
    {
      id: Math.random(),
      title: "First Floor",
      image: "floor-1.png",
      description:
        "The focal point of this development will be the magnificent Maine Tower, set to offer 297 exquisite apartments, arranged over 42-storeys and boasting  breathtaking panoramic views from the upper floors.This all-private tower is set to be a dramatic new landmark",
    },
    {
      id: Math.random(),
      title: "Second Floor",
      image: "floor-1.png",
      description:
        "The focal point of this development will be the magnificent Maine Tower, set to offer 297 exquisite apartments, arranged over 42-storeys and boasting  breathtaking panoramic views from the upper floors.This all-private tower is set to be a dramatic new landmark",
    },
    {
      id: Math.random(),
      title: "Garage Floor",
      image: "floor-1.png",
      description:
        "The focal point of this development will be the magnificent Maine Tower, set to offer 297 exquisite apartments, arranged over 42-storeys and boasting  breathtaking panoramic views from the upper floors.This all-private tower is set to be a dramatic new landmark",
    },
  ],
};
export default data;
