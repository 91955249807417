import React from "react";

function Testimonial() {
  return (
    <>
      <section className="coreal-testimonial-area">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="section-title text-center">
                <span>Testimonials</span>
                <h3 className="title">Client Feedbacks</h3>
              </div>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-lg-10">
              <div className="row">
                <div className="col-lg-6">
                  <div
                    className="coreal-testimonial-item wow fadeInUp"
                    data-wow-duration="2s"
                    data-wow-delay="0s"
                  >
                    <p>
                      “ Our portfolio includes some of the region most iconic
                      landmarks, from super high-rise luxury developments, to
                      five star hotels, ”
                    </p>
                    <div className="meta-author d-flex">
                      <div className="thumb">
                        <img
                          src={
                            require(`../../assets/images/user-1.jpg`).default
                          }
                          alt=""
                        />
                      </div>
                      <div className="text">
                        <h5 className="title">Huntur Dimitir</h5>
                        <span>Founder, Huntur Co.</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div
                    className="coreal-testimonial-item wow fadeInUp"
                    data-wow-duration="2s"
                    data-wow-delay=".5s"
                  >
                    <p>
                      “ Our portfolio includes some of the region most iconic
                      landmarks, from super high-rise luxury developments, to
                      five star hotels, ”
                    </p>
                    <div className="meta-author d-flex">
                      <div className="thumb">
                        <img
                          src={
                            require(`../../assets/images/user-2.jpg`).default
                          }
                          alt=""
                        />
                      </div>
                      <div className="text">
                        <h5 className="title">Olive Erickson</h5>
                        <span>New Mexico</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Testimonial;
