import mapboxgl from "mapbox-gl";
import React, { useEffect, useRef, useState } from "react";
import SliderCom from "../../Helper/SliderCom";
import SelectBox from "../../Share/SelectBox";

mapboxgl.accessToken =
  "pk.eyJ1IjoicmF5aGFuMDQyMSIsImEiOiJja251NnZkcDUwYTJjMnhtbnAxYW8yOXlrIn0.8gtnbL6vZeMb7wm1x6CmaQ";

function MainSection({ propertiesCollapsed = [] }) {
  // slectbox
  const sorting1 = ["sorting1", "sorting24", "sorting34", "sorting44"];
  const [sort1, setSort1] = useState("Sorting");
  const changeSort1 = (value) => {
    setSort1(value);
  };
  const sorting2 = ["sorting11", "sorting21", "sorting31", "sorting41"];
  const [sort2, setSort2] = useState("Sorting");
  const changeSort2 = (value) => {
    setSort2(value);
  };
  const sorting3 = ["sorting12", "sorting22", "sorting32", "sorting42"];
  const [sort3, setSort3] = useState("Sorting");
  const changeSort3 = (value) => {
    setSort3(value);
  };
  const sorting4 = ["sorting13", "sorting23", "sorting33", "sorting43"];
  const [sort4, setSort4] = useState("Sorting");
  const changeSort4 = (value) => {
    setSort4(value);
  };
  const geojson = {
    type: "FeatureCollection",
    features: [
      {
        type: "Feature",
        geometry: {
          type: "Point",
          coordinates: [-77.032, 38.913],
        },
        properties: {
          title: "Mapbox",
          description: "Washington, D.C.",
        },
      },
      {
        type: "Feature",
        geometry: {
          type: "Point",
          coordinates: [-122.414, 37.776],
        },
        properties: {
          title: "Mapbox",
          description:
            'San Francisco, California<img src="https://www.google.com/images/branding/googlelogo/2x/googlelogo_color_92x30dp.png" alt="Girl in a jacket"> <a href="http://godsilla.bandcamp.com" target="_blank" title="Opens in a new window">Gods’illa</a>.',
        },
      },
      {
        type: "Feature",
        properties: {
          title: "Lincoln Park",
          description: "A northside park that is home to the Lincoln Park Zoo",
        },
        geometry: {
          coordinates: [-87.637596, 41.940403],
          type: "Point",
        },
      },
      {
        type: "Feature",
        properties: {
          title: "Burnham Park",
          description: "A lakefront park on Chicago's south side",
        },
        geometry: {
          coordinates: [-87.603735, 41.829985],
          type: "Point",
        },
      },
      {
        type: "Feature",
        properties: {
          title: "Millennium Park",
          description:
            "A downtown park known for its art installations and unique architecture",
        },
        geometry: {
          coordinates: [-88.622554, 41.882534],
          type: "Point",
        },
      },
      {
        type: "Feature",
        properties: {
          title: "Grant Park",
          description:
            "A downtown park that is the site of many of Chicago's favorite festivals and events",
        },
        geometry: {
          coordinates: [-89.619185, 41.876367],
          type: "Point",
        },
      },
      {
        type: "Feature",
        properties: {
          title: "Humboldt Park",
          description: "A large park on Chicago's northwest side",
        },
        geometry: {
          coordinates: [-90.70199, 41.905423],
          type: "Point",
        },
      },
      {
        type: "Feature",
        properties: {
          title: "Douglas Park",
          description:
            "A large park near in Chicago's North Lawndale neighborhood",
        },
        geometry: {
          coordinates: [-91.699329, 41.860092],
          type: "Point",
        },
      },
      {
        type: "Feature",
        properties: {
          title: "Calumet Park",
          description:
            "A park on the Illinois-Indiana border featuring a historic fieldhouse",
        },
        geometry: {
          coordinates: [-87.230221, 41.715515],
          type: "Point",
        },
      },
    ],
  };

  const mapContainer = useRef(null);
  const [lng] = useState(-96);
  const [lat] = useState(37.8);
  const [zoom] = useState(3);

  const [hoveredDistrict, _setHoveredDistrict] = useState(null);
  const hoveredDistrictRef = useRef(hoveredDistrict);
  const setHoveredDistrict = (data) => {
    hoveredDistrictRef.current = data;
    _setHoveredDistrict(data);
  };

  useEffect(() => {
    const map = new mapboxgl.Map({
      container: mapContainer.current,
      style: "mapbox://styles/mapbox/light-v10",
      center: [lng, lat],
      zoom,
    });
    map.addControl(new mapboxgl.NavigationControl());
    map.once("load", () => {
      map.addSource("district-source", {
        type: "geojson",
        data: geojson,
      });

      map.addLayer({
        id: "district-layer",
        type: "fill",
        source: "district-source",
        layout: {},
        paint: {
          "fill-color": [
            "match",
            ["get", "CD116FP"],
            "01",
            "#5AA5D7",
            "02",
            "#02735E",
            "03",
            "#00E0EF",
            "04",
            "#84D0D9",
            "05",
            "#202359",
            "06",
            "#CE7529",
            "07",
            "#00AE6C",
            "08",
            "#0056A3",
            /* other */ "#ffffff",
          ],
          "fill-opacity": [
            "case",
            ["boolean", ["feature-state", "hover"], false],
            0.8,
            0.5,
          ],
        },
      });

      map.on("mousemove", "district-layer", (e) => {
        if (e.features.length > 0) {
          if (hoveredDistrictRef.current && hoveredDistrictRef.current > -1) {
            map.setFeatureState(
              {
                source: "district-source",
                id: hoveredDistrictRef.current,
              },
              { hover: false }
            );
          }

          const _hoveredDistrict = e.features[0].id;

          map.setFeatureState(
            { source: "district-source", id: _hoveredDistrict },
            { hover: true }
          );

          setHoveredDistrict(_hoveredDistrict);
        }
      });

      // When the mouse leaves the state-fill layer, update the feature state of the
      // previously hovered feature.
      map.on("mouseleave", "district-layer", () => {
        if (hoveredDistrictRef.current) {
          map.setFeatureState(
            { source: "district-source", id: hoveredDistrictRef.current },
            { hover: false }
          );
        }
        setHoveredDistrict(null);
      });
    });
    geojson.features.forEach((marker) => {
      // create a HTML element for each feature
      const el = document.createElement("div");
      el.className = "marker";

      // make a marker for each feature and add it to the map
      new mapboxgl.Marker(el)
        .setLngLat(marker.geometry.coordinates)
        .setPopup(
          new mapboxgl.Popup({ offset: 25 }) // add popups
            .setHTML(
              `<h3>${marker.properties.title}</h3><p>${marker.properties.description}</p>`
            )
        )
        .addTo(map);
      // .togglePopup();
    });
  });

  const [collapsed, setCollapsed] = useState();
  const handleClick = (e, id) => {
    e.preventDefault();
    setCollapsed(id);
  };
  const settings = {
    dots: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: false,
    speed: 1500,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerMode: true,
    centerPadding: "400px",
    responsive: [
      {
        breakpoint: 1201,
        settings: {
          centerPadding: "200px",
        },
      },
      {
        breakpoint: 992,
        settings: {
          centerPadding: "100px",
        },
      },
      {
        breakpoint: 768,
        settings: {
          centerPadding: "0px",
        },
      },
      {
        breakpoint: 576,
        settings: {
          centerPadding: "0px",
        },
      },
    ],
  };
  return (
    <>
      <section className="coreal-listing-details-topbar-area">
        <div className="container custom-container">
          <div className="row">
            <div className="col-lg-12">
              <div className="coreal-listing-details-topbar d-block d-lg-flex justify-content-between align-items-center">
                <div className="coreal-listing-details-heading">
                  <h2 className="title">Villa on Hollywood Boulevard</h2>
                  <p>
                    <i className="fal fa-map-marker-alt"></i> Merrick Way,
                    Miami, FL 33134, USA
                  </p>
                </div>
                <div className="coreal-listing-details-price-bar text-end">
                  <span>
                    $ 350,00 <span>/ Month</span>
                  </span>
                  <br />
                  <span className="item-2">
                    $ 520,00 <span>/ Sq Ft</span>
                  </span>
                  <p>FOR RENT</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="coreal-listing-details-slider mt-60">
          <div className="container-fluid p-0">
            <SliderCom
              settings={settings}
              className="row listing-details-slider-active  g-0"
            >
              <div className="col-lg-4">
                <div className="coreal-listing-details-slider-thumb">
                  <img
                    src={
                      require(`../../../assets/images/list-details-thumb-1.jpg`)
                        .default
                    }
                    alt=""
                  />
                  <div className="open-time">
                    <a href="#">Open House</a>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="coreal-listing-details-slider-thumb">
                  <img
                    src={
                      require(`../../../assets/images/list-details-thumb-2.jpg`)
                        .default
                    }
                    alt=""
                  />
                  <div className="open-time">
                    <a href="#">Open House</a>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="coreal-listing-details-slider-thumb">
                  <img
                    src={
                      require(`../../../assets/images/list-details-thumb-3.jpg`)
                        .default
                    }
                    alt=""
                  />
                  <div className="open-time">
                    <a href="#">Open House</a>
                  </div>
                </div>
              </div>
            </SliderCom>
          </div>
        </div>
        <div className="container custom-container mt-30">
          <div className="row">
            <div className="col-lg-2 col-md-4 col-sm-6">
              <div className="coreal-listing-details-box text-center mt-30">
                <img src="assets/images/icon/1.svg" alt="" />
                <h5 className="title">Area</h5>
                <span>4300 Sq Ft</span>
              </div>
            </div>
            <div className="col-lg-2 col-md-4 col-sm-6">
              <div className="coreal-listing-details-box text-center mt-30">
                <img src="assets/images/icon/2.svg" alt="" />
                <h5 className="title">Lot Size</h5>
                <span>4300 Sq Ft</span>
              </div>
            </div>
            <div className="col-lg-2 col-md-4 col-sm-6">
              <div className="coreal-listing-details-box text-center mt-30">
                <img src="assets/images/icon/3.svg" alt="" />
                <h5 className="title">Bedroom</h5>
                <span>4300 Sq Ft</span>
              </div>
            </div>
            <div className="col-lg-2 col-md-4 col-sm-6">
              <div className="coreal-listing-details-box text-center mt-30">
                <img src="assets/images/icon/4.svg" alt="" />
                <h5 className="title">Built Year</h5>
                <span>4300 Sq Ft</span>
              </div>
            </div>
            <div className="col-lg-2 col-md-4 col-sm-6">
              <div className="coreal-listing-details-box text-center mt-30">
                <img src="assets/images/icon/5.svg" alt="" />
                <h5 className="title">Built Year</h5>
                <span>4300 Sq Ft</span>
              </div>
            </div>
            <div className="col-lg-2 col-md-4 col-sm-6">
              <div className="coreal-listing-details-box text-center mt-30">
                <img src="assets/images/icon/6.svg" alt="" />
                <h5 className="title">Garage</h5>
                <span>4300 Sq Ft</span>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="coreal-listing-details-main-area">
        <div className="container custom-container">
          <div className="coreal-listing-details-main-box"></div>
          <div className="row">
            <div className="col-lg-8">
              <div className="coreal-listing-details-content">
                <div className="coreal-listing-details-title">
                  <h3 className="title">Property Description</h3>
                </div>
                <p>
                  The spectacular Harbour Central is a brand new development in
                  London’s Docklands, comprising a total of 642 private
                  apartments divided across five residential buildings, along
                  with a leisure complex. Once complete, the development will
                  provide a vast range of highly-specified studio, one, two and
                  three bedroom apartments along with eight exceptional
                  penthouse apartments. This will be the height of luxury living
                  and exclusivity just minutes from Canary Wharf – the booming
                  financial centre of London.
                </p>
                <p>
                  The spectacular Harbour Central is a brand new development in
                  London’s Docklands, comprising a total of 642 private
                  apartments divided across five residential buildings, along
                  with a leisure complex. Once complete, the development will
                  provide a vast range of highly-specified studio, one, two and
                  three bedroom apartments along with eight exceptional
                  penthouse apartments. This will be the height of luxury living
                  and exclusivity just minutes from Canary Wharf – the booming
                  financial centre of London.
                </p>
              </div>
              <div className="coreal-listing-details-item mt-30">
                <div className="coreal-listing-details-title">
                  <h3 className="title">Additional Details</h3>
                </div>
                <div className="row">
                  <div className="col-md-4">
                    <ul>
                      <li>
                        <span></span>Building Age: <span>2 Years</span>
                      </li>
                      <li>
                        <span></span>Parking: <span>Attached Garage</span>
                      </li>
                      <li>
                        <span></span>Cooling: <span>Central Cooling</span>
                      </li>
                    </ul>
                  </div>
                  <div className="col-md-4">
                    <ul>
                      <li>
                        <span></span>Heating: <span>Forced Air, Gas</span>
                      </li>
                      <li>
                        <span></span>Sewer: <span>Public/City</span>
                      </li>
                      <li>
                        <span></span>Water: <span>City</span>
                      </li>
                    </ul>
                  </div>
                  <div className="col-md-4">
                    <ul>
                      <li>
                        <span></span>Exercise Room: <span>Yes</span>
                      </li>
                      <li>
                        <span></span>Storage Room: <span>Yes</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="coreal-listing-details-features mt-40">
                <div className="coreal-listing-details-title">
                  <h3 className="title">Additional Details</h3>
                </div>
                <ul className="coreal-listing-details-checkbox">
                  <li>
                    <input type="checkbox" name="checkbox5" id="checkbox1" />
                    <label htmlFor="checkbox1">
                      <span></span>Air Conditioning
                    </label>
                  </li>

                  <li>
                    <input type="checkbox" name="checkbox5" id="checkbox2" />
                    <label htmlFor="checkbox2">
                      <span></span>Backyard
                    </label>
                  </li>

                  <li>
                    <input type="checkbox" name="checkbox5" id="checkbox3" />
                    <label htmlFor="checkbox3">
                      <span></span>Balcony
                    </label>
                  </li>

                  <li>
                    <input type="checkbox" name="checkbox5" id="checkbox4" />
                    <label htmlFor="checkbox4">
                      <span></span>Dryer
                    </label>
                  </li>

                  <li>
                    <input type="checkbox" name="checkbox5" id="checkbox5" />
                    <label htmlFor="checkbox5">
                      <span></span>Electric Range
                    </label>
                  </li>

                  <li>
                    <input type="checkbox" name="checkbox5" id="checkbox6" />
                    <label htmlFor="checkbox6">
                      <span></span>Fully Furnished
                    </label>
                  </li>

                  <li>
                    <input type="checkbox" name="checkbox5" id="checkbox7" />
                    <label htmlFor="checkbox7">
                      <span></span>Gym
                    </label>
                  </li>

                  <li>
                    <input type="checkbox" name="checkbox5" id="checkbox8" />
                    <label htmlFor="checkbox8">
                      <span></span>Home Theater
                    </label>
                  </li>

                  <li>
                    <input type="checkbox" name="checkbox5" id="checkbox9" />
                    <label htmlFor="checkbox9">
                      <span></span>Laundry Room
                    </label>
                  </li>

                  <li>
                    <input type="checkbox" name="checkbox5" id="checkbox10" />
                    <label htmlFor="checkbox10">
                      <span></span>Ocean View
                    </label>
                  </li>

                  <li>
                    <input type="checkbox" name="checkbox5" id="checkbox11" />
                    <label htmlFor="checkbox11">
                      <span></span>Pool
                    </label>
                  </li>

                  <li>
                    <input type="checkbox" name="checkbox5" id="checkbox12" />
                    <label htmlFor="checkbox12">
                      <span></span>Recreation
                    </label>
                  </li>
                </ul>
              </div>
              <div className="coreal-listing-details-attatchment mt-40">
                <div className="coreal-listing-details-title">
                  <h3 className="title">Attatchment</h3>
                </div>
                <div className="coreal-listing-details-attatchment-item">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="coreal-listing-details-attatchment-doc">
                        <div className="icon">
                          <img
                            src={
                              require("../../../assets/images/doc-1.png")
                                .default
                            }
                            alt=""
                          />
                        </div>
                        <a href="#">Demo PDF Document</a>
                        <i className="fal fa-long-arrow-right"></i>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="coreal-listing-details-attatchment-doc">
                        <div className="icon">
                          <img
                            src={
                              require("../../../assets/images/doc-2.png")
                                .default
                            }
                            alt=""
                          />
                        </div>
                        <a href="#">Demo Word Document</a>
                        <i className="fal fa-long-arrow-right"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="coreal-listing-details-tab mt-50">
                <div className="coreal-listing-details-title">
                  <h3 className="title">Floor Plans</h3>
                </div>
                <div className="coreal-listing-details-tab-box">
                  <div className="accordion" id="accordionExample">
                    {propertiesCollapsed &&
                      propertiesCollapsed.map((item) => (
                        <div key={`${item.id}pro`} className="accordion-item">
                          <div className="accordion-header" id="headingOne">
                            <button
                              className={`accordion-button ${
                                collapsed !== item.id ? "collapsed" : ""
                              }`}
                              onClick={(e) => handleClick(e, item.id)}
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#collapseOne"
                              aria-expanded="true"
                              aria-controls="collapseOne"
                            >
                              <span>{item.title}</span>
                            </button>
                          </div>
                          <div
                            id="collapseOne"
                            className={`accordion-collapse collapse ${
                              collapsed === item.id ? "show" : ""
                            }`}
                            aria-labelledby="headingOne"
                            data-bs-parent="#accordionExample"
                          >
                            <div className="accordion-body">
                              <div className="thumb text-center mb-60">
                                <img
                                  src={
                                    require(`../../../assets/images/${item.image}`)
                                      .default
                                  }
                                  alt=""
                                />
                              </div>
                              <p>{item.description}</p>
                            </div>
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
              <div className="coreal-listing-details-play mt-50">
                <div className="coreal-listing-details-title">
                  <h3 className="title">Property Video</h3>
                </div>
                <div className="coreal-listing-details-play-box">
                  <img
                    src={require(`../../../assets/images/play-bg.jpg`).default}
                    alt=""
                  />
                  <div className="play-btn">
                    <a
                      className="video-popup"
                      href="https://www.youtube.com/watch?v=Op3S9hB_Dok&t=1s"
                    >
                      <i className="fas fa-play"></i>
                    </a>
                  </div>
                </div>
              </div>
              <div className="coreal-listing-details-map mt-50">
                <div className="coreal-listing-details-title">
                  <h3 className="title">Property Map</h3>
                </div>
                <div className="coreal-listing-details-map-box">
                  <div ref={mapContainer} id="coreal-map"></div>
                </div>
              </div>
              <div className="coreal-listing-details-nearby mt-50">
                <div className="coreal-listing-details-title">
                  <h3 className="title">What’s Nearby</h3>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="coreal-listing-details-nearby-item">
                      <div className="icon">
                        <i className="fal fa-graduation-cap"></i>
                      </div>
                      <h4 className="title">Education</h4>
                      <div className="item">
                        <ul>
                          <li>
                            <span>Vino di Savino (0.09 mi)</span>
                          </li>
                          <li>
                            <span>Go Get Your Smock (0.09 mi)</span>
                          </li>
                          <li>
                            <span>Lincoln School (0.17 mi)</span>
                          </li>
                        </ul>
                        <span></span>
                        <ul>
                          <li>
                            <i className="fas fa-star"></i>
                            <i className="fas fa-star"></i>
                            <i className="fas fa-star"></i>
                            <i className="fas fa-star"></i>
                            <i className="fas fa-star"></i>
                          </li>
                          <li>
                            <i className="fas fa-star"></i>
                            <i className="fas fa-star"></i>
                            <i className="fas fa-star"></i>
                            <i className="fas fa-star"></i>
                          </li>
                          <li>
                            <i className="fas fa-star"></i>
                            <i className="fas fa-star"></i>
                            <i className="fas fa-star"></i>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="coreal-listing-details-nearby-item">
                      <div className="icon">
                        <i className="fal fa-shopping-basket"></i>
                      </div>
                      <h4 className="title">Shop</h4>
                      <div className="item">
                        <ul>
                          <li>
                            <span>Vino di Savino (0.09 mi)</span>
                          </li>
                          <li>
                            <span>Go Get Your Smock (0.09 mi)</span>
                          </li>
                          <li>
                            <span>Lincoln School (0.17 mi)</span>
                          </li>
                        </ul>
                        <span></span>
                        <ul>
                          <li>
                            <i className="fas fa-star"></i>
                            <i className="fas fa-star"></i>
                            <i className="fas fa-star"></i>
                            <i className="fas fa-star"></i>
                            <i className="fas fa-star"></i>
                          </li>
                          <li>
                            <i className="fas fa-star"></i>
                            <i className="fas fa-star"></i>
                            <i className="fas fa-star"></i>
                            <i className="fas fa-star"></i>
                          </li>
                          <li>
                            <i className="fas fa-star"></i>
                            <i className="fas fa-star"></i>
                            <i className="fas fa-star"></i>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="coreal-listing-details-nearby-item">
                      <div className="icon">
                        <i className="fal fa-hospital"></i>
                      </div>
                      <h4 className="title">Medical</h4>
                      <div className="item">
                        <ul>
                          <li>
                            <span>Vino di Savino (0.09 mi)</span>
                          </li>
                          <li>
                            <span>Go Get Your Smock (0.09 mi)</span>
                          </li>
                          <li>
                            <span>Lincoln School (0.17 mi)</span>
                          </li>
                        </ul>
                        <span></span>
                        <ul>
                          <li>
                            <i className="fas fa-star"></i>
                            <i className="fas fa-star"></i>
                            <i className="fas fa-star"></i>
                            <i className="fas fa-star"></i>
                            <i className="fas fa-star"></i>
                          </li>
                          <li>
                            <i className="fas fa-star"></i>
                            <i className="fas fa-star"></i>
                            <i className="fas fa-star"></i>
                            <i className="fas fa-star"></i>
                          </li>
                          <li>
                            <i className="fas fa-star"></i>
                            <i className="fas fa-star"></i>
                            <i className="fas fa-star"></i>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="coreal-listing-details-nearby-item">
                      <div className="icon">
                        <i className="fal fa-desktop"></i>
                      </div>
                      <h4 className="title">Electronic Shop</h4>
                      <div className="item">
                        <ul>
                          <li>
                            <span>Vino di Savino (0.09 mi)</span>
                          </li>
                          <li>
                            <span>Go Get Your Smock (0.09 mi)</span>
                          </li>
                          <li>
                            <span>Lincoln School (0.17 mi)</span>
                          </li>
                        </ul>
                        <span></span>
                        <ul>
                          <li>
                            <i className="fas fa-star"></i>
                            <i className="fas fa-star"></i>
                            <i className="fas fa-star"></i>
                            <i className="fas fa-star"></i>
                            <i className="fas fa-star"></i>
                          </li>
                          <li>
                            <i className="fas fa-star"></i>
                            <i className="fas fa-star"></i>
                            <i className="fas fa-star"></i>
                            <i className="fas fa-star"></i>
                          </li>
                          <li>
                            <i className="fas fa-star"></i>
                            <i className="fas fa-star"></i>
                            <i className="fas fa-star"></i>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="coreal-listing-details-properties mt-20">
                <div className="coreal-listing-details-title">
                  <h3 className="title">Similar Properties</h3>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="coreal-listing-details-properties-item mt-10">
                      <div className="thumb">
                        <img
                          src={
                            require(`../../../assets/images/properties-1.jpg`)
                              .default
                          }
                          alt=""
                        />
                        <i className="fal fa-heart"></i>
                        <a href="#">
                          <i className="fal fa-users"></i> <span>Family</span>
                        </a>
                      </div>
                      <div className="content">
                        <h3 className="title">$78,987,00</h3>
                        <p>
                          <a href="#">North west 20th Ave San Francisco, CA</a>
                        </p>
                        <ul>
                          <li>
                            <i className="fal fa-bed"></i> 5 Bed
                          </li>
                          <li>
                            <i className="fal fa-bath"></i> 2 Bath
                          </li>
                          <li>
                            <i className="fal fa-expand"></i> 4100 sqft
                          </li>
                        </ul>
                        <div className="author">
                          <img
                            src={
                              require(`../../../assets/images/user-1.jpg`)
                                .default
                            }
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="coreal-listing-details-properties-item mt-10">
                      <div className="thumb">
                        <img
                          src={
                            require(`../../../assets/images/properties-2.jpg`)
                              .default
                          }
                          alt=""
                        />
                        <i className="fal fa-heart"></i>
                        <a href="#">
                          <i className="fal fa-users"></i> <span>Family</span>
                        </a>
                      </div>
                      <div className="content">
                        <h3 className="title">$78,987,00</h3>
                        <p>
                          <a href="#">North west 20th Ave San Francisco, CA</a>
                        </p>
                        <ul>
                          <li>
                            <i className="fal fa-bed"></i> 5 Bed
                          </li>
                          <li>
                            <i className="fal fa-bath"></i> 2 Bath
                          </li>
                          <li>
                            <i className="fal fa-expand"></i> 4100 sqft
                          </li>
                        </ul>
                        <div className="author">
                          <img
                            src={
                              require(`../../../assets/images/user-2.jpg`)
                                .default
                            }
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="coreal-listing-details-comments mt-50 mb-60">
                <div className="coreal-listing-details-title">
                  <h3 className="title">02 Reviews</h3>
                </div>
                <div className="coreal-listing-details-comments-box">
                  <div className="details-comments-item">
                    <div className="thumb">
                      <img
                        src={
                          require(`../../../assets/images/user-3.png`).default
                        }
                        alt=""
                      />
                    </div>
                    <div className="content">
                      <h4 className="title">Rosalina Kelian</h4>
                      <span>19th May 2021</span>
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipisicing
                        elit, sed do eiusmod tempor incididunt ut labore et
                        dolore magna aliqua. Ut enim ad minim veniam, quis
                        nostrud exercitation ullamco laboris nisi ut aliquip ex
                        ea commodo consequat.
                      </p>
                      <a href="#">
                        <i className="fal fa-share"></i> Reply
                      </a>
                    </div>
                  </div>
                  <div className="details-comments-item ml-130">
                    <div className="thumb">
                      <img
                        src={
                          require(`../../../assets/images/user-4.png`).default
                        }
                        alt=""
                      />
                    </div>
                    <div className="content">
                      <h4 className="title">Rosalina Kelian</h4>
                      <span>19th May 2021</span>
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipisicing
                        elit, sed do eiusmod tempor incididunt ut labore et
                        dolore magna aliqua. Ut enim ad minim veniam, quis
                        nostrud exercitation ullamco laboris nisi ut aliquip ex
                        ea commodo consequat.
                      </p>
                      <a href="#">
                        <i className="fal fa-share"></i> Reply
                      </a>
                    </div>
                  </div>
                  <div className="details-comments-item">
                    <div className="thumb">
                      <img
                        src={
                          require("../../../assets/images/user-5.png").default
                        }
                        alt=""
                      />
                    </div>
                    <div className="content">
                      <h4 className="title">Rosalina Kelian</h4>
                      <span>19th May 2021</span>
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipisicing
                        elit, sed do eiusmod tempor incididunt ut labore et
                        dolore magna aliqua. Ut enim ad minim veniam, quis
                        nostrud exercitation ullamco laboris nisi ut aliquip ex
                        ea commodo consequat.
                      </p>
                      <a href="#">
                        <i className="fal fa-share"></i> Reply
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="coreal-listing-details-form-box">
                <div className="top-bar">
                  <div className="coreal-rating">
                    <fieldset className="rating">
                      <input type="radio" id="star5" name="rating" value="5" />
                      <label
                        className="full"
                        htmlFor="star5"
                        title="Awesome - 5 stars"
                      ></label>
                      <input
                        type="radio"
                        id="star4half"
                        name="rating"
                        value="4 and a half"
                      />
                      <label
                        className="half"
                        htmlFor="star4half"
                        title="Pretty good - 4.5 stars"
                      ></label>
                      <input type="radio" id="star4" name="rating" value="4" />
                      <label
                        className="full"
                        htmlFor="star4"
                        title="Pretty good - 4 stars"
                      ></label>
                      <input
                        type="radio"
                        id="star3half"
                        name="rating"
                        value="3 and a half"
                      />
                      <label
                        className="half"
                        htmlFor="star3half"
                        title="Meh - 3.5 stars"
                      ></label>
                      <input type="radio" id="star3" name="rating" value="3" />
                      <label
                        className="full"
                        htmlFor="star3"
                        title="Meh - 3 stars"
                      ></label>
                      <input
                        type="radio"
                        id="star2half"
                        name="rating"
                        value="2 and a half"
                      />
                      <label
                        className="half"
                        htmlFor="star2half"
                        title="Kinda bad - 2.5 stars"
                      ></label>
                      <input type="radio" id="star2" name="rating" value="2" />
                      <label
                        className="full"
                        htmlFor="star2"
                        title="Kinda bad - 2 stars"
                      ></label>
                      <input
                        type="radio"
                        id="star1half"
                        name="rating"
                        value="1 and a half"
                      />
                      <label
                        className="half"
                        htmlFor="star1half"
                        title="Meh - 1.5 stars"
                      ></label>
                      <input type="radio" id="star1" name="rating" value="1" />
                      <label
                        className="full"
                        htmlFor="star1"
                        title="Sucks big time - 1 star"
                      ></label>
                      <input
                        type="radio"
                        id="starhalf"
                        name="rating"
                        value="half"
                      />
                      <label
                        className="half"
                        htmlFor="starhalf"
                        title="Sucks big time - 0.5 stars"
                      ></label>
                    </fieldset>
                  </div>
                  <p>
                    Your email address will not be published.Required fields are
                    marked
                  </p>
                </div>
                <form action="#">
                  <div className="input-box">
                    <textarea
                      name="#"
                      placeholder="Type your comments...."
                      id="#"
                      cols="100"
                      rows="10"
                    ></textarea>
                    <i className="fal fa-pencil-alt"></i>
                  </div>
                  <div className="input-box">
                    <input type="text" placeholder="Type your name...." />
                    <i className="fal fa-user"></i>
                  </div>
                  <div className="input-box">
                    <input type="email" placeholder="Type your email...." />
                    <i className="fal fa-envelope"></i>
                  </div>
                  <div className="input-box">
                    <input type="text" placeholder="Type your website...." />
                    <i className="fal fa-globe"></i>
                  </div>
                </form>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="listing-details-sidebar">
                <div className="listing-details-agent sidebar-widget mb-45">
                  <div className="sidebar-widget-title">
                    <h3 className="title">Property Agent</h3>
                  </div>
                  <div className="agent-details">
                    <div className="thumb">
                      <img
                        src={
                          require(`../../../assets/images/user-6.jpg`).default
                        }
                        alt=""
                      />
                    </div>
                    <div className="content">
                      <h4 className="title">Miranda H. Halim</h4>
                      <span>Founder</span>
                    </div>
                  </div>
                  <div className="text mt-20">
                    <p>
                      The spectacular Harbour Central a brand new development in
                      London’s Docklands, comprising a total of 642 private.
                    </p>
                  </div>
                  <div className="agent-contact-details">
                    <ul>
                      <li>
                        <i className="fal fa-phone"></i>{" "}
                        <a href="#">+098 908 967 87</a>
                      </li>
                      <li>
                        <i className="fal fa-envelope"></i>{" "}
                        <a href="#">info@webexample.com</a>
                      </li>
                    </ul>
                  </div>
                  <div className="agent-contact-info">
                    <a href="#">Connect With Me</a>
                    <a className="chat-box" href="#">
                      <i className="fal fa-comments"></i>
                    </a>
                  </div>
                </div>
                <div className="listing-details-find sidebar-widget">
                  <div className="sidebar-widget-title">
                    <h3 className="title">Find your home</h3>
                  </div>
                  <div className="listing-details-find-box mt-30">
                    <form action="#">
                      <div className="input-box">
                        <input type="text" placeholder="Enter keyword" />
                      </div>
                      <div className="">
                        <div className="coreal-select-item">
                          <SelectBox
                            className="mb-20"
                            datas={sorting1}
                            selected={sort1}
                            action={changeSort1}
                          />
                        </div>
                      </div>
                      <div className="">
                        <div className="coreal-select-item">
                          <SelectBox
                            className="mb-20"
                            datas={sorting2}
                            selected={sort2}
                            action={changeSort2}
                          />
                        </div>
                      </div>
                      <div className="">
                        <div className="coreal-select-item">
                          <SelectBox
                            className="mb-20"
                            datas={sorting3}
                            selected={sort3}
                            action={changeSort3}
                          />
                        </div>
                      </div>
                      <div className="">
                        <div className="coreal-select-item">
                          <SelectBox
                            className="mb-20"
                            datas={sorting4}
                            selected={sort4}
                            action={changeSort4}
                          />
                        </div>
                      </div>
                      <div className="input-box">
                        <button type="button">
                          <i className="far fa-search"></i> Search Now
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
                <div className="listing-details-featured-properties sidebar-widget mt-45">
                  <div className="sidebar-widget-title">
                    <h3 className="title">Featured Properties</h3>
                  </div>
                  <div className="listing-details-featured-properties-item mt-30">
                    <div className="thumb">
                      <img
                        src={
                          require(`../../../assets/images/featured-properties-thumb.png`)
                            .default
                        }
                        alt=""
                      />
                      <i className="fal fa-heart"></i>
                      <div className="properties-price">
                        <span>House For Sale</span>
                        <h3 className="title">$98,0000</h3>
                      </div>
                    </div>
                    <div className="content">
                      <p>
                        <a href="#">2166 20th Ave San Francisco, CA</a>
                      </p>
                      <ul>
                        <li>
                          <i className="fal fa-bed"></i> 5 Bed
                        </li>
                        <li>
                          <i className="fal fa-bath"></i> 2 Bath
                        </li>
                        <li>
                          <i className="fal fa-expand"></i> 4100 sqft
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="coreal-listing-details-add mt-45">
                  <a href="#">
                    <img
                      src={require(`../../../assets/images/ad.png`).default}
                      alt=""
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default MainSection;
