import React from "react";

function Hero() {
  return (
    <>
      <div className="coreal-page-banner-area border">
        <div className="container custom-container">
          <div className="row">
            <div className="col-lg-12">
              <div className="coreal-page-banner-content">
                <div className="heading">
                  <h3 className="title">Get Every Answers</h3>
                </div>
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <a href="#">Home</a>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      FAQ
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Hero;
