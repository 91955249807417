import React from "react";

function GetInTouch() {
  return (
    <>
      <div className="coreal-get-in-touch-area">
        <div className="container custom-container">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <div className="section-title">
                <span>Get in touch</span>
                <h3 className="title">
                  For more information about our services, get in touch with our
                  expert consultants.
                </h3>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="row">
                <div className="col-lg-6 col-md-6">
                  <div className="coreal-get-in-touch-item text-center">
                    <img
                      src={require(`../../assets/images/icon/1.png`).default}
                      alt=""
                    />
                    <span>Call for support</span>
                    <h4 className="title">1900 68668</h4>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6">
                  <div className="coreal-get-in-touch-item text-center">
                    <img
                      src={require(`../../assets/images/icon/2.png`).default}
                      alt=""
                    />
                    <span>Sent email us</span>
                    <h4 className="title">in@example.com</h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default GetInTouch;
