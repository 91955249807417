import React from "react";

function Hero() {
  return (
    <>
      <section className="coreal-hero-2-area">
        <div className="container custom-container">
          <div className="row">
            <div className="col-lg-7">
              <div className="coreal-hero-content-2">
                <span
                  className="wow fadeInLeft"
                  data-wow-duration="2s"
                  data-wow-delay="0s"
                >
                  Get your perfect home for save your family
                </span>
                <h1
                  className="title wow fadeInLeft"
                  data-wow-duration="2s"
                  data-wow-delay=".3s"
                >
                  Let’s find a <span>Perfect</span> Home for you.
                </h1>
                <ul
                  className="wow fadeInUp"
                  data-wow-duration="2s"
                  data-wow-delay=".6s"
                >
                  <li>
                    <a className="main-btn" href="#">
                      Buy Property
                    </a>
                  </li>
                  <li>
                    <a className="main-btn-2 main-btn" href="#">
                      Sale Property
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-5">
              <div className="coreal-hero-thumb-2">
                <img
                  src={require("../../assets/images/hero-thumb-2.png").default}
                  alt=""
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <div className="hero-fun-facts">
                <div className="hero-title">
                  <h4 className="title">Fun Facts</h4>
                </div>
                <div className="hero-fun-facts-box d-flex justify-content-between">
                  <div className="hero-fun-facts-item">
                    <h3 className="title">7500</h3>
                    <span> Project Done </span>
                  </div>
                  <div className="hero-fun-facts-item">
                    <h3 className="title">23</h3>
                    <span> Co-Agents </span>
                  </div>
                  <div className="hero-fun-facts-item">
                    <h3 className="title">902</h3>
                    <span>Cup Of Coffee</span>
                  </div>
                  <div className="hero-fun-facts-item">
                    <h3 className="title">120</h3>
                    <span> Branch Worldwide </span>
                  </div>
                  <div className="hero-fun-facts-item">
                    <h3 className="title">974</h3>
                    <span> Built House </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Hero;
