import React from "react";

function CardStyleTwo({ datas }) {
  return (
    <div className="coreal-single-blog mt-30">
      <div className="thumb">
        <img
          src={require(`../../assets/images/${datas.cover}`).default}
          alt=""
        />
      </div>
      <div className="content">
        <div className="meta-items">
          <div className="meta-categores">
            <a href="#">{datas.category}</a>
          </div>
          <div className="meta-author">
            <span>
              By <a href="#">{datas.username}</a>
            </span>
          </div>
        </div>
        <div className="meta-date text-center">
          <h5 className="title">23</h5>
          <span>Jun</span>
        </div>
        <h4 className="title">
          <a href="#">{datas.title}</a>
        </h4>
      </div>
    </div>
  );
}

export default CardStyleTwo;
