import React, { useState } from "react";
import CardStyleOne from "../Card/CardStyleOne";

function ListingTab({ listingDatas }) {
  const [tab, setTab] = useState("all");
  const handler = (e, value) => {
    e.preventDefault();
    setTab(value);
  };
  return (
    <>
      <div className="row">
        <div className="col-lg-12">
          <div className="coreal-listing-tab-btn d-flex">
            <ul className="nav nav-pills" id="pills-tab" role="tablist">
              <li className="nav-item" role="presentation">
                <button
                  className={`nav-link ${tab === "all" ? "active" : ""}`}
                  id="pills-1-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-1"
                  type="button"
                  role="tab"
                  aria-controls="pills-1"
                  aria-selected="true"
                  onClick={(e) => handler(e, "all")}
                >
                  All Listings
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className={`nav-link ${tab === "newWork" ? "active" : ""}`}
                  id="pills-2-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-2"
                  type="button"
                  role="tab"
                  aria-controls="pills-2"
                  aria-selected="false"
                  onClick={(e) => handler(e, "newWork")}
                >
                  New York
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className={`nav-link ${tab === "london" ? "active" : ""}`}
                  id="pills-1-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-1"
                  type="button"
                  role="tab"
                  aria-controls="pills-1"
                  aria-selected="true"
                  onClick={(e) => handler(e, "london")}
                >
                  London
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className={`nav-link ${tab === "dhaka" ? "active" : ""}`}
                  id="pills-1-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-1"
                  type="button"
                  role="tab"
                  aria-controls="pills-1"
                  aria-selected="true"
                  onClick={(e) => handler(e, "dhaka")}
                >
                  Dhaka
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className={`nav-link ${tab === "sanghai" ? "active" : ""}`}
                  id="pills-1-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-1"
                  type="button"
                  role="tab"
                  aria-controls="pills-1"
                  aria-selected="true"
                  onClick={(e) => handler(e, "sanghai")}
                >
                  Sanghai
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className={`nav-link ${tab === "chittagong" ? "active" : ""}`}
                  id="pills-1-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-1"
                  type="button"
                  role="tab"
                  aria-controls="pills-1"
                  aria-selected="true"
                  onClick={(e) => handler(e, "chittagong")}
                >
                  Chittagong
                </button>
              </li>
            </ul>
            <div className="coreal-listing-tab-link">
              <a className="main-btn" href="#">
                50+ Cities
              </a>
            </div>
          </div>
          <div className="tab-content" id="pills-tabContent">
            <div
              className={`tab-pane fade show ${tab === "all" ? "active" : ""}`}
              id="pills-1"
              role="tabpanel"
              aria-labelledby="pills-1-tab"
            >
              <div className="coreal-listing-tab-content">
                <div className="row">
                  {listingDatas.slice(0, 6).map((value) => (
                    <div
                      key={value.id + value.title}
                      className="col-lg-4 col-md-6"
                    >
                      <CardStyleOne datas={value} />
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div
              className={`tab-pane fade show ${
                tab === "newWork" ? "active" : ""
              }`}
              id="pills-2"
              role="tabpanel"
              aria-labelledby="pills-2-tab"
            >
              <div className="coreal-listing-tab-content">
                <div className="row">
                  {listingDatas.slice(0, 6).map((value) => (
                    <div
                      key={value.id + value.title}
                      className="col-lg-4 col-md-6"
                    >
                      <CardStyleOne datas={value} />
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div
              className={`tab-pane fade show ${
                tab === "london" ? "active" : ""
              }`}
              id="pills-3"
              role="tabpanel"
              aria-labelledby="pills-3-tab"
            >
              <div className="coreal-listing-tab-content">
                <div className="row">
                  {listingDatas.slice(0, 6).map((value) => (
                    <div
                      key={value.id + value.title}
                      className="col-lg-4 col-md-6"
                    >
                      <CardStyleOne datas={value} />
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div
              className={`tab-pane fade show ${
                tab === "dhaka" ? "active" : ""
              }`}
              id="pills-4"
              role="tabpanel"
              aria-labelledby="pills-4-tab"
            >
              <div className="coreal-listing-tab-content">
                <div className="row">
                  {listingDatas.slice(0, 6).map((value) => (
                    <div
                      key={value.id + value.title}
                      className="col-lg-4 col-md-6"
                    >
                      <CardStyleOne datas={value} />
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div
              className={`tab-pane fade show ${
                tab === "sanghai" ? "active" : ""
              }`}
              id="pills-5"
              role="tabpanel"
              aria-labelledby="pills-5-tab"
            >
              <div className="coreal-listing-tab-content">
                <div className="row">
                  {listingDatas.slice(0, 6).map((value) => (
                    <div
                      key={value.id + value.title}
                      className="col-lg-4 col-md-6"
                    >
                      <CardStyleOne datas={value} />
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div
              className={`tab-pane fade show ${
                tab === "chittagong" ? "active" : ""
              }`}
              id="pills-6"
              role="tabpanel"
              aria-labelledby="pills-6-tab"
            >
              <div className="coreal-listing-tab-content">
                <div className="row">
                  {listingDatas.slice(0, 6).map((value) => (
                    <div
                      key={value.id + value.title}
                      className="col-lg-4 col-md-6"
                    >
                      <CardStyleOne datas={value} />
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ListingTab;
