import React from "react";
import useToggle from "../../Hooks/useToggles";
import listingData from "../Data/HomeOne";
import NavItems from "../Data/NavItems";
import BackToTop from "../Helper/BackToTop";
import Drawer from "../Mobile/Drawer";
import FooterCom from "../Partials/Footers/FooterHomeOne";
import HeaderCom from "../Partials/Headers/HeaderHomeOne";
import SearchBar from "../Partials/SearchBar";
import Category from "./Category";
import Contact from "./Contact";
import DownloadArea from "./DownloadArea";
import ExploreSection from "./ExploreSection";
import FeatureSection from "./FeatureSection";
import FunFactSection from "./FunFactSection";
import Hero from "./Hero";
import ListingPart from "./ListingPart";
import SponserSection from "./SponserSection";
import Testimonial from "./Testimonial";

export default function Home() {
  const [drawer, drawerAction] = useToggle(false);
  const [search, setSearch] = useToggle(false);
  return (
    <>
      <SearchBar search={search} action={setSearch.toggle} />
      <Drawer
        navItems={NavItems}
        drawer={drawer}
        action={drawerAction.toggle}
      />
      <HeaderCom action={drawerAction.toggle} searchAction={setSearch.toggle} />
      <Hero />
      <Category />
      <Contact />
      <ListingPart data={listingData.listingData} />
      <FunFactSection />
      <FeatureSection />
      <ExploreSection />
      <DownloadArea />
      <Testimonial />
      <SponserSection />
      <FooterCom />
      <BackToTop />
    </>
  );
}
