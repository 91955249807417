import React from "react";

function CardRowStyle({ className, datas }) {
  return (
    <>
      <div
        className={`coreal-listing-details-properties-list ${className || ""}`}
      >
        <div className="thumb">
          <i className="fal fa-heart"></i>
          <a href="#">
            <i className="fal fa-users"></i> <span>{datas.category}</span>
          </a>
          <div className="author">
            <img
              src={require(`../../assets/images/${datas.cover}`).default}
              alt=""
            />
          </div>
        </div>
        <div className="content">
          <h3 className="title">${datas.price}</h3>
          <a href="#">{datas.title}</a>
          <p>
            Estate is the land along with any permanent improvements attached to
            the land, whether natural or man-made—including water
          </p>
          <ul>
            <li>
              <i className="fal fa-bed"></i> {datas.bed} Bed
            </li>
            <li>
              <i className="fal fa-bath"></i> {datas.bath} Bath
            </li>
            <li>
              <i className="fal fa-expand"></i> {datas.sqft} sqft
            </li>
          </ul>
        </div>
      </div>
    </>
  );
}

export default CardRowStyle;
