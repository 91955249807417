import React from "react";
import dots from "../../../assets/images/dots.svg";
import logo from "../../../assets/images/logo.png";
import Navs from "../../Share/Navs";

function Header({ className, action, searchAction }) {
  return (
    <>
      <header className={`coreal-header-area ${className || ""}`}>
        <div className="container custom-container">
          <div className="row">
            <div className="col-lg-12">
              <div className="coreal-header-items d-flex justify-content-between align-items-center">
                <div className="coreal-header-brand d-flex">
                  <div className="logo">
                    <a href="#">
                      <img src={logo} alt="coreal" />
                    </a>
                  </div>
                  <div className="coreal-header-btns">
                    <ul>
                      <li>
                        <a href="#">Buy</a>
                      </li>
                      <li>
                        <a href="#">Rent</a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="coreal-header-main-menu">
                  <Navs />
                </div>
                <div className="coreal-header-bar">
                  <ul>
                    <li>
                      <a
                        className="coreal-canvas_open"
                        onClick={(e) => action(e)}
                      >
                        <img src={dots} alt="" />
                      </a>
                    </li>
                    <li>
                      <a
                        className="open-search"
                        onClick={(e) => {
                          searchAction(e);
                        }}
                      >
                        <i className="fal fa-search"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fal fa-user"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
}

export default Header;
