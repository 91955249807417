import React, { useState } from "react";
import SelectBox from "../../Share/SelectBox";

function MainSection() {
  const sorting1 = ["sorting1", "sorting24", "sorting34", "sorting44"];
  const [sort1, setSort1] = useState("Sorting");
  const changeSort1 = (value) => {
    setSort1(value);
  };
  const sorting2 = ["sorting11", "sorting21", "sorting31", "sorting41"];
  const [sort2, setSort2] = useState("Sorting");
  const changeSort2 = (value) => {
    setSort2(value);
  };
  return (
    <>
      <section className="coreal-agency-page-area pt-55 pb-100">
        <div className="container custom-container">
          <div className="row">
            <div className="col-lg-4 col-md-6">
              <div className="coreal-agents-item coreal-agents-item-page mt-45">
                <div className="thumb">
                  <img
                    src={require(`../../../assets/images/agents-1.png`).default}
                    alt=""
                  />
                </div>
                <div className="content">
                  <span>Founder</span>
                  <h4 className="title">Romada H. Halim</h4>
                  <ul>
                    <li>
                      <a href="#">
                        <i className="fal fa-phone"></i> 980. 098. 967. 83
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fal fa-envelope"></i> info@webexample.com
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="coreal-agents-item coreal-agents-item-page mt-45">
                <div className="thumb">
                  <img
                    src={require(`../../../assets/images/agents-2.png`).default}
                    alt=""
                  />
                </div>
                <div className="content">
                  <span>Founder</span>
                  <h4 className="title">Romada H. Halim</h4>
                  <ul>
                    <li>
                      <a href="#">
                        <i className="fal fa-phone"></i> 980. 098. 967. 83
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fal fa-envelope"></i> info@webexample.com
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="coreal-agents-item coreal-agents-item-page mt-45">
                <div className="thumb">
                  <img
                    src={require(`../../../assets/images/agents-3.png`).default}
                    alt=""
                  />
                </div>
                <div className="content">
                  <span>Founder</span>
                  <h4 className="title">Romada H. Halim</h4>
                  <ul>
                    <li>
                      <a href="#">
                        <i className="fal fa-phone"></i> 980. 098. 967. 83
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fal fa-envelope"></i> info@webexample.com
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="coreal-agents-item coreal-agents-item-page mt-45">
                <div className="thumb">
                  <img
                    src={require(`../../../assets/images/agents-4.png`).default}
                    alt=""
                  />
                </div>
                <div className="content">
                  <span>Founder</span>
                  <h4 className="title">Romada H. Halim</h4>
                  <ul>
                    <li>
                      <a href="#">
                        <i className="fal fa-phone"></i> 980. 098. 967. 83
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fal fa-envelope"></i> info@webexample.com
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="coreal-agents-item coreal-agents-item-page mt-45">
                <div className="thumb">
                  <img
                    src={require(`../../../assets/images/agents-1.png`).default}
                    alt=""
                  />
                </div>
                <div className="content">
                  <span>Founder</span>
                  <h4 className="title">Romada H. Halim</h4>
                  <ul>
                    <li>
                      <a href="#">
                        <i className="fal fa-phone"></i> 980. 098. 967. 83
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fal fa-envelope"></i> info@webexample.com
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="listing-details-find sidebar-widget mt-45">
                <div className="sidebar-widget-title">
                  <h3 className="title">Become an agent</h3>
                </div>
                <div className="listing-details-find-box mt-30">
                  <form action="#">
                    <div className="input-box">
                      <input type="text" placeholder="Enter full name" />
                      <i className="fal fa-user"></i>
                    </div>
                    <div className="">
                      <div className="coreal-select-item">
                        <SelectBox
                          className="mb-20"
                          datas={sorting1}
                          selected={sort1}
                          action={changeSort1}
                        />
                      </div>
                    </div>
                    <div className="">
                      <div className="coreal-select-item">
                        <SelectBox
                          className="mb-20"
                          datas={sorting2}
                          selected={sort2}
                          action={changeSort2}
                        />
                      </div>
                    </div>
                    <div className="input-box">
                      <button type="button">Be An Agent 007</button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default MainSection;
