import React from "react";

function TrackingUserCard({ datas, usercount }) {
  return (
    <>
      <div className={`item-${usercount} item`}>
        <img
          src={require(`../../assets/images/${datas.user}`).default}
          alt=""
        />
        <div className="coreal-track-user-info">
          <h4 className="title">{datas.username}</h4>
          <ul>
            <li>
              <i className="fal fa-phone"></i> {datas.phone}
            </li>
            <li>
              <i className="fal fa-envelope"></i>
              {datas.email}
            </li>
          </ul>
        </div>
      </div>
    </>
  );
}

export default TrackingUserCard;
