const data = {
  listingData: [
    {
      price: 8917,
      title: "2166 20th Ave San Francisco, CA",
      bed: 2,
      bath: 4,
      sqft: 3456,
      image: "listing-1.jpg",
    },
    {
      price: 8917,
      title: "2166 20th Ave San Francisco, CA",
      bed: 2,
      bath: 4,
      sqft: 3456,
      image: "listing-2.jpg",
    },
    {
      price: 8917,
      title: "2166 20th Ave San Francisco, CA",
      bed: 2,
      bath: 4,
      sqft: 3456,
      image: "listing-3.jpg",
    },
    {
      price: 8917,
      title: "2166 20th Ave San Francisco, CA",
      bed: 2,
      bath: 4,
      sqft: 3456,
      image: "listing-4.jpg",
    },
  ],
};
export default data;
