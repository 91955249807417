import React from "react";
import CardStyleTwo from "../Card/CardStyleTwo";

function Blogs({ blogs }) {
  return (
    <>
      <section className="coreal-blog-area pt-90 pb-100">
        <div className="container custom-container">
          <div className="row">
            <div className="col-lg-12">
              <div className="section-title">
                <span>Insights</span>
                <h3 className="title">Blog insights</h3>
              </div>
            </div>
          </div>
          <div className="row">
            {blogs.map((value) => (
              <div
                key={Math.random() + value.username}
                className="col-lg-4 col-md-6"
              >
                <CardStyleTwo datas={value} />
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
}

export default Blogs;
