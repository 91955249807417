import React from "react";
import faq from "../../Data/Faq";
import NavItems from "../../Data/NavItems";
import BackToTop from "../../Helper/BackToTop";
import MargeHeaderDrawer from "../../Helper/MargeHeaderDrawer";
import FooterCom from "../../Partials/Footers/FooterHomeOne";
import Hero from "./Hero";
import MainSection from "./MainSection";

function FAQ() {
  return (
    <>
      <MargeHeaderDrawer navItems={NavItems} />
      <Hero />
      <MainSection data={faq.faq} />
      <FooterCom />
      <BackToTop />
    </>
  );
}

export default FAQ;
