import React from "react";
import Navs from "../../Share/Navs";

function HeaderHomeThree({ action }) {
  return (
    <header className="coreal-header-area-3">
      <div className="coreal-header-topbar-3">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div className="coreal-header-topbar-box d-flex justify-content-between align-items-center">
                <div className="coreal-header-topbar-info">
                  <div className="item">
                    <div className="icon">
                      <img
                        src={
                          require("../../../assets/images/icon/header-icon-1.svg")
                            .default
                        }
                        alt=""
                      />
                    </div>
                    <div className="content">
                      <span>Support Email</span>
                      <a href="#">support@example.com</a>
                    </div>
                  </div>
                  <div className="item ml-70">
                    <div className="icon">
                      <img
                        src={
                          require("../../../assets/images/icon/header-icon-2.svg")
                            .default
                        }
                        alt=""
                      />
                    </div>
                    <div className="content">
                      <span>Phone Number</span>
                      <a href="#">097. 897. 986. 87</a>
                    </div>
                  </div>
                </div>
                <div className="coreal-header-topbar-brand">
                  <a href="#">
                    <img
                      src={require("../../../assets/images/logo.png").default}
                      alt=""
                    />
                  </a>
                  <div className="coreal-live-chat">
                    <a href="#">
                      <i className="fal fa-comments"></i> Live Chat/Support
                    </a>
                  </div>
                </div>
                <div className="coreal-header-topbar-btns d-flex justify-content-between align-items-center">
                  <div className="link ">
                    <a href="#">
                      <i className="fal fa-home"></i> List Your Property
                    </a>
                  </div>
                  <div className="coreal-header-bar ml-20">
                    <ul>
                      <li>
                        <a
                          className="coreal-canvas_open"
                          href="#"
                          onClick={(e) => action(e)}
                        >
                          <img
                            src={
                              require(`../../../assets/images/dots.svg`).default
                            }
                            alt=""
                          />
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="fal fa-user"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-12">
            <div className="coreal-header-items-3 d-flex justify-content-between align-items-center">
              <div className="coreal-header-main-menu">
                <Navs />
              </div>
              <div className="coreal-header-btns d-flex align-items-center">
                <div className="coreal-select-item">
                  <select
                    className="nice-select"
                    style={{ appearance: "none" }}
                  >
                    <option data-display="Sell">Sell</option>
                    <option value="1">Buy</option>
                    <option value="2">Rent</option>
                  </select>
                </div>
                <div className="coreal-header-search">
                  <form action="#">
                    <div className="input-box">
                      <input type="text" placeholder="Search with keyword" />
                      <i className="fal fa-search"></i>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}

export default HeaderHomeThree;
