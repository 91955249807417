import React, { useState } from "react";

function Drawer({ drawer, action, navItems }) {
  const [itemSize, setSize] = useState("0px");
  const [item, setItem] = useState("");

  const handler = (e, value) => {
    e.preventDefault();
    const getItems = document.querySelectorAll(`#${value} li`).length;
    if (value !== item) {
      setSize(`${50 * getItems}px`);
      setItem(value);
    } else {
      setItem("");
      setSize("0px");
    }
  };
  return (
    <>
      <div
        className={`coreal-off_canvars_overlay ${
          drawer ? "coreal-active" : ""
        }`}
        onClick={(e) => action(e)}
      ></div>
      <div className="coreal-offcanvas_menu">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div
                className={`coreal-offcanvas_menu_wrapper overflow-style-none ${
                  drawer ? "coreal-active" : ""
                }`}
              >
                <div className="coreal-canvas_close" onClick={(e) => action(e)}>
                  <a href="#">
                    <i className="fal fa-times"></i>
                  </a>
                </div>
                <div className="coreal-header-social">
                  <ul className="text-center">
                    <li>
                      <a href="#">facebook</a>
                    </li>
                    <li>
                      <a href="#">Twitter</a>
                    </li>
                    <li>
                      <a href="#">Skype</a>
                    </li>
                  </ul>
                </div>
                <div id="menu" className="text-left">
                  <ul className="coreal-offcanvas_main_menu">
                    {navItems.map((items) => (
                      <li
                        key={items.id + items.title}
                        className={`coreal-menu-item-has-children ${
                          item === items.title ? "coreal-menu-open" : ""
                        }`}
                      >
                        {items.submenu ? (
                          <>
                            <span className="menu-expand">
                              <i className="far fa-angle-down"></i>
                            </span>
                            <a onClick={(e) => handler(e, items.title)}>
                              {items.title}
                            </a>
                          </>
                        ) : (
                          <a href={items.link}>{items.title}</a>
                        )}

                        {items.submenu && (
                          <ul
                            id={items.title}
                            className="sidebar-menu overflow-hidden"
                            style={{
                              maxHeight:
                                item === items.title ? itemSize : "0px",
                            }}
                          >
                            {items.items.map((subItems) => (
                              <li key={subItems.id + subItems.title}>
                                <a href="#">{subItems.title}</a>
                              </li>
                            ))}
                          </ul>
                        )}
                      </li>
                    ))}
                  </ul>
                </div>
                {/* <div id="menu" className="text-left">
                  <ul className="coreal-offcanvas_main_menu">
                    <li className="coreal-menu-item-has-children">
                      <span className="menu-expand">
                        <i className="far fa-angle-down"></i>
                      </span>
                      <a onClick={(e) => handler(e, "home")}>home</a>
                      <ul
                        id="home"
                        className="sidebar-menu overflow-hidden"
                        style={{
                          maxHeight: item === "home" ? itemSize : "0px",
                        }}
                      >
                        <li>
                          <a href="#">home 2</a>
                        </li>
                        <li>
                          <a href="#">home 2</a>
                        </li>
                        <li>
                          <a href="#">home 2</a>
                        </li>
                        <li>
                          <a href="#">home 2</a>
                        </li>
                      </ul>
                    </li>
                    <li className="coreal-menu-item-has-children">
                      <span className="menu-expand">
                        <i className="far fa-angle-down"></i>
                      </span>
                      <a onClick={(e) => handler(e, "about")}>about</a>
                      <ul
                        id="about"
                        className="sidebar-menu overflow-hidden"
                        style={{
                          maxHeight: item === "about" ? itemSize : "0px",
                        }}
                      >
                        <li>
                          <a href="#">about 2</a>
                        </li>
                        <li>
                          <a href="#">about 2</a>
                        </li>
                        <li>
                          <a href="#">about 2</a>
                        </li>
                        <li>
                          <a href="#">about 2</a>
                        </li>
                        <li>
                          <a href="#">about 2</a>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </div> */}
                <div className="coreal-offcanvas_footer">
                  <div className="coreal-logo text-center mb-30 mt-30">
                    <a href="index.html">
                      <img
                        src={require(`../../assets/images/logo.png`).default}
                        alt=""
                      />
                    </a>
                  </div>
                  <p>
                    I’m Michal Škvarenina, a multi-disciplinary designer
                    currently working at Wild and as a freelance designer.
                  </p>
                  <ul>
                    <li>
                      <i className="fas fa-phone"></i> +212 34 45 45 98
                    </li>
                    <li>
                      <i className="fas fa-home"></i> Angle Bd Abdelmoumen & rue
                      soumaya, Résidence
                    </li>
                    <li>
                      <i className="fas fa-envelope"></i> hello@example.com
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Drawer;
