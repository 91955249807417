import mapboxgl from "mapbox-gl";
import React, { useEffect, useRef, useState } from "react";
import SelectBox from "../../Share/SelectBox";

mapboxgl.accessToken =
  "pk.eyJ1IjoicmF5aGFuMDQyMSIsImEiOiJja251NnZkcDUwYTJjMnhtbnAxYW8yOXlrIn0.8gtnbL6vZeMb7wm1x6CmaQ";
function MainSection({ listedGridData }) {
  const mapContainer = useRef(null);
  const [lng] = useState(-79.4512);
  const [lat] = useState(43.6568);
  const [zoom] = useState(13);

  useEffect(() => {
    const map = new mapboxgl.Map({
      container: mapContainer.current,
      style: "mapbox://styles/mapbox/streets-v11",
      center: [lng, lat],
      zoom,
    });

    map.addControl(new mapboxgl.NavigationControl());
  });
  const [selected, setSelected] = useState([]);
  const handleChange = (id) => {
    let selectedItem = selected;
    let findItem = selected.indexOf(id);
    findItem > -1 ? selectedItem.splice(findItem, 1) : selectedItem.push(id);
    setSelected(selectedItem);
  };

  // selectbox
  const sorting = ["sorting1", "sorting2", "sorting3", "sorting4"];
  const [sort, setSort] = useState("Currency");
  const changeSort = (value) => {
    setSort(value);
  };

  const sorting1 = ["sorting1", "sorting24", "sorting34", "sorting44"];
  const [sort1, setSort1] = useState("price format");
  const changeSort1 = (value) => {
    setSort1(value);
  };
  const sorting2 = ["sorting11", "sorting21", "sorting31", "sorting41"];
  const [sort2, setSort2] = useState("Currency");
  const changeSort2 = (value) => {
    setSort2(value);
  };
  const sorting3 = ["sorting12", "sorting22", "sorting32", "sorting42"];
  const [sort3, setSort3] = useState("SQ ft");
  const changeSort3 = (value) => {
    setSort3(value);
  };
  const sorting4 = ["sorting13", "sorting23", "sorting33", "sorting43"];
  const [sort4, setSort4] = useState("Condition");
  const changeSort4 = (value) => {
    setSort4(value);
  };
  const sorting12 = [
    "sorting1312",
    "sorting2312",
    "sorting3312",
    "sorting4312",
  ];
  const [sort12, setSort12] = useState("Intervel payment");
  const changeSort12 = (value) => {
    setSort12(value);
  };
  const sorting5 = ["sorting135", "sorting235", "sorting335", "sorting435"];
  const [sort5, setSort5] = useState("Property type");
  const changeSort5 = (value) => {
    setSort5(value);
  };
  const sorting6 = ["sorting136", "sorting236", "sorting336", "sorting436"];
  const [sort6, setSort6] = useState("Status");
  const changeSort6 = (value) => {
    setSort6(value);
  };
  const sorting7 = ["sorting137", "sorting237", "sorting337", "sorting437"];
  const [sort7, setSort7] = useState("Condition");
  const changeSort7 = (value) => {
    setSort7(value);
  };
  const sorting8 = ["sorting138", "sorting238", "sorting338", "sorting438"];
  const [sort8, setSort8] = useState("Town/city");
  const changeSort8 = (value) => {
    setSort8(value);
  };
  const sorting9 = ["sorting139", "sorting239", "sorting339", "sorting439"];
  const [sort9, setSort9] = useState("Post Zip code");
  const changeSort9 = (value) => {
    setSort9(value);
  };
  const sorting10 = [
    "sorting1310",
    "sorting2310",
    "sorting3310",
    "sorting4310",
  ];
  const [sort10, setSort10] = useState("District");
  const changeSort10 = (value) => {
    setSort10(value);
  };
  const sorting11 = [
    "sorting1311",
    "sorting2311",
    "sorting3311",
    "sorting4311",
  ];
  const [sort11, setSort11] = useState("state");
  const changeSort11 = (value) => {
    setSort11(value);
  };

  return (
    <>
      <section className="coreal-add-listing-area">
        <div className="container custom-container">
          <div className="row">
            <div className="col-lg-12">
              <div className="coreal-add-listing-box">
                <form className="coreal-form" method="post" action="#">
                  <div className="coreal-add-listing-title">
                    <span>01</span>
                    <h3 className="title">Add Properties</h3>
                    <p>
                      Set all the content & other assets for list your property
                    </p>
                  </div>
                  <div className="coreal-add-listing-porperties-form">
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="input-box">
                          <input
                            type="text"
                            placeholder="Enter property name"
                          />
                        </div>
                      </div>
                      <div className="col-lg-4">
                        <div className="input-box">
                          <div className="coreal-select-item">
                            <SelectBox
                              className="mb-20"
                              datas={sorting}
                              selected={sort}
                              action={changeSort}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4">
                        <div className="input-box">
                          <input
                            type="text"
                            placeholder="Enter property name"
                          />
                          <i className="fal fa-dollar-sign"></i>
                        </div>
                      </div>
                      <div className="col-lg-4">
                        <div className="input-box">
                          <div className="coreal-select-item">
                            <SelectBox
                              className="mb-20"
                              datas={sorting1}
                              selected={sort1}
                              action={changeSort1}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4">
                        <div className="input-box">
                          <div className="coreal-select-item">
                            <SelectBox
                              className="mb-20"
                              datas={sorting2}
                              selected={sort2}
                              action={changeSort2}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4">
                        <div className="input-box">
                          <input
                            type="text"
                            placeholder="Enter property name"
                          />
                          <i className="fal fa-dollar-sign"></i>
                        </div>
                      </div>
                      <div className="col-lg-4">
                        <div className="input-box">
                          <div className="coreal-select-item">
                            <SelectBox
                              className="mb-20"
                              datas={sorting3}
                              selected={sort3}
                              action={changeSort3}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="input-box">
                          <textarea
                            name="#"
                            id="#"
                            cols="30"
                            rows="10"
                            placeholder="Descriptions"
                          ></textarea>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="coreal-add-listing-title mt-70">
                    <span>02</span>
                    <h3 className="title">Summary</h3>
                    <p>
                      Set all the content & other assets for list your property
                    </p>
                  </div>
                  <div className="coreal-add-listing-summary-form">
                    <div className="row">
                      <div className="col-lg-4">
                        <div className="input-box">
                          <div className="coreal-select-item">
                            <SelectBox
                              className="mb-20"
                              datas={sorting4}
                              selected={sort4}
                              action={changeSort4}
                            />
                          </div>
                        </div>
                        <div className="input-box">
                          <div className="coreal-select-item">
                            <SelectBox
                              className="mb-20"
                              datas={sorting5}
                              selected={sort5}
                              action={changeSort5}
                            />
                          </div>
                        </div>
                        <div className="input-box">
                          <input type="text" placeholder="Rooms" />
                          <i className="fal fa-window"></i>
                        </div>
                        <div className="input-box">
                          <input type="text" placeholder="Bathroom" />
                          <i className="fal fa-bath"></i>
                        </div>
                        <div className="input-box">
                          <input type="text" placeholder="Area" />
                          <i className="fal fa-expand"></i>
                        </div>
                      </div>
                      <div className="col-lg-4">
                        <div className="input-box">
                          <div className="coreal-select-item">
                            <SelectBox
                              className="mb-20"
                              datas={sorting6}
                              selected={sort6}
                              action={changeSort6}
                            />
                          </div>
                        </div>
                        <div className="input-box">
                          <div className="coreal-select-item">
                            <SelectBox
                              className="mb-20"
                              datas={sorting7}
                              selected={sort7}
                              action={changeSort7}
                            />
                          </div>
                        </div>
                        <div className="input-box">
                          <input type="text" placeholder="Bed" />
                          <i className="fal fa-bed"></i>
                        </div>
                        <div className="input-box">
                          <input type="text" placeholder="Gareges" />
                          <i className="fal fa-car-garage"></i>
                        </div>
                        <div className="input-box">
                          <input type="text" placeholder="Area Unit" />
                          <i className="fal fa-map"></i>
                        </div>
                      </div>
                      <div className="col-lg-4">
                        <div className="coreal-add-listing-upload">
                          <div className="box__input">
                            <input
                              className="box__file"
                              type="file"
                              name="files[]"
                              id="coreal_imgInp"
                              data-multiple-caption="{count} files selected"
                              multiple
                            />
                            <label htmlFor="coreal_imgInp">
                              <span className="box__dragndrop">
                                <img
                                  src={
                                    require(`../../../assets/images/camera-icon.png`)
                                      .default
                                  }
                                  alt=""
                                />
                              </span>
                              <br />
                              <strong>Add Photo</strong>
                            </label>
                            <div className="coreal-input-thumb">
                              {/* <img
                                id="coreal_blah"
                                src="#"
                                alt="upload image"
                              /> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="coreal-listing-details-features mt-40">
                      <div className="coreal-listing-details-title">
                        <h3 className="title">Additional Details</h3>
                      </div>
                      <ul className="coreal-listing-details-checkbox">
                        <li>
                          <input
                            type="checkbox"
                            name="checkbox24"
                            id="checkbox24"
                          />
                          <label htmlFor="checkbox24">
                            <span></span>Air Conditioning
                          </label>
                        </li>

                        <li>
                          <input
                            type="checkbox"
                            name="checkbox25"
                            id="checkbox25"
                          />
                          <label htmlFor="checkbox25">
                            <span></span>Backyard
                          </label>
                        </li>

                        <li>
                          <input
                            type="checkbox"
                            name="checkbox26"
                            id="checkbox26"
                          />
                          <label htmlFor="checkbox26">
                            <span></span>Balcony
                          </label>
                        </li>

                        <li>
                          <input
                            type="checkbox"
                            name="checkbox27"
                            id="checkbox27"
                          />
                          <label htmlFor="checkbox27">
                            <span></span>Dryer
                          </label>
                        </li>

                        <li>
                          <input
                            type="checkbox"
                            name="checkbox28"
                            id="checkbox28"
                          />
                          <label htmlFor="checkbox28">
                            <span></span>Electric Range
                          </label>
                        </li>

                        <li>
                          <input
                            type="checkbox"
                            name="checkbox29"
                            id="checkbox29"
                          />
                          <label htmlFor="checkbox29">
                            <span></span>Fully Furnished
                          </label>
                        </li>

                        <li>
                          <input
                            type="checkbox"
                            name="checkbox30"
                            id="checkbox30"
                          />
                          <label htmlFor="checkbox30">
                            <span></span>Gym
                          </label>
                        </li>

                        <li>
                          <input
                            type="checkbox"
                            name="checkbo31"
                            id="checkbo31"
                          />
                          <label htmlFor="checkbo31">
                            <span></span>Home Theater
                          </label>
                        </li>

                        <li>
                          <input
                            type="checkbox"
                            name="checkbox32"
                            id="checkbox32"
                          />
                          <label htmlFor="checkbox32">
                            <span></span>Laundry Room
                          </label>
                        </li>

                        <li>
                          <input
                            type="checkbox"
                            name="checkbox33"
                            id="checkbox33"
                          />
                          <label htmlFor="checkbox33">
                            <span></span>Ocean View
                          </label>
                        </li>

                        <li>
                          <input
                            type="checkbox"
                            name="checkbox34"
                            id="checkbox34"
                          />
                          <label htmlFor="checkbox34">
                            <span></span>Pool
                          </label>
                        </li>

                        <li>
                          <input
                            type="checkbox"
                            name="checkbox35"
                            id="checkbox35"
                          />
                          <label htmlFor="checkbox35">
                            <span></span>Recreation
                          </label>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="coreal-add-listing-title mt-70">
                    <span>03</span>
                    <h3 className="title">Location Information</h3>
                    <p>
                      Set all the content & other assets for list your property
                    </p>
                  </div>
                  <div className="coreal-add-listing-information-form">
                    <div className="row">
                      <div className="col-lg-4">
                        <div className="input-box">
                          <div className="coreal-select-item">
                            <SelectBox
                              className="mb-20"
                              datas={sorting8}
                              selected={sort8}
                              action={changeSort8}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4">
                        <div className="input-box">
                          <div
                            id="coreal-geocoder"
                            className="coreal-geocoder"
                          ></div>
                        </div>
                      </div>
                      <div className="col-lg-4">
                        <div className="input-box">
                          <div className="coreal-select-item">
                            <SelectBox
                              className="mb-20"
                              datas={sorting9}
                              selected={sort9}
                              action={changeSort9}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4">
                        <div className="input-box">
                          <input type="text" placeholder="Property Address" />
                          <i className="fal fa-map-marker-alt"></i>
                        </div>
                        <div className="input-box">
                          <div className="coreal-select-item">
                            <SelectBox
                              className="mb-20"
                              datas={sorting10}
                              selected={sort10}
                              action={changeSort10}
                            />
                          </div>
                        </div>
                        <div className="input-box">
                          <div className="coreal-select-item">
                            <SelectBox
                              className="mb-20"
                              datas={sorting11}
                              selected={sort11}
                              action={changeSort11}
                            />
                          </div>
                        </div>
                        <div className="input-box">
                          <div className="coreal-select-item">
                            <SelectBox
                              className="mb-20"
                              datas={sorting12}
                              selected={sort12}
                              action={changeSort12}
                            />
                          </div>
                        </div>
                        <div className="input-box">
                          <input type="text" placeholder="Cordinate" />
                          <i className="fal fa-location"></i>
                        </div>
                      </div>
                      <div className="col-lg-8">
                        <div className="coreal-map mt-20">
                          <div ref={mapContainer} id="coreal-map"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="coreal-add-listing-title mt-70">
                    <span>04</span>
                    <h3 className="title">Media Files/Assets</h3>
                    <p>
                      Set all the content & other assets for list your property
                    </p>
                  </div>
                  <div className="coreal-add-listing-upload-file-form">
                    <div className="row">
                      <div className="col-lg-4">
                        <div className="coreal-add-listing-upload-file-box mt-20">
                          <div className="icon">
                            <i className="fal fa-address-book"></i>
                          </div>
                          <h4 className="title">Property Document</h4>
                          <p>
                            Upload every single documents you have, don’t hide
                            anything
                          </p>
                          <div className="coreal-add-listing-modal">
                            <button
                              type="button"
                              className="btn btn-primary"
                              data-bs-toggle="modal"
                              data-bs-target="#exampleModal1"
                            >
                              Upload
                            </button>

                            <div
                              className="modal fade coreal-add-listing-modal"
                              id="exampleModal1"
                              tabIndex="-1"
                              aria-hidden="true"
                            >
                              <div className="modal-dialog modal-lg  modal-dialog-centered">
                                <div className="modal-content">
                                  <div className="modal-body">
                                    <div className="coreal-property-title">
                                      <h3 className="title">
                                        Property Document
                                      </h3>
                                    </div>
                                    <div className="coreal-property-form mt-20 coreal-repeater">
                                      <div
                                        className="coreal-repeater-form-box"
                                        data-repeater-list="category-group"
                                      >
                                        <div data-repeater-item>
                                          <div className="coreal-property-document-upload">
                                            <input
                                              id="coreal_label"
                                              className="box__file"
                                              type="file"
                                            />
                                          </div>
                                          <input
                                            type="hidden"
                                            name="id"
                                            id="cat-id"
                                          />
                                          <input type="text" name="cat-title" />
                                          <input type="text" name="cat-slug" />
                                          <input
                                            className="float-end"
                                            data-repeater-delete
                                            type="button"
                                            value="Delete"
                                          />
                                        </div>
                                      </div>
                                      <input
                                        className="add-btn"
                                        data-repeater-create
                                        type="button"
                                        value="Add"
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4">
                        <div className="coreal-add-listing-upload-file-box mt-20">
                          <div className="icon">
                            <i className="fal fa-camera"></i>
                          </div>
                          <h4 className="title">Property Photos</h4>
                          <p>
                            Upload every single documents you have, don’t hide
                            anything
                          </p>
                          <div className="coreal-add-listing-modal">
                            <button
                              type="button"
                              className="btn btn-primary"
                              data-bs-toggle="modal"
                              data-bs-target="#exampleModal2"
                            >
                              Upload
                            </button>
                            <div
                              className="modal fade coreal-add-listing-modal"
                              id="exampleModal2"
                              tabIndex="-1"
                              aria-hidden="true"
                            >
                              <div className="modal-dialog modal-lg  modal-dialog-centered">
                                <div className="modal-content">
                                  <div className="modal-body">
                                    <div className="coreal-property-title">
                                      <h3 className="title">Property Photos</h3>
                                    </div>
                                    <div className="coreal-add-listing-upload">
                                      <div className="box__input">
                                        <input
                                          className="box__file"
                                          type="file"
                                          name="files[]"
                                          id="coreal_imgInp2"
                                          data-multiple-caption="{count} files selected"
                                          multiple
                                        />
                                        <label htmlFor="coreal_imgInp">
                                          <span className="box__dragndrop">
                                            <img
                                              src="assets/images/camera-icon.png"
                                              alt=""
                                            />
                                          </span>
                                          <br />
                                          <strong>Add Photo</strong>
                                        </label>
                                        <div className="coreal-input-thumb">
                                          {/* <img
                                            id="coreal_blah2"
                                            src="#"
                                            alt="upload image"
                                          /> */}
                                        </div>
                                      </div>
                                    </div>
                                    <div className="coreal-property-form">
                                      <input type="button" value="Save" />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4">
                        <div className="coreal-add-listing-upload-file-box mt-20">
                          <div className="icon">
                            <i className="fal fa-home"></i>
                          </div>
                          <h4 className="title">Floor Plans</h4>
                          <p>
                            Upload every single documents you have, don’t hide
                            anything
                          </p>
                          <div className="coreal-add-listing-modal">
                            <button
                              type="button"
                              className="btn btn-primary"
                              data-bs-toggle="modal"
                              data-bs-target="#exampleModal3"
                            >
                              Upload
                            </button>

                            <div
                              className="modal fade coreal-add-listing-modal"
                              id="exampleModal3"
                              tabIndex="-1"
                              aria-hidden="true"
                            >
                              <div className="modal-dialog modal-lg  modal-dialog-centered">
                                <div className="modal-content">
                                  <div className="modal-body">
                                    <div className="coreal-property-title mb-40">
                                      <h3 className="title">Floor Plans</h3>
                                    </div>
                                    <div className="input-box">
                                      <input type="text" placeholder="Title" />
                                    </div>
                                    <div className="input-box">
                                      <input
                                        type="text"
                                        placeholder="Area Size"
                                      />
                                    </div>
                                    <div className="input-box">
                                      <input
                                        type="text"
                                        placeholder="Bed Room"
                                      />
                                    </div>
                                    <div className="input-box">
                                      <input
                                        type="text"
                                        placeholder="Bath Room"
                                      />
                                    </div>
                                    <div className="coreal-property-form">
                                      <input type="button" value="Save" />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="input-box">
                          <input
                            type="text"
                            placeholder="Enter direct video link or drag video"
                          />
                          <div className="box__input">
                            <input
                              className="box__file"
                              type="file"
                              name="files[]"
                              id="file"
                              data-multiple-caption="{count} files selected"
                              multiple
                            />
                            <label htmlFor="file">
                              <strong>
                                <i className="fal fa-video"></i> Upload Video
                              </strong>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="coreal-add-listing-title mt-70">
                    <span>05</span>
                    <h3 className="title">Property Features</h3>
                    <p>
                      Set all the content & other assets for list your property
                    </p>
                  </div>
                  <div className="coreal-add-listing-property-form-box pt-10">
                    <div className="row">
                      <div className="col-lg-4">
                        <ul className="coreal-checkbox-box checkbox_style6">
                          {listedGridData.slice(0, 8).map((item) => (
                            <li
                              key={item.id}
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <input
                                  type="checkbox"
                                  onChange={() => handleChange(item.id)}
                                  selected={selected.includes(item.id)}
                                />
                                <span>{item.name}</span>
                              </div>
                            </li>
                          ))}
                        </ul>
                      </div>
                      <div className="col-lg-4">
                        <ul className="coreal-checkbox-box checkbox_style6">
                          {listedGridData.slice(0, 8).map((item) => (
                            <li
                              key={item.id}
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <input
                                  type="checkbox"
                                  onChange={() => handleChange(item.id)}
                                  selected={selected.includes(item.id)}
                                />
                                <span>{item.name}</span>
                              </div>
                            </li>
                          ))}
                        </ul>
                      </div>
                      <div className="col-lg-4">
                        <ul className="coreal-checkbox-box checkbox_style6">
                          {listedGridData.slice(0, 8).map((item) => (
                            <li
                              key={item.id}
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <input
                                  type="checkbox"
                                  onChange={() => handleChange(item.id)}
                                  selected={selected.includes(item.id)}
                                />
                                <span>{item.name}</span>
                              </div>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="coreal-add-listing-submit-btn">
                    <ul>
                      <li>
                        <button type="submit">Publish Property</button>
                      </li>
                      <li>
                        <a href="#">
                          <i className="fal fa-pen"></i> Edit Details
                        </a>
                      </li>
                    </ul>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default MainSection;
