import React from "react";

function FooterHomeTwo() {
  return (
    <>
      <section className="coreal-footer-2-area">
        <div className="container custom-container">
          <div className="row">
            <div className="col-lg-4">
              <div className="footer-about-widget">
                <h4 className="title">About Us</h4>
                <p>
                  Finding the mental wherewithal to get into workout mode can
                  take some serious effort, especially when your activities
                  happen at home. It can be done, but you have
                </p>
                <ul>
                  <li>
                    <a href="#">
                      <i className="fab fa-facebook-f"></i>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i className="fab fa-twitter"></i>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i className="fab fa-behance"></i>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i className="fab fa-youtube"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="footer-links-widget">
                <div className="item">
                  <h4 className="title">Main Links</h4>
                  <ul>
                    <li>
                      <a href="#">About Us</a>
                    </li>
                    <li>
                      <a href="#">Services</a>
                    </li>
                    <li>
                      <a href="#">Pricing</a>
                    </li>
                    <li>
                      <a href="#">Portfolio</a>
                    </li>
                    <li>
                      <a href="#">Insights</a>
                    </li>
                    <li>
                      <a href="#">Hiring</a>
                    </li>
                  </ul>
                </div>
                <div className="item">
                  <h4 className="title">Services</h4>
                  <ul>
                    <li>
                      <a href="#">Web Design</a>
                    </li>
                    <li>
                      <a href="#">Game Design</a>
                    </li>
                    <li>
                      <a href="#">UI & UX Design</a>
                    </li>
                    <li>
                      <a href="#">CMS Support</a>
                    </li>
                    <li>
                      <a href="#">CRM Software</a>
                    </li>
                    <li>
                      <a href="#">Online Support</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="footer-post-widget">
                <div className="widget-title">
                  <h3 className="title">Weekly Insights</h3>
                </div>
                <div className="footer-post-box">
                  <div className="footer-post-item">
                    <div className="thumb">
                      <img
                        src={
                          require(`../../../assets/images/footer-post-thumb-1.png`)
                            .default
                        }
                        alt=""
                      />
                    </div>
                    <div className="content">
                      <h4 className="title">
                        <a href="#">Built with light & dark mode</a>
                      </h4>
                      <div className="meta-item">
                        <div className="meta-author">
                          <span>
                            By <a href="#">Admin</a>
                          </span>
                        </div>
                        <div className="meta-comment">
                          <span>23 Comments</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="footer-post-item">
                    <div className="thumb">
                      <img
                        src={
                          require(`../../../assets/images/footer-post-thumb-2.png`)
                            .default
                        }
                        alt=""
                      />
                    </div>
                    <div className="content">
                      <h4 className="title">
                        <a href="#">Luxury Villa In Los Angeles</a>
                      </h4>
                      <div className="meta-item">
                        <div className="meta-author">
                          <span>
                            By <a href="#">Admin</a>
                          </span>
                        </div>
                        <div className="meta-comment">
                          <span>23 Comments</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="footer-post-item">
                    <div className="thumb">
                      <img
                        src={
                          require(`../../../assets/images/footer-post-thumb-3.png`)
                            .default
                        }
                        alt=""
                      />
                    </div>
                    <div className="content">
                      <h4 className="title">
                        <a href="#">Casa Lomas de Machalí</a>
                      </h4>
                      <div className="meta-item">
                        <div className="meta-author">
                          <span>
                            By <a href="#">Admin</a>
                          </span>
                        </div>
                        <div className="meta-comment">
                          <span>23 Comments</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="coreal-copyright-area coreal-copyright-2-area">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="coreal-copyright-box">
                <div className="coreal-copyright-item text-center">
                  <p>
                    Copyright & Design by @
                    <a href="https://themeforest.net/user/quomodotheme">
                      Quomodosoft
                    </a>
                    - 2021
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default FooterHomeTwo;
